import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { USER_SECONDARY_MAIL_UPDATE } from "./graphql"
import BounceIndicator from "react-activity/lib/Bounce"
import facebookImage from "../assets/images/fb.svg"
import linkedinImage from "../assets/images/linkedin.svg"
import googleImage from "../assets/images/google.svg"
import error_logo from "../assets/images/error_icon.png"
import Button from "@material-ui/core/Button"
import ResetPassword from "./resetPassword"
import DeleteAccount from "./delete-account"
import Login from "./login"
import { navigate } from "gatsby"
import { validateEmailID } from "../../utils"
import addToMailchimp from "gatsby-plugin-mailchimp"

function MyAccount(props) {
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [email, setEmail] = useState(props.userValue.data.user.secondaryEmail)
  const [saveLoading, setSaveLoading] = useState(false)
  const [savedEmail, setSavedEmail] = useState(
    props.userValue.data.user.secondaryEmail
  )
  const [isEditing, setIsEditing] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [updatesecondaryEmailMutation] = useMutation(USER_SECONDARY_MAIL_UPDATE)

  const handleGoogle = async () => {
    navigate(`${process.env.BACKEND_URL}/connect/google`)
  }

  const handleFacebook = async () => {
    navigate(`${process.env.BACKEND_URL}/connect/facebook`)
  }

  const NewsletterEnableAction = () => {
    console.log("news letter called")
    // addToMailchimp("jamescharles@gmail.com", {
    //   'group[223596][1]': true,
    //   'group[223596][2]': true,
    //   'group[223596][4]': false,
    // }).then((data) => {
    //   console.log("subscription data result", data)
    // })
    // .catch((error) => {
    //   console.log("subscription error", error)
    //   // Errors in here are client side
    //   // Mailchimp always returns a 200
    // });
  }

  const updatesecondaryEmail = async () => {
    try {
      await updatesecondaryEmailMutation({
        variables: {
          userId: props.userValue.data.user.id,
          email: email,
        },
      }).then(secondaryResponse => {
        console.log("secondaryResponse", secondaryResponse)
        setIsEditing(false)
        setSaveLoading(false)
      })
    } catch (e) {
      setSaveLoading(false)
      console.log("updatesecondaryEmail  error", e.message)
    }
  }

  const validateMailCheck = () => {
    if (saveLoading === false) {
      setSaveLoading(true)
      setEmailError("")
      setEmailError("")
      console.log("email", email + validateEmailID(email))
      if (validateEmailID(email)) {
        updatesecondaryEmail()
      } else {
        setEmailError("Invalid Email")
      }
    }
  }

  return (
    <>
      {console.log("deleteOpen", deleteOpen)}
      <DeleteAccount
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        userValue={props.userValue}
      />
      <ResetPassword
        open={open}
        setOpen={setOpen}
        userValue={props.userValue}
      />
      <div id="account" role="tabpanel" aria-labelledby="pills-account-tab">
        <div className="my-account-section">
          <div className="title">Email settings</div>
          <div className="email-section">
            <div className="e-mail">
              <span>your email address</span>
              {isEditing === false ? (
                <p>{email}</p>
              ) : (
                <div className="login-fields" style={{ maxWidth: "none" }}>
                  <div className="field">
                    <input
                      type="email"
                      autoFocus
                      placeholder="Email Address"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                    />
                    {emailError !== "" ? (
                      <div className="e-message">{emailError}</div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            {isEditing === false ? (
              <div
                className="e-edit"
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                <span>edit email</span>
              </div>
            ) : (
              <div
                className="e-edit"
                onClick={() => {
                  validateMailCheck()
                }}
              >
                {saveLoading === false ? (
                  <span>save email</span>
                ) : (
                  <div className={"centered"}>
                    <BounceIndicator size={10} color={"#0055a4"} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="preference-section">
            <div className="list-one">
              <span>email preferences</span>
              <p>We will send the selected emails to {savedEmail}</p>
              <div className="pre-list">
                <div className="left">
                  <span>Personalized Recommendations</span>
                  <p>
                    Education, study in france or blogs recommendations directly
                    into you email address.
                  </p>
                </div>
                <div className="toggle">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={e => {
                        console.log("recommend", e.target.checked)
                        NewsletterEnableAction()
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="pre-list">
                <div className="left">
                  <span>Newsletters</span>
                  <p>Subscribe to our newsletter for the better insights.</p>
                </div>
                <div className="toggle">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="pre-list">
                <div className="left">
                  <span>Dimensions News</span>
                  <p>
                    Updates about the dimension community and other latest
                    features.
                  </p>
                </div>
                <div className="toggle">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div
                onClick={() => {
                  setOpen(true)
                }}
                style={{ cursor: "pointer" }}
                className="reset"
              >
                <a>reset your password</a>
              </div>
            </div>
          </div>
          <div className="empty-line"></div>
          <div className="accounts">
            <div className="title">account settings</div>
            <div className="list-one list-two">
              <div className="l-acc">
                <span>linked accounts</span>
                <p>
                  We use this to let you sign in and populate your profile
                  information.
                </p>
              </div>
              <div className="pre-list">
                <div className="text">
                  <img src={googleImage} alt="" />
                  <p>sign in with google</p>
                </div>
                <div className="con-button">
                  <a onClick={() => handleGoogle()}>Connect</a>
                </div>
                {/* <Button onClick={() => handleGoogle()} className="con-button">
                  Connect
                </Button> */}
              </div>
              <div className="pre-list">
                <div className="text">
                  <img src={facebookImage} alt="" />
                  <p>sign in with Facebook</p>
                </div>
                <div className="con-button">
                  <a onClick={() => handleFacebook()}>Connect</a>
                </div>
                {/* <Button onClick={() => handleFacebook()} className="con-button">
                  Connect
                </Button> */}
              </div>
              <div className="pre-list">
                <div className="text">
                  <img src={linkedinImage} alt="" />
                  <p>sign in with linkedin</p>
                </div>
                <div className="con-button">
                  <a href="#">Connect</a>
                </div>
              </div>
            </div>
            <div className="delete-acc">
              <div className="left">
                <span>delete account</span>
                <p>By deleting you account you will lose all your data.</p>
              </div>
              <div
                className="right"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteOpen(true)
                }}
              >
                <a>delete account</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MyAccount
