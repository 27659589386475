
import React, { useState } from 'react';

import { Upload, Spin, Button, Select, Input } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import 'antd/lib/upload/style/index.css'
import 'antd/lib/spin/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/input/style/index.css'

import apiUrl from '../../api/apiUrl';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';






const { Dragger } = Upload;

function Recommandation({ id, recommandDoc }) {

    const [recommand, setrecommandDoc] = useState(recommandDoc ?? [])
    const [newDocuments, setnewDocuments] = useState([''])
    const [isLoading, setisLoading] = useState(false)

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"


    const uploadExpDocument = async (e, i) => {
        setisLoading(true)
        try {
            var tempexpDocument = [...recommand]
            var expDocument = tempexpDocument.map(item => { return { Name: item.Name, Certificate: item.Certificate.id } })

            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            //EDUDocuments[feild] = uploadResp.data[0].id
            var tempDoc = {}
            tempDoc.Name = newDocuments[i]
            tempDoc.Certificate = uploadResp.data[0].id
            expDocument.push(tempDoc)

            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { RecommendationDocuments: expDocument })
            updateUser?.data?.RecommendationDocuments && setrecommandDoc(updateUser?.data?.RecommendationDocuments)
            setnewDocuments([])
            setisLoading(false)
        } catch (error) {
            console.log(error)
            setnewDocuments([])
            setisLoading(false)
        }
    }

    const addNewDocs = () => {
        var tempDoc = [...newDocuments]
        tempDoc.push('')
        setnewDocuments(tempDoc)
    }


    const deleteFile = async (itemid) => {
        setisLoading(true)
        try {
            var tempexpDocument = [...recommand]
            var newexpDocument = tempexpDocument.filter(item => item?.id !== itemid).map(item => { return { Name: item.Name, Certificate: item.Certificate.id } })
    
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { RecommendationDocuments: newexpDocument })
            updateUser?.data?.RecommendationDocuments && setrecommandDoc(updateUser?.data?.RecommendationDocuments)
            setisLoading(false)

        } catch (error) {
            console.log(error)
            setisLoading(false)

        }
    }

    const changeText = (value, index) => {
        var newDoc = [...newDocuments]
        newDoc[index] = value
        // console.log(value,index)
        setnewDocuments(newDoc)
    }




    if (isLoading) {
        return (
            <div className="spin">
                <Spin indicator={antIcon} />
            </div>
        )
    }

    return (
        <>
            {recommand.length > 0 && <>
                {recommand.map((item, index) =>
                    <div className="row mt-3" key={index}>
                        <div className="col">
                            <Input placeholder="Enter Company Name" value={item.Name} />
                        </div>

                        <div className="col text-center d-flex justify-content-between">
                            {/* <Button >View Document</Button> */}

                            <Button href={`${apiUrl.host}${item?.Certificate?.url}`} target="_blank">View Document</Button>
                            {/* <Button size={"middle"} onClick={()=>{saveInfo()}}>
                                Save
                            </Button> */}

                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile(item?.id) }} />
                        </div>

                    </div>)}



            </>}


            {newDocuments.map((item, index) =>
                <div className="row mt-3" key={index}>
                    <div className="col">
                        <Input placeholder="Enter Company Name" value={item} onChange={(e) => { changeText(e.target.value, index) }} />
                    </div>


                    <div className="col">
                        <Dragger accept={accpted} multiple={false} showUploadList={false} className="p-0"
                            onChange={(e) => uploadExpDocument(e, index)} disabled={!newDocuments[index].length}
                        >
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </div>

                </div>)}


            {/* <div className="row mt-3">
                <div className="col">
                <Input placeholder="Enter Company Name"  />

                </div>
                {true ?
                    <>
                        <div className="col text-center">
                            <Button >View Document</Button>
                        
                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} />
                        </div>

                    </> :

                    <div className="col">
                        <Dragger {...settings}>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </div>



                }
            </div> */}


            <div className="text-end mt-3 mb-3 p-1">
                <div className="cta-primary">
                    <Button type="primary" size={"medium"} onClick={() => { addNewDocs() }}>
                        Add More
                    </Button>
                </div>
            </div>



        </>
    )
}

export default Recommandation;
