import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import {
  JOURNEY_BLOCK,
  JOURNEY_STEP_QUERY,
  MUTATION_USER_JOURNEY
} from "../components/graphql"
import Button from "@material-ui/core/Button";
import dashboard_icon from "../assets/images/dashboard.png"
import check_img from '../assets/images/check_green.png'



import axios from "axios"
import apiUrl from "../api/apiUrl"

function Journey(props) {
  const userID = localStorage.getItem("userId")
  const allJourney = useQuery(JOURNEY_STEP_QUERY)
  const activeList = []
  props.userValue.data.user.journeyStepContent.forEach((record) => {
    if (record) {
      activeList.push(record.active)
    }
  });
  const [activeJourney, setActiveJourney] = useState(activeList)

  const [updateUser] = useMutation(MUTATION_USER_JOURNEY)

  const journeyBlockStaticContent = useQuery(JOURNEY_BLOCK, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
      },
    },
  })
  console.log("journeyyyyyyyyyyyyyyyyyyyyyy", props)
  const handleInputChange = index => (event) => {
    console.log("*******************************************", index, "#############", event.target.checked)

    if (event.target.checked) {
      let newArr = [];
      var j = 0;
      props.userValue.data.user.journeyStepContent.forEach((record) => {
        if (j <= index) {
          newArr.push(true)
        }
        else {
          newArr.push(false)
        }
        j += 1
      });
      console.log("4444444444==============>>>", newArr)
      setActiveJourney(newArr)
      console.log("trueeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      if (allJourney.data.journeySteps.length > 0) {
        const journeyList = []
        var i = 0;
        allJourney.data.journeySteps.forEach(record => {
          if (i <= index) {
            let dict = {}
            dict.journeystep = record.id
            dict.active = true
            journeyList.push(dict)
          }
          else {
            let dict = {}
            dict.journeystep = record.id
            dict.active = false
            journeyList.push(dict)
          }
          i += 1;
        })
        console.log("//////////////////////////////", journeyList)
        try {

          const reqHeader = {
            headers: {
              Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
            }
          }

          updateUser({
            variables: {
              userid: userID ? userID : "",
              journeyContent: journeyList,
            },
          })
            // axios.post(apiUrl.updateJourney,{journeyContent:journeyList},reqHeader)
            .then(userResponse => {
              console.log("reponse", userResponse.data)
            }).then(axios.post(apiUrl.updateJourney, { journeyContent: journeyList }, reqHeader))
        } catch (e) {
          console.log("journey user error", e)
        }
      }
    }
    else {
      let newArr = [];
      var j = 0;
      props.userValue.data.user.journeyStepContent.forEach((record) => {
        if (j < index) {
          newArr.push(true)
        }
        else {
          newArr.push(false)
        }
        j += 1
      });
      console.log("22222222==============>>>", newArr)
      setActiveJourney(newArr)
      console.log("falseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      if (allJourney.data.journeySteps.length > 0) {
        const journeyList = []
        var i = 0;
        allJourney.data.journeySteps.forEach(record => {
          if (i < index) {
            let dict = {}
            dict.journeystep = record.id
            dict.active = true
            journeyList.push(dict)
          }
          else {
            let dict = {}
            dict.journeystep = record.id
            dict.active = false
            journeyList.push(dict)
          }
          i += 1;
        })
        console.log("//////////////////////////////==============", journeyList)

        const reqHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        try {
          updateUser({
            variables: {
              userid: userID ? userID : "",
              journeyContent: journeyList,
            },
          })
            .then(userResponse => {
              console.log("reponse", userResponse.data)
            }).then(axios.post(apiUrl.updateJourney, { journeyContent: journeyList }, reqHeader))
        } catch (e) {
          console.log("journey user error", e)
        }
      }
    }
  }
  console.log("HHHHHHHHHHHHHHH===============================", activeJourney)
  return (
    <div id="journey" role="tabpanel" aria-labelledby="pills-journey-tab">
      <div className="journey-section">
        <div className="title">
          {journeyBlockStaticContent.data ? journeyBlockStaticContent.data.journeyBlock.title : ""}
        </div>
        <p>{journeyBlockStaticContent.data ? journeyBlockStaticContent.data.journeyBlock.description : ""}</p>
        <div className="user-journey">
          <div className="user-journey-list">
            {props.userValue.data
              ? props.userValue.data.user.journeyStepContent.map(
                (content, index) => (
                  <div key={index}>
                    {content ?
                      <>
                        {content.active ? (
                          <div className="list-count active" onClick={handleInputChange(index)} style={{ cursor: 'pointer' }}>
                            <div className="count">
                              {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                            </div>
                            <div className="content" >
                              <div className="image">
                                {content.journeystep.thumbnailImage ? (
                                  <>
                                    <img
                                      src={
                                        process.env.DEV_BACKEND_URL +
                                        content.journeystep.thumbnailImage.url
                                      }
                                      className="icon"
                                      alt=""
                                    />

                                  </>
                                ) : <></>
                                }
                              </div>
                              <h4 >{content.journeystep.name}</h4>


                              <div className="d-flex justify-content-between">
                                <h4 ></h4>
                                <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                                <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                              </div>

                              {/* <div className="toggle">
                                    <label className="switch">
                                      <input type="checkbox"
                                      checked={activeJourney[index]}
                                      onChange={
                                          handleInputChange(index)
                                      }/>
                                  
                                      <span className="slider round"></span>
                                    </label>
                                  </div> */}
                            </div>
                          </div>
                        ) : (

                          <div className="list-count">
                            <div className="count">{index + 1 < 10 ? "0" + (index + 1) : index + 1}</div>
                            <div className="content">
                              <div className="image">
                                {content.journeystep.thumbnailImage ? (
                                  <>
                                    <img
                                      src={
                                        process.env.BACKEND_URL +
                                        content.journeystep.thumbnailImage.url
                                      }
                                      className="icon"
                                      alt=""
                                    />
                                  </>
                                ) : null}
                              </div>
                              <h4>{content.journeystep.name}</h4>

                              <div className="d-flex justify-content-end">
                               
                                <div className="toggle">
                                  <label className="switch">
                                    <input type="checkbox"
                                      checked={activeJourney[index]}
                                      onChange={
                                        handleInputChange(index)
                                      } />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>

                            </div>
                          </div>



                          // <div className="list-count" onClick={handleInputChange(index)} style={{ cursor: 'pointer' }}>
                          //   <div className="count">{index + 1 < 10 ? "0" + (index + 1) : index + 1}</div>
                          //   <div className="content" >
                          //     <div className="image">
                          //       {content.journeystep.thumbnailImage ? (
                          //         <img src="" />
                          //       ) : null}
                          //     </div>
                          //     <h4 >{content.journeystep.name}</h4>
                          //     <div className="toggle">
                          //       <label className="switch">
                          //         <input type="checkbox"
                          //           checked={activeJourney[index]}
                          //           onChange={
                          //             handleInputChange(index)
                          //           } />

                          //         <span className="slider round"></span>
                          //       </label>
                          //     </div>
                          //   </div>
                          // </div>
                        )}
                      </>
                      : null}
                  </div>
                )
              )
              : "null"}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Journey
