import React, { useState } from "react"
import {
  TextField,
  Dialog, DialogActions, MenuItem,
  DialogContent, DialogTitle
} from "@material-ui/core"
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons';

import { useMutation } from "@apollo/client"
import { UPDATE_USER_EDUCATION_BACKGROUND } from "../graphql"
import axios from "axios"
import apiUrl from "../../api/apiUrl"
// import { YearPicker } from 'react-dropdown-date';
// import DatePicker from '@material-ui/lab';
// import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { EXTRA_CARRICULAR_ACTIVITY } from "../graphql"

import { useQuery } from "@apollo/client"

export default function ExtraCarricular(props) {

  const [open, setOpen] = useState(true)
  const userID = props.userValue.data.user.id

  const [activityStatus, setactivityStatus] = useState('Ongoing')
  const [activity, setactivity] = useState('')
  const [position, setposition] = useState('')
  const [description, setdescription] = useState('')
  const [startDate, setstartDate] = useState('')
  const [endOn, setendOn] = useState('')
  const [isIntrest, setisIntrest] = useState('')

  const [activityError, setactivityError] = useState('')
  const [posistionError, setposistionError] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState({})


  const [courseName, setCourseName] = useState("")
  const [boardName, setBoardName] = useState("")
  const [universityOrInstitute, setUniversityOrInstitute] = useState("")
  const [locationName, setLocationName] = useState("")
  const [passingOutYear, setPassingOutYear] = useState(null)
  const [gradingSystem, setGradingSystem] = useState("")
  const [markValue, setMarkValue] = useState("")
  const [updateUser] = useMutation(UPDATE_USER_EDUCATION_BACKGROUND)
  const list = []
  const list1 = []


  const extraCarricularActivitys = useQuery(EXTRA_CARRICULAR_ACTIVITY)


  props.userValue.data.user.extraCarricularActivitys.forEach((record) => {

    let dict = {}
    dict.id = record?.id
    dict.Status = record?.Status
    dict.position = record?.position
    dict.description = record?.description
    dict.startedFrom = record?.startedFrom
    dict.EndedOn = record?.EndedOn
    dict.intrestInParticipate = record?.intrestInParticipate
    dict.extra_carricular_activity = record?.extra_carricular_activity
    list.push(dict)
  })



  // const startYear = 1950
  // const endyear = new Date().getFullYear()

  // const yearList = []

  // for (var i = startYear; i >= endyear; i--) {
  //   yearList.push(i.toString());
  // }


  const startYear = new Date().getFullYear()
  const endyear = 1950

  const yearList = []

  for (var i = startYear; i >= endyear; i--) {
    yearList.push(i.toString());
  }


  const [educationList, setEducationList] = useState(list)
  const [extraCarricularList, setextraCarricularList] = useState(list)


  console.log(extraCarricularList, '===extra===')



  educationList.forEach((record) => {
    let dict = {}
    dict.activityError = ""
    dict.posistionError = ""
    list1.push(dict)
  })

  const [extraCarricularListError, setextraCarricularListError] = useState(list1)


  const handleClose = () => {
    setOpen(false)
  }

  const handleAdd = (index, element) => e => {
    let newArr = [...extraCarricularList];
    // if(element !== "passingOutYear"){
    newArr[index][element] = e.target.value;
    // }
    // else{
    //   newArr[index][element] = (e.getFullYear()).toString()

    // }
    setextraCarricularList(newArr);
  }

  const handleSubmitButton = () => {
    if (props.add) {
      if (!activity) {
        setactivityError("Choose a valid activity")
      }
      if (!position) {
        setposistionError("Enter a valid position")
      }

      if (activity && position) {
        setactivityError("")
        setposistionError("")
        handleSubmit()
      }
    }
    else {
      let listArr = []
      let flag = 0
      extraCarricularList.forEach((record) => {
        let dict = {}
        if (!record.extra_carricular_activity) {
          dict.activityError = "Enter a valid Activity"
          flag = 1
        }
        else if (!record.position) {
          dict.posistionError = "Enter a valid board name"
          flag = 1
        }
        else {
          dict.activityError = ""
          dict.posistionError = ""
        }
        listArr.push(dict)
      })
      setextraCarricularListError(listArr)
      if (flag === 0) {
        handleSubmit()
      }
    }
  }

  const deleteEducation = (index) => {

    var tempEdu = [...educationList]
    tempEdu.splice(index, 1)
    setEducationList([])

  }

  const handleSubmit = async () => {
    setIsLoading(true)
    if (props.add) {
      if (activity || position) {
        let dict1 = {}
        dict1.Status = activityStatus || ''
        dict1.position = position
        dict1.description = description || ''
        dict1.intrestInParticipate = isIntrest || 'No'
        dict1.extra_carricular_activity = activity

        if (startDate) { dict1.startedFrom = startDate }
        if (endOn) { dict1.EndedOn = endOn }

        // dict1.startedFrom = startDate || ''
        // dict1.EndedOn = endOn || ''

        const requestHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        try {
          await axios.post(apiUrl.addEducation, { extraCarricularActivitys: extraCarricularList.concat([dict1]) }, requestHeader)
            .then(response => {
              setIsLoading(false)
              setNotification({ error: false, message: 'Extra carricular activity added successfully' })
            })
            .finally(() => {
              setOpen(false)
            })
        } catch (e) {
          setIsLoading(false)
          setNotification({ error: true, message: 'Failed to created extraCarricularActivitys' })


        }
      }
      else {
        setOpen(false)
      }
    }
    else {
      try {

        const requestHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        axios.post(apiUrl.addEducation, { extraCarricularActivitys: educationList }, requestHeader)
          .then(response => {
            setIsLoading(false)
            setNotification({ error: false, message: 'Extra carricular activity updated successfully' })

          })
          .finally(() => {
            setOpen(false)
            setCourseName("")
            setBoardName("")
            setUniversityOrInstitute("")
            setLocationName("")
            setPassingOutYear("")
            setGradingSystem("")
            setMarkValue("")
          })
      } catch (e) {
        setIsLoading(false)
        setNotification({ error: true, message: 'Failed to update extraCarricularActivitys' })
        console.log("update user education background error", e.message)
      }

    }
  }
  console.log("updateedddddddddddddddddddddddddddddd", educationList)
  return (
    <div>
      {props.add ?

        <>
          <div className="row w-100">
            <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
          </div>

          <div className="fw-bold">Extra Carricular Activity</div>
          <div className="row w-100 p-3">
            <div className="col mt-3">
              <FormLabel id="demo-row-radio-buttons-group-label" >Activity Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={activityStatus}
                onChange={(e) => setactivityStatus(e.target.value)}>
                <FormControlLabel value="Ongoing" control={<Radio size="small" />} label="Ongoing" />
                <FormControlLabel value="Completed" control={<Radio size="small" />} label="Completed" />
              </RadioGroup>
            </div>

            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="activity"
                label="Activity"
                type="text"
                value={activity}
                onChange={e => {
                  setactivity(e.target.value)
                }}
                helperText={activityError || ""}
                error={activityError}
              >

              </TextField>

              {' '}
            </div>


          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="position-name"
                label="Designation"
                type="text"
                value={position}
                onChange={e => {
                  setposition(e.target.value)
                }}
                helperText={posistionError || ""}
                error={posistionError}
              />
              {' '}
            </div>
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="course-name"
                label="Description"
                type="text"
                value={description}
                onChange={e => {
                  setdescription(e.target.value)
                }}
              />
              {' '}
            </div>
          </div>
          <div className="row w-100">
            <div className="col">

              <TextField
                className="w-100"
                margin="dense"
                id="start-from"
                label="Started From"
                type="date"
                placeholder="t"
                value={startDate}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => {
                  setstartDate(e.target.value)
                }}
              />
              {' '}
            </div>

            {activityStatus == 'Ongoing' && <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="end-on"
                label="Ended On"
                type="date"
                value={endOn}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => {
                  setendOn(e.target.value)
                }}
              />
              {' '}
            </div>}

          </div>

          {/* <div className="row w-100 mt-2">
            <div className="col">
              <FormLabel id="demo-row-radio-buttons-group-label" >Would you be interested in participating in similar activities at university where you apply?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={isIntrest}
                onChange={(e) => setisIntrest(e.target.value)}
              >
                <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
              </RadioGroup>
              {' '}
            </div>
          </div> */}

          <div className="mt-3 mb-3 d-flex justify-content-between p-1">

            {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
              Save
            </Button> */}
            <div className="cta-primary">
              <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
                Save
              </Button>
            </div>
            <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
              + Add new
            </Button>
          </div>

        </>

        :
        <>


          {props.userValue.data.user.extraCarricularActivitys.length > 0 ?
            <>
              {props.userValue.data ? props.userValue.data.user.extraCarricularActivitys.map(
                (content, index) => (

                  <div className="my-3">
                    <div className="row w-100 px-3">
                      <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
                    </div>
                    <div className="fw-bold">Edit ExtraCurricular Activitys</div>


                    <div className="row w-100">

                      <div className="col mt-2 w-100 ps-2">
                        <FormLabel id="demo-row-radio-buttons-group-label" >Activity Status</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={extraCarricularList[index].Status}
                          onChange={handleAdd(index, "Status")}>
                          <FormControlLabel value="Ongoing" control={<Radio size="small" />} label="Ongoing" />
                          <FormControlLabel value="Completed" control={<Radio size="small" />} label="Completed" />
                        </RadioGroup>
                      </div>

                      <div className="col">
                        <TextField
                          className="w-100"

                          margin="dense"
                          id="activity"
                          label="Activity"
                          type="text"
                          value={extraCarricularList[index].extra_carricular_activity}
                          onChange={handleAdd(index, "extra_carricular_activity")}
                          helperText={extraCarricularListError[index].activityError || ""}
                          error={extraCarricularListError[index].activityError}
                        >


                        </TextField>

                        {' '}
                      </div>


                    </div>
                    <div className="row w-100">

                      <div className="col">
                        <TextField
                          className="w-100"
                          margin="dense"
                          id="position-name"
                          label="Position"
                          type="text"
                          value={extraCarricularList[index].position}
                          onChange={handleAdd(index, "position")}
                          helperText={extraCarricularListError[index].posistionError || ""}
                          error={extraCarricularListError[index].posistionError}
                        />

                        {' '}
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100"
                          margin="dense"
                          id="description"
                          label="Description"
                          type="text"
                          value={extraCarricularList[index].description}
                          onChange={handleAdd(index, "description")}

                        />
                        {' '}
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col">


                        <TextField
                          className="w-100"
                          margin="dense"
                          id="start-from"
                          label="Start From"
                          type="date"
                          value={extraCarricularList[index].startedFrom}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={handleAdd(index, "startedFrom")}

                        />
                        {' '}
                      </div>
                      <div className="col">

                        <TextField
                          className="w-100"
                          margin="dense"
                          id="end-on"
                          label="End On"
                          type="date"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={extraCarricularList[index].EndedOn}
                          onChange={handleAdd(index, "EndedOn")}

                        />
                        {' '}
                      </div>
                    </div>

                    {/* <div className="row w-100 mt-2">
                      <div className="col">
                        <FormLabel id="demo-row-radio-buttons-group-label" >Would you be interested in participating in similar activities at university where you apply?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={extraCarricularList[index].intrestInParticipate}
                          onChange={handleAdd(index, "intrestInParticipate")}
                        >
                          <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                        </RadioGroup>

                        {' '}
                      </div>
                    </div> */}
                  </div>
                )) : null}
            </>
            : null}
          {props.userValue.data.user.extraCarricularActivitys.length > 0 && <div className="my-3 d-flex justify-content-between p-1">
            {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
              Save
            </Button> */}
            <div className="cta-primary">
            <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
              Save
            </Button>
            </div>
            <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
              + Add new
            </Button>
          </div>}

        </>
      }
    </div>
  )
}