import React, { useEffect, useState } from 'react'

import '../assets/css/personal_profile.css'
import { useQuery, useMutation } from "@apollo/client"
import {
    COUNTRY_QUERY,
    UPDATE_USER_MUTATION,
    VALIDATE_USER_EMAIL_ID,
} from "../components/graphql"

import PhoneInput from "react-phone-number-input"
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import "react-phone-number-input/style.css"


import {
    Button,
    TextField,
    MenuItem
} from "@material-ui/core"
import axios from 'axios'
import apiUrl from '../api/apiUrl'




function EditProfile({ userValue, id }) {

    const [firstName, setfirstName] = useState(userValue?.firstName)
    const [lastName, setlastName] = useState(userValue?.lastName)
    const [dateOfBirth, setDateOfBirth] = useState(userValue?.dateOfBirth);
    const [mobileNumber, setMobileNumber] = useState(userValue?.mobileNumber);
    const [gender, setGender] = useState(userValue?.Gender);
    const [city, setcity] = useState(userValue?.cityName);
    const [name, setname] = useState(userValue?.username)
    const [email, setemail] = useState(userValue?.email)
    const [currentLocation, setcurrentLocation] = useState(userValue?.currentLocation)
    const [currentState, setcurrentState] = useState(userValue?.state)
    const [currentCountry, setcurrentCountry] = useState(userValue?.countryName?.id)

    const [nameError, setnameError] = useState('')
    const [emailError, setemailError] = useState('')


    const countryQuery = useQuery(COUNTRY_QUERY)

    const countryList = countryQuery?.data?.countries || []



    const validation = async () => {
        if (!name) { setnameError('Enter name'); return null }
        if (!email) { setemailError('Enter email'); return null }

        try {
            const requestHeader = {
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                }
            }
            var response = await axios.post(apiUrl.updateProfile,
                {
                    username: name,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    mobileNumber: mobileNumber,
                    Gender: gender,
                    cityName: city,
                    dateOfBirth: dateOfBirth,
                    currentLocation: currentLocation,
                    state: currentState,
                    countryName: currentCountry
                }, requestHeader

            )
            console.log(response.data)
        } catch (error) {
            console.log(error)

        }

    }


    return (

        <div className="col-md-12 position-relative" style={{ marginTop: '30%' }}>
            {/* <div className="edit-personal-profile-right text-center" >
                    <img className="edit-personal-profile-logo" src={logoIcon} />
                    <p style={{ color: "#1e69b5", margin: "20px" }}>Personal Profile</p>
                    <div className="edit-personal-profile-underline"></div>
                </div> */}

            <div className="edit-personal-profile-edit mx-5 mt-5" >
                <spna className="fw-bold">Edit profile</spna>
                <div className="row mt-3">
                    {/* <div className="col-md-3">
                            <div className="edit-profile-avatar">
                            </div>
                        </div> */}

                    <div className="row">
                        <div className="col">
                            {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}
                            <TextField
                                className="w-100"
                                margin="dense"
                                id="firstname"
                                label="First Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                                value={firstName}
                                onChange={e => {
                                    setfirstName(e.target.value)
                                }}
                            >
                            </TextField>
                        </div>
                        <div className="col">
                            <TextField
                                className="w-100"
                                margin="dense"
                                id="lastname"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="last Name"
                                type="text"
                                value={lastName}
                                onChange={e => {
                                    setlastName(e.target.value)
                                }}
                            >
                            </TextField>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            <TextField id="standard-basic"
                                value={name}
                                onChange={(e) => setname(e.target.value)}
                                label="Username"
                                variant="standard"
                                className="w-100"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={nameError ? nameError : ""}
                                error={nameError}
                            />

                        </div>

                        <div className="col">
                            <TextField id="standard-basic"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                label="Email"
                                variant="standard"
                                className="w-100"
                                type="email"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={emailError ? emailError : ""}
                                error={emailError}
                                fullWidth />
                        </div>

                    </div>


                </div>
                <div className="row m-0 p-0">
                    <div className="col m-0 p-0">
                        {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}

                        <PhoneInput
                            // countryCallingCodeEditable={false}
                            international
                            className="w-100 mt-4 pt-1"
                            countryCallingCodeEditable={false}
                            defaultCountry="FR"
                            value={mobileNumber}
                            placeholder="Contact Number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => {
                                setMobileNumber(e)
                            }}
                        >
                        </PhoneInput>{''}

                    </div>
                    <div className="col">
                        {/* <TextField id="standard-basic" label="dob" variant="standard" className="w-100 mt-3" /> */}

                        <TextField
                            className="w-100"
                            margin="dense"
                            id="date-of-birth"
                            label="Date of Birth"
                            type="date"
                            // defaultValue={"2017-05-24"}
                            placeholder=""
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={dateOfBirth}
                            onChange={e => {
                                setDateOfBirth(e.target.value)
                            }}

                        />

                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TextField
                            className="w-100"
                            margin="dense"
                            id="marks-value"
                            label="Current location"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={currentLocation}
                            onChange={e => {
                                setcurrentLocation(e.target.value)
                            }}

                        />

                    </div>
                    <div className="col">
                        <TextField
                            className="w-100"
                            select
                            margin="dense"
                            id="gender"
                            label="Gender"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={gender}
                            onChange={e => {
                                setGender(e.target.value)
                            }}

                        >
                            <MenuItem value={"Male"} key={"male"}>
                                Male
                            </MenuItem>
                            <MenuItem value={"Female"} key={"female"}>
                                Female
                            </MenuItem>
                            <MenuItem value={"Other"} key={"female"}>
                                Other
                            </MenuItem>
                        </TextField>

                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}
                        <TextField
                            className="w-100"
                            margin="dense"
                            id="city"
                            label="Current City"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={city}
                            onChange={e => {
                                setcity(e.target.value)
                            }}


                        >

                        </TextField>

                    </div>
                    <div className="col">
                        <TextField
                            className="w-100"
                            margin="dense"
                            id="state"
                            label="State"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={currentState}
                            onChange={e => {
                                setcurrentState(e.target.value)
                            }}

                        >
                        </TextField>

                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TextField
                            className="w-100"
                            select
                            margin="dense"
                            id="country"
                            label="Country"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={currentCountry}
                            onChange={e => {
                                setcurrentCountry(e.target.value)
                            }}
                        >
                            {countryList.map((item, index) => <MenuItem value={item?.id} key={index}>
                                {item?.countryName}
                            </MenuItem>)}

                        </TextField>

                    </div>
                    <div className="col">
                        {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}

                    </div>
                </div>



                <div className="row w-100">
                    <div className="col mt-3">

                        <Button className="cancel-btn" color="primary" style={{ color: '#3377B6' }}>
                            Cancel
                        </Button>
                        <Button
                            onClick={(e) => { validation() }}
                            className="save-btn"
                            color="primary"
                        >
                            Save
                        </Button>
                    </div>
                </div>



            </div>
        </div>)
}

export default EditProfile
