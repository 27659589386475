import React, { useState } from "react"
import {
  TextField,
  Dialog, DialogActions, MenuItem,
  DialogContent, DialogTitle
} from "@material-ui/core"

import { CheckOutlined } from '@ant-design/icons';

import Autocomplete from "@material-ui/lab/Autocomplete"
import { useQuery } from "@apollo/client"
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useMutation } from "@apollo/client"
import { UPDATE_USER_EDUCATION_BACKGROUND } from "../graphql"
import axios from "axios"
import apiUrl from "../../api/apiUrl"
// import { YearPicker } from 'react-dropdown-date';
// import DatePicker from '@material-ui/lab';
// import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  COUNTRY_QUERY,
  UPDATE_USER_MUTATION,
  VALIDATE_USER_EMAIL_ID,
} from "../graphql"

import { Button } from "antd"
import { DeleteFilled } from '@ant-design/icons';


export default function AddressContact(props) {
  console.log("=========111111111111111111==========", props)
  const [open, setOpen] = useState(true)
  const userID = props.userValue.data.user.id

  const [emergenceeNomineeName, setemergenceeNomineeName] = useState(props?.userValue?.data?.user?.emergencyNomineeName || '')
  const [emergenceeNomineeContact, setemergenceeNomineeContact] = useState(props?.userValue?.data?.user?.emergencyNomineeContact || '')
  const [realationWithNominee, setrealationWithNominee] = useState(props?.userValue?.data?.user?.nomineeRelation || '')

  const [nomineeNameError, setnomineeNameError] = useState('')
  const [nomineeContactError, setnomineeContactError] = useState('')
  const [nomineeRelationError, setnomineeRelationError] = useState('')

  const [isLoading, setisLoading] = useState(false)
  const [notification, setnotification] = useState({})

  const [address, setAddress] = useState(props?.userValue?.data?.user?.address || '')
  const [state, setstate] = useState(props?.userValue?.data?.user?.state || '')
  const [city, setcity] = useState(props?.userValue?.data?.user?.cityName || '')
  const [zipCode, setzipCode] = useState(props?.userValue?.data?.user?.zipcode || '')
  const countryQuery = useQuery(COUNTRY_QUERY)
  const [countryError, setcountryError] = useState('')
  const [addressError, setaddressError] = useState('')
  const [stateError, setstateError] = useState('')
  const [cityError, setcityError] = useState('')
  const [zipCodeError, setzipCodeError] = useState('')
  const [updateUser] = useMutation(UPDATE_USER_EDUCATION_BACKGROUND)
  const list = []
  const list1 = []
  const defaultCountryData = props.userValue.data.user.countryName
    ? props.userValue.data.user.countryName
    : ""

  const countryID = props.userValue.data.user.countryName
    ? props.userValue.data.user.countryName.id
    : ""

  const [defaultCountry, setDefaultCountry] = useState(
    defaultCountryData.countryName
  )

  const [country, setCountry] = useState(countryID)


  const countryProps = {
    options: countryQuery.data ? countryQuery.data.countries : [],
    getOptionLabel: option => option.countryName,
  }



  const handleSubmitButton = () => {

    console.log(country, '==c==')

    if (!address) {
      setaddressError("Enter a valid Address")
      return
    }
    if (!country) {
      setcountryError("Enter a valid country name")
      return
    }
    if (!state) {
      setstateError("Enter a valid state")
      return
    }
    if (!city) {
      setcityError("Enter a valid City")
      return
    }
    if (!zipCode) {
      setzipCodeError("Enter a valid zipcode")
      return
    }
    if (!emergenceeNomineeName) {
      setnomineeNameError('This field is required')
    }

    if (!emergenceeNomineeContact) {
      setnomineeContactError('This field is required')
    }

    if (!realationWithNominee) {
      nomineeRelationError('This field is required')
    }


    if (address && country && state && city
      && zipCode && emergenceeNomineeName && emergenceeNomineeContact && realationWithNominee) {
      setaddressError("")
      setcountryError("")
      setstateError("")
      setcityError("")
      setzipCodeError("")
      setnomineeNameError('')
      setnomineeContactError('')
      setnomineeRelationError('')
      handleSubmit()
    }


  }

  var onTagsChange = (event, values) => {
    if (values) {
      setCountry(values.id)
    } else {
      setCountry("")
    }
  }

  console.log('newuser', props?.userValue?.data?.user?.address)

  const handleSubmit = async () => {
    setisLoading(true)
    const requestHeader = {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
      }
    }

    let formPayload = {
      address: address,
      cityName: city,
      state: state,
      countryName: country,
      zipcode: zipCode,
      emergencyNomineeName: emergenceeNomineeName,
      emergencyNomineeContact: emergenceeNomineeContact,
      nomineeRelation: realationWithNominee
    }

    try {
      axios.post(apiUrl.updateProfile, formPayload, requestHeader)
        .then(response => {
          setnotification({ error: false, message: 'Data updated successfully' })
          setisLoading(false)
          console.log("reponse", response.data)
        })
        .finally(() => {
          setOpen(false)
        })
    } catch (e) {
      setisLoading(false)
      setnotification({ error: true, message: 'Failed to update data, Try again later' })
      console.log("Adding user education background error", e.message)
    }

  }

  return (
    <div>

      <>
        <div className="row w-100">
          <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
        </div>
        <div className="fw-bold">Edit Address and Contact</div>
        <div className="row w-100">
          <div className="col">
            <TextField
              required
              className="w-100"
              margin="dense"
              id="address"
              label="Address"
              type="text"
              value={address}
              onChange={e => {
                setAddress(e.target.value)
              }}
              helperText={addressError || ''}
              error={addressError}
            >
              Address

            </TextField>
          </div>
          <div className="col">

            <TextField
              required
              className="w-100"
              margin="dense"
              id="city-name"
              label="City/Town"
              type="text"
              value={city}
              onChange={e => {
                setcity(e.target.value)
              }}
              helperText={cityError || ''}
              error={cityError}
            />
          </div>

          <div className="col">
            <TextField
              required
              className="w-100"
              margin="dense"
              id="state-name"
              label="State"
              type="text"
              value={state}
              onChange={e => {
                setstate(e.target.value)
              }}
              helperText={stateError || ''}
              error={stateError}
            />
            {' '}
          </div>
        </div>

        {/* <div className="row w-100">
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="country-name"
              label="Country"
              type="text"
              value={country}
              onChange={e => {
                setCountry(e.target.value)
              }}
              helperText={countryError || ''}
              error={countryError}
            />

            {' '}
          </div>
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="state-name"
              label="State"
              type="text"
              value={state}
              onChange={e => {
                setstate(e.target.value)
              }}
              helperText={stateError || ''}
              error={stateError}
            />
            {' '}
          </div>
        </div> */}

        <div className="row w-100">
          <div className="col">

            <TextField
              required
              className="w-100"
              margin="dense"
              id="cip-name"
              label="Zip code"
              type="number"
              value={zipCode}
              onChange={e => {
                setzipCode(e.target.value)
              }}
              helperText={zipCodeError || ''}
              error={zipCodeError}
            />
          </div>
          <div className="col">
            <Autocomplete
              {...countryProps}
              id="debug"
              className="w-100"
              debug
              onChange={onTagsChange}
              defaultValue={{ countryName: defaultCountry }}
              renderInput={params => (
                <TextField
                  required
                  className="w-100"
                  {...params}
                  label="Country"
                  margin="normal"
                  error={countryError}
                />
              )}
            />

            {' '}
          </div>


        </div>
        <div className="row w-100">
          <div className="col">
            <TextField
              required
              className="w-100"
              margin="dense"
              id="address"
              label="Emergency Contact Nominee Name"
              type="text"
              value={emergenceeNomineeName}
              onChange={e => {
                setemergenceeNomineeName(e.target.value)
              }}
              helperText={nomineeNameError || ''}
              error={nomineeNameError}

            >
              Address

            </TextField>
          </div>
          <div className="col">
            <TextField
              required
              className="w-100"
              margin="dense"
              id="position-name"
              label="Contact Number"
              type="number"
              value={emergenceeNomineeContact}
              onChange={e => {
                setemergenceeNomineeContact(e.target.value)
              }}
              helperText={nomineeContactError || ''}
              error={nomineeContactError}
            />
            {' '}
          </div>
          <div className="col">
            <TextField
              required
              className="w-100"
              margin="dense"
              select
              id="relationship"
              label="Relationship with Nominee"
              type="text"
              value={realationWithNominee}
              onChange={e => {
                setrealationWithNominee(e.target.value)
              }}
              helperText={nomineeRelationError || ''}
              error={nomineeRelationError}
            >
              {[
                "Father",
                "Mother",
                "Brother",
                "Sister",
                "Guardian",
                "Sibling",
                "Husband",
                "Wife",
                "Son",
                "Daughter"
              ].map((item, index) => < MenuItem value={item} key={index}>
                {item}
              </MenuItem>)}

            </TextField>
          </div>
        </div>




        <div className="mt-3 pb-2 pl-1 pr-1">
          {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
            Save
          </Button> */}
          <div className="cta-primary">
            <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
              Save
            </Button>
          </div>
        </div>

      </>


    </div >
  )
}