import React, { useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { useMutation, useQuery } from "@apollo/client"
import popup_logo from "../assets/images/popup_logo.png"
import info_logo from "../assets/images/info_icon.png"
import error_logo from "../assets/images/error_icon.png"
import { Link } from "gatsby"
import { validateEmailID, validatePassword } from "../../utils"
import close_image from "../assets/images/icon-close.png"
import { DropdownButton, Dropdown } from "react-bootstrap"
// import { Field, reduxForm } from "redux-form";

import {
  UPDATE_USER_PASSWORD_MUTATION,
  LOGIN_USER_MUTATION,
} from "../components/graphql"
import axios from "axios";

export default function FormDialog({ open, setOpen, userValue }) {
  const [pass, setPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const [email, setEmail] = useState(userValue.data.user.email)
  const [user, setUser] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [newPasswordError, setNewPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [loginError, setLoginError] = useState("")
  const [userRoleError, setUserRoleError] = useState("")
  const [loginUser] = useMutation(LOGIN_USER_MUTATION)
  const [changePassword] = useMutation(UPDATE_USER_PASSWORD_MUTATION)
  const userId = userValue.data.user.id

  //   const [createUser] = useMutation(CREATE_USER_MUTATION)
  //   const [loginUser] = useMutation(LOGIN_USER_MUTATION)

  //   const allUserRoles = useQuery(GET_ALL_USER_ROLES)
  //   const allJourney = useQuery(JOURNEY_STEP_QUERY)
  //   const allStudyAbroadExam = useQuery(STUDY_ABROAD_EXAM_QUERY)
  //   const [updateUser] = useMutation(UPDATE_USER_DATA)
  //   const [updateUserRole] = useMutation(UPDATE_USER_ROLE_MUTATION)
  //   const [userRoleID, setUserRoleID] = useState("")
  //   const [getUser] = useMutation(GET_USER_ID_MUTATION)
  //   const [userRoleName, setUserRoleName] = useState("Select Role...")

  const handleClose = () => {
    setOpen(false)
    formDataReset()
  }

  const handleValidation = () => {
    var isAllfieldsValid = true
    if (!validatePassword(pass)) {
      isAllfieldsValid = false
      setPasswordError("Invalid password")
      if (!validatePassword(newPass)) {
        isAllfieldsValid = false
        setNewPasswordError("Use of minimum 7 characters")
        if (newPass !== confirmPass) {
          isAllfieldsValid = false
          setConfirmPasswordError("Password and confirm password doesn't match")
        } else {
          setConfirmPasswordError("")
        }
      } else {
        setNewPasswordError("")
        if (newPass !== confirmPass) {
          isAllfieldsValid = false
          setConfirmPasswordError("Password and confirm password doesn't match")
        } else {
          setConfirmPasswordError("")
        }
      }
    } else {
      setPasswordError("")
      if (!validatePassword(newPass)) {
        isAllfieldsValid = false
        setNewPasswordError("Use of minimum 7 characters")
        if (newPass !== confirmPass) {
          isAllfieldsValid = false
          setConfirmPasswordError("Password and confirm password doesn't match")
        }
      } else {
        setNewPasswordError("")
        if (newPass !== confirmPass) {
          isAllfieldsValid = false
          setConfirmPasswordError("Password and confirm password doesn't match")
        } else {
          setConfirmPasswordError("")
        }
      }
    }
    if (isAllfieldsValid) {
      resetAction()
    }
  }

  const resetAction = async () => {
    console.log("resetAction called")
    try {
      await loginUser({
        variables: {
          password: pass,
          // identifier: user || email
          identifier: email,
        },
      }).then(response => {
        console.log("reponse", response.data)
        changePasswordFunction()
      })
    } catch (loginError) {
      setLoginError("Current password doesn't match")
      console.log("login user error", loginError)
    }
  }

  const changePasswordFunction = async () => {
    try {
      await changePassword({
        variables: {
          userId: userId,
          pwd: newPass,
        },
      }).then(changeResponse => {
        console.log("changeResponse", changeResponse)
        axios({
          method: 'get',
          url: `${process.env.NODEBB_URL}/api/user/email/${email}`,
          headers: { 
            "_uid":"1",
            "Authorization":`Bearer ${process.env.NOBEBB_TOKEN}`
          }
        })
        .then(function (response) {
            console.log("11111111111111111111111111111####",response);
            if(response){
                if(response.data){
                   let uid = response.data.uid
                   if(uid){
                      axios({
                        method: 'put',
                        url: `${process.env.NODEBB_URL}/api/v3/users/${uid}/password`,
                        data:{
                          "_uid":uid,
                          "password": newPass,
                          "newPassword":newPass
                        },
                        headers: { 
                          "_uid":"1",
                          "Authorization":`Bearer ${process.env.NOBEBB_TOKEN}`
                      }})
                      .then(function (response) {
                          console.log("222222222222222222########")
                      })
                      .catch(function (error) {
                          console.log(error);
                      })
                    }
                }
            }
        })
        .catch(function (error) {
          console.log(error);
        })
        formDataReset()
        setOpen(false)
      })
    } catch (changePassError) {
      setLoginError("Failed to change password, please try again")
      console.log("login user error", changePassError)
    }
  }

  const formDataReset = () => {
    setPass("")
    setNewPass("")
    setConfirmPass("")
    setPasswordError("")
    setNewPasswordError("")
    setConfirmPasswordError("")
    // setEmail("")
    // setPass("")
    // setConfirmPass("")
    // setUserRoleID("")
    // setUserRoleName("Select Role...")
    // setEmailError("")
    // setPasswordError("")
    // setConfirmPasswordError("")
    // setUserRoleError("")
    // setLoginError("")
  }

  React.useEffect(() => {
    console.log("useEffect called")
  }, [])

  return (
    <div>
      <Dialog
        className="login-signup-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="sign-popup">
          <div className="box">
            <div className="signup-popup">
              <div className="site-logo">
                <Link to="/">
                  <img
                    src={popup_logo}
                    alt="Sticky Logo"
                    className="popuplogo"
                  ></img>
                </Link>
                <DialogContentText>Reset Password</DialogContentText>
              </div>
              <div className="login-fields">
                <div className="field">
                  <input
                    type="password"
                    id="password"
                    placeholder="Current Password"
                    value={pass}
                    onChange={e => {
                      setPass(e.target.value)
                    }}
                  />
                  {passwordError !== "" ? (
                    <div className="e-message">
                      <img src={error_logo} alt="" /> {passwordError}
                    </div>
                  ) : null}
                </div>

                <div className="field">
                  <input
                    type="password"
                    id="password"
                    placeholder="New Password"
                    value={newPass}
                    onChange={e => {
                      setNewPass(e.target.value)
                    }}
                  />
                  {newPasswordError !== "" ? (
                    <div className="e-message">
                      <img src={error_logo} alt="" /> {newPasswordError}
                    </div>
                  ) : (
                    <div className="w-message">
                      <img src={info_logo} alt="" /> Use of minimum 7 characters
                    </div>
                  )}
                </div>

                <div className="field c_pass">
                  <input
                    type="password"
                    id="c_password"
                    placeholder="Confirm Password"
                    value={confirmPass}
                    onChange={e => {
                      setConfirmPass(e.target.value)
                    }}
                  />
                  {confirmPasswordError !== "" ? (
                    <div className="e-message">
                      <img src={error_logo} alt="" /> {confirmPasswordError}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="callback-message" style={{ top: "-3px" }}>
                {loginError !== "" ? (
                  <div className="e-message">
                    <img src={error_logo} alt="" /> {loginError}
                  </div>
                ) : null}
              </div>
              <div className="submit">
                <button
                  onClick={() => {
                    handleValidation()
                  }}
                >
                  Reset Password
                </button>
              </div>

              {/*<TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={e => { setEmail(e.target.value)}}
              />

              <TextField
                autoFocus={false}
                margin="dense"
                id="password"
                label="password"
                type="email"
                fullWidth
                value={pass}
                onChange={e => {
                  setPass(e.target.value)
                }}
              />

              <TextField
                autoFocus={false}
                margin="dense"
                id="password"
                label="Confirm Password"
                type="password"
                fullWidth
                value={pass}
                onChange={e => {
                  setPass(e.target.value)
                }}
              /> */}
            </div>
          </div>
        </DialogContent>
        {/*<DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions> */}
        <img
          className="closeicon"
          onClick={() => {
            handleClose()
          }}
          src={close_image}
          alt=""
        />
      </Dialog>
    </div>
  )
}
