
import React, { useState } from 'react';

import { Upload, message, Button, Select, Input, Spin } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import 'antd/lib/upload/style/index.css'
import 'antd/lib/message/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/dist/antd.css'


import apiUrl from '../../api/apiUrl';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';





const { Dragger } = Upload;
const { Option } = Select;

function ExtraCaricular({ extraDocs, id }) {

    const [extraCarricularDocs, setextraCarricularDocs] = useState(extraDocs ?? [])
    const [newDocuments, setnewDocuments] = useState([''])
    const [isLoading, setisLoading] = useState(false)
    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;



    const deleteFile = async (itemid) => {
        setisLoading(true)
        try {
            var tempexpDocument = [...extraCarricularDocs]
            var newexpDocument = tempexpDocument.filter(item => item?.id !== itemid).map(item => { return { Title: item.Title, Certificate: item.Certificate.id } })
            console.log(newexpDocument)
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { ExtraCarricular: newexpDocument })
            updateUser?.data?.ExtraCarricular && setextraCarricularDocs(updateUser?.data?.ExtraCarricular)
            setisLoading(false)

        } catch (error) {
            console.log(error)
            setisLoading(false)

        }
    }

    const uploadExpDocument = async (e, i) => {
        setisLoading(true)
        try {
            var tempexpDocument = [...extraCarricularDocs]
            var expDocument = tempexpDocument.map(item => { return { Title: item.Title, Certificate: item.Certificate.id } })
            console.log('exp', expDocument)

            // var tempDoc = {}
            // tempDoc.company = newDocuments[index]
            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            //EDUDocuments[feild] = uploadResp.data[0].id
            var tempDoc = {}
            tempDoc.Title = newDocuments[i]
            tempDoc.Certificate = uploadResp.data[0].id
            expDocument.push(tempDoc)
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { ExtraCarricular: expDocument })
            updateUser?.data?.ExtraCarricular && setextraCarricularDocs(updateUser?.data?.ExtraCarricular)
            setnewDocuments([])
            setisLoading(false)
        } catch (error) {
            console.log(error)
            setnewDocuments([])
            setisLoading(false)
        }
    }

    const changeText = (value, index) => {
        var newDoc = [...newDocuments]
        newDoc[index] = value
        // console.log(value,index)
        setnewDocuments(newDoc)
    }

    const addNewDocs = () => {
        var tempDoc = [...newDocuments]
        tempDoc.push('')
        setnewDocuments(tempDoc)
    }


    if (isLoading) {
        return (
            <div className="spin">
                <Spin indicator={antIcon} />
            </div>
        )


    }

    return (
        <>



            {extraCarricularDocs.length >= 0 && <>
                {extraCarricularDocs.map((item, index) =>
                    <div className="row mt-3" key={index}>
                        <div className="col">


                            <Input placeholder="Enter Company Name" value={item.Title} />

                        </div>

                        <div className="col text-center d-flex justify-content-between">
                            {/* <Button >View Document</Button> */}

                            <Button href={`${apiUrl.host}${item?.Certificate?.url}`} target="_blank">View Document</Button>
                            {/* <Button size={"middle"} onClick={()=>{saveInfo()}}>
                                Save
                            </Button> */}

                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile(item?.id) }} />
                        </div>

                    </div>)}



            </>}


            {newDocuments.map((item, index) =>
                <div className="row mt-3" key={index}>
                    <div className="col">
                        <Select style={{ width: '100%' }} onChange={(e) => changeText(e, index)} placeholder={'Select Activity'} value={newDocuments[index]}>
                            <Option value="Student Government"> Student Government</Option>
                            <Option value="The Debate Team students">The Debate Team students</Option>
                            <Option value="The Arts Team students">The Arts Team students</Option>
                            <Option value="Internships"> Internships</Option>
                            <Option value="Culture Clubs">Culture Clubs</Option>
                            <Option value="Coding and Other Web Skills">Coding and Other Web Skills</Option>
                            <Option value="Sports">Sports</Option>
                            <Option value="Music Group"> Music Group</Option>

                        </Select>
                    </div>


                    <div className="col">
                        <Dragger accept={accpted} multiple={false} showUploadList={false} className="p-0"
                            onChange={(e) => uploadExpDocument(e, index)}
                        >
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </div>

                </div>)}


            <div className="text-end mt-3 mb-3 p-1">
                <div className="cta-primary">
                    <Button type="primary" size={"medium"} onClick={() => { addNewDocs() }}>
                        Add More
                    </Button>
                </div>
            </div>



        </>
    )
}

export default ExtraCaricular;
