import React, { useState } from "react"
import complete_image from "../assets/images/path-1563@1x.png"
import final_image from "../assets/images/path-1557@1x.png"
// import univ1 from "../assets/images/univ1.png"
// import univ2 from "../assets/images/univ2.png"
import { useQuery, useMutation } from "@apollo/client"
import { USER_WISHLIST_BOOKMARK_QUERY } from "../components/graphql"
import LinesEllipsis from "react-lines-ellipsis"
import { Link } from "gatsby"
import axios from "axios"
import AppoinmentBooking from "./dialog/appoinment"

function Dashboard(props) {
  const [userId, setUserID] = useState(undefined)
  const [followingCount, setFollowingCount] = useState(0)
  const [followerCount, setFollowerCount] = useState(0)
  const [ansQnCount, setAnsQnCount] = useState(0)
  const [questionCount, setQuestionCount] = useState(0)
  const [showAppoinment, setshowAppoinment] = useState(false)

  const userWishlistBookmarkData = useQuery(USER_WISHLIST_BOOKMARK_QUERY, {
    variables: { id: userId },
  })
  console.log("dashboardddddddddddd", props)

  React.useEffect(() => {
    setUserID(localStorage.getItem("userId"))
  }, [])

  var lastIndexofActive = props.userValue.data.user.journeyStepContent
    ? props.userValue.data.user.journeyStepContent
      .map(e => e.active)
      .lastIndexOf(true)
    : 0
  var percent = 0
  if (props.userValue.data.user.email) {
    axios({
      method: "get",
      url: `${process.env.NODEBB_URL}/api/user/email/${props.userValue.data.user.email}`,
      headers: {
        _uid: "1",
        Authorization: `Bearer ${process.env.NOBEBB_TOKEN}`,
      },
    })
      .then(function (response) {
        console.log("::::::::::::::>>>>>>>>>>>", response)
        if (response) {
          if (response.data) {
            setFollowingCount(response.data.followingCount)
            setFollowerCount(response.data.followerCount)
            setAnsQnCount(response.data.postcount - response.data.topiccount)
            setQuestionCount(response.data.topiccount)
            console.log(
              followingCount,
              "========***************===========",
              followerCount
            )
          }
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  if (
    props.userValue.data.user.firstName &&
    props.userValue.data.user.firstName !== "null" &&
    props.userValue.data.user.firstName !== ""
  ) {
    percent = 20
  }
  if (props.userValue.data.user.userRole) {
    if (props.userValue.data.user.userRole.name === "Student") {
      if (props.userValue.data.user.educationBackground.length > 0) {
        percent += 20
      }
      if (props.userValue.data.user.futureEducationPreferences.length > 0) {
        percent += 20
      }
      if (props.userValue.data.user.workExperience.length > 0) {
        percent += 20
      }
      if (props.userValue.data.user.studyAbroadExamContent.length > 0) {
        var flag = 0
        props.userValue.data.user.studyAbroadExamContent.forEach(record => {
          if(record){
            if (record.markValue) {
              flag = 1
            }
          }
        })
        if (flag === 1) {
          percent += 20
        }
      }
    } else {
      if (props.userValue.data.user.companyDetail.length > 0) {
        percent += 40
      }
      if (props.userValue.data.user.workExperience.length > 0) {
        percent += 40
      }
    }
  }
  return (
    <div id="dashboard" role="tabpanel" aria-labelledby="pills-home-tab">
      <div className="top-section">
        <div className="name-section">
          <div className="name">
            Bonjour <span>{props.userValue.data.user.firstName}!</span>
          </div>
          <div className="complete-profile">
            Complete your profile for better suggestions
          </div>
        </div>
      </div>
      {percent !== 100 ? (
        <div className="profile-completion">
          <div className="left">
            <div className="title">Profile Completion</div>
            <p>Complete your profile for better suggestions</p>
          </div>
          <div className="right">{percent}%</div>
          <div className="progress-section">
            <span style={{ width: percent + "%" }}></span>
          </div>
        </div>
      ) : null}
      {props.userValue.data.user.userRole ? (
        <>
          {props.userValue.data.user.userRole.name === "Student" &&
            props.userValue.data.user.journeyStepContent ? (
            <div className="jouney">
              <div className="title">Journey Mapping</div>
              <div className="progress-steps">
                <div className="steps-line"></div>
                <div className="steps">
                  {props.userValue.data
                    ? props.userValue.data.user.journeyStepContent
                      ? props.userValue.data.user.journeyStepContent.map(
                        (content, index) => (
                          <>
                            {index + 1 !==
                              props.userValue.data.user.journeyStepContent
                                .length ? (
                              <>
                                {lastIndexofActive + 1 === index ? (
                                  <div className="step active" key={index}>
                                    <span>{content.journeystep.name}</span>
                                  </div>
                                ) : (
                                  <>
                                    {content.active ? (
                                      <div
                                        className="step finish"
                                        key={index}
                                      >
                                        <img
                                          src={complete_image}
                                          alt=""
                                          className="complete"
                                        />
                                      </div>
                                    ) : (
                                      <div className="step" key={index} />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="step finish" key={index}>
                                <img
                                  src={final_image}
                                  alt=""
                                  className="complete"
                                />
                              </div>
                            )}
                          </>
                        )
                      )
                      : ""
                    : ""}
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      <>

        <div className="activity">
          <div className="title">My Activity</div>
          <div className="activity-list">
            <div className="follow">
              <div className="count">{followingCount}</div>
              <div className="text">Following</div>
            </div>
            <div className="follow">
              <div className="count">{followerCount}</div>
              <div className="text">Followers</div>
            </div>
            <div className="follow">
              <div className="count">{ansQnCount}</div>
              <div className="text">Answers</div>
            </div>
            <div className="follow">
              <div className="count">{questionCount}</div>
              <div className="text">Questions</div>
            </div>
          </div>
        </div>


        <div className="row inline" >
          <div className="col">
            <div className="dashcard">
              <span className="head">Best picks</span>
              <p>From best articles to universities, we got your back!</p>
            </div>
          </div>
          <div className="col">
            <div className="dashcard">
              <span className="head">Wishlist</span>
              <p>The wishes which you can saved for later, and it doesn't seems so hard with us.</p>
            </div>
          </div>
        </div>

        <div className="row inline" >
          <div className="col">
            <div className="dashcard">
              <span className="head">Housing Status</span>
              <p>Check status of your desired housing property.</p>
            </div>
          </div>

          {showAppoinment && <AppoinmentBooking isOpen={showAppoinment} handleClose={()=>setshowAppoinment(false)} />}
          <div className="col" onClick={()=> setshowAppoinment(true)}>
            <div className="dashcard">
              <span className="head">Talk To Us</span>
              <p>Get to know more about our services and experience the hassle free move in to France for your studies.</p>
            </div>
          </div>
        </div>


      </>


      {
        props.userValue.data.user.userRole ? (
          <>
            {props.userValue.data.user.userRole.name === "Student" ? (
              <div className="wishlist-articles">
                <div className="row">
                  {/*<div className="col-md-12 col-lg-6 left">
                                <div className="title">Wish Listed Articles</div>
                                <div className="list">
                                    <div className="box">
                                        <h6>TRAVEL PREPARATION</h6>
                                        <p>Scholarships For Syrian Students In Exile In France</p>
                                    </div>
                                    <div className="box">
                                        <h6>Education Loan</h6>
                                        <p>Guide For Asylum Seekers In Paris</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="title">Wish Listed Universities</div>
                                <div className="list">
                                    <div className="box">
                                        <img src={univ1} alt="" />
                                        <div className="rating"><i className="fa fa-star"
                                            aria-hidden="true"></i> <span> 4.2 </span></div>
                                        <h6>University of Paris</h6>
                                        <p>International School Of management</p>
                                    </div>
                                    <div className="box">
                                        <img src={univ2} alt="" />
                                        <div className="rating"><i className="fa fa-star"
                                            aria-hidden="true"></i> <span> 4.2 </span></div>
                                        <h6>Strasbourg University</h6>
                                        <p>International School Of management</p>
                                    </div>
                                </div>
                            </div> */}
                  {/* <div className="col-md-12 col-lg-6 left">
                                <div className="title">
                                    <span>Best picks</span>
                                    <p>From best articles to universities, we got your back!</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="title">
                                    <span>Wishlist</span>
                                    <p>The wishes which you can saved for later, and it doesn't seems so hard with us.</p>
                                </div>
                            </div> */}
                  <div className="wishlist-articles">
                    <div className="row">
                      {userWishlistBookmarkData.data ? (
                        <>
                          {userWishlistBookmarkData.data.user ? (
                            <>
                              {userWishlistBookmarkData.data.user.bookmarkArticles
                                .length > 0 ? (
                                <div className="col-md-12 col-lg-6 left">
                                  <div className="title">
                                    Wish Listed Articles
                                  </div>
                                  <div className="list">
                                    {userWishlistBookmarkData.data.user.bookmarkArticles.map(
                                      (content, index) => (
                                        <>
                                          {index === 0 || index === 1 ? (
                                            <div className="box" key={index}>
                                              <h6>{content.topic.title}</h6>
                                              <Link
                                                to={`/blog/${content.url}/`}
                                                // state={{ id: content.id }}
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                              >
                                                <LinesEllipsis
                                                  text={content.title}
                                                  maxLine="3"
                                                  ellipsis="..."
                                                  trimRight
                                                  basedOn="letters"
                                                />
                                              </Link>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : null}

                      {userWishlistBookmarkData.data ? (
                        <>
                          {userWishlistBookmarkData.data.user ? (
                            <>
                              {userWishlistBookmarkData.data.user
                                .wishlisted_universities.length > 0 ? (
                                <div class="col-md-12 col-lg-6">
                                  <div class="title">
                                    Wish Listed Universities
                                  </div>
                                  <div class="list">
                                    {userWishlistBookmarkData.data.user.wishlisted_universities.map(
                                      (content, index) => (
                                        <>
                                          {index === 0 || index === 1 ? (
                                            <div class="box" key={index}>
                                              {content.thumbnailImage ? <img
                                                src={`${process.env.BACKEND_URL}${content.thumbnailImage.url}`}
                                                alt="thumbnailImage"
                                              /> : <img
                                                src={require("../assets/images/default-university-thumbnail.png")}
                                                alt="thumbnailImage"
                                              />}
                                              <div class="rating">
                                                <i
                                                  class="fa fa-star"
                                                  aria-hidden="true"
                                                ></i>
                                                <span>{content.rating ? parseFloat(content.rating).toFixed(1) : 0}</span>
                                              </div>
                                              <h6>{content.universityName ? content.universityName : 0}</h6>
                                              <p>{content.universitySubtitle ? content.universitySubtitle : 0}</p>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null
      }
    </div >
  )
}
export default Dashboard
