
import React from 'react';

import { Upload, Spin, Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import apiUrl from '../../api/apiUrl';
import axios from 'axios';


import { Radio } from 'antd';

import 'antd/lib/radio/style/index.css'
import 'antd/lib/spin/style/index.css'

import { LoadingOutlined } from '@ant-design/icons';


const { Dragger } = Upload;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


function EnglishProficiency({ proDoc, id }) {

    const [proficiencyDocs, setproficiencyDocs] = React.useState(proDoc)

    console.log('udata', 'pro', proDoc)

    const [exam, setexam] = React.useState('')
    const [examIsLoading, setexamIsLoading] = React.useState(false)
    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"




    const uploadFile = async (e) => {

        //EnglishProficiencyDocs: {Test: "test", EnglishProficiency: "620a3af1a404d8723e4513a4"}

        var proficiencyDoc = { Test: exam }

        console.log('upload function added')

        try {
            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            proficiencyDoc.EnglishProficiency = uploadResp.data[0].id

            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { EnglishProficiencyDocs: proficiencyDoc })
            updateUser?.data?.EnglishProficiencyDocs && setproficiencyDocs(updateUser?.data?.EnglishProficiencyDocs)
            setexamIsLoading(false)

        } catch (error) {
            console.log(error)
            setexamIsLoading(false)


        }
    }


    const deleteFile = async () => {
        setexamIsLoading(true)
        try {
           
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { EnglishProficiencyDocs: null })
            setproficiencyDocs(updateUser?.data?.EnglishProficiency || {})
            setexamIsLoading(false)
        } catch (error) {
           console.log(error)
           setexamIsLoading(false)

        }
    }






    return (
        <>


            {proficiencyDocs?.EnglishProficiency ? <div className="row">
                {/* <label>Please select English Proficiency Test</label> */}

                <div className="col mt-2">

                    <span>IELTS</span>

                </div>
                <div className="col mt-2 pl-2">
                    <div className="col text-center">
                    <Button href={`${apiUrl.host}${proficiencyDocs?.EnglishProficiency?.url}`} target="_blank">View Document</Button>

                        <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={()=>deleteFile()} />
                    </div>
                </div>
            </div>

                :

                <div className="row">
                    <label>Please select English Proficiency Test</label>

                    <div className="row">



                        <div className="col">

                            <div className="mt-4">
                                <Radio.Group buttonStyle="solid" onChange={(e) => setexam(e.target.value)}>
                                    <Radio.Button value="IELTS">IELTS</Radio.Button>
                                    <Radio.Button value="TOEFL">TOEFL</Radio.Button>
                                    <Radio.Button value="PTE">PTE</Radio.Button>
                                    <Radio.Button value="Duolingo">Duolingo</Radio.Button>
                                </Radio.Group>
                            </div>

                        </div>




                        <div className="col mt-4">
                            <div className="col">
                                {!examIsLoading ? <Dragger accept={accpted} multiple={false} showUploadList={false} disabled={!exam.length} onChange={(e)=>{uploadFile(e);setexamIsLoading(true)}}>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                                    : <Spin indicator={antIcon} />

                                }
                            </div>
                        </div>

                    </div>

                </div>}


            {/* <div className="col">
                        <Dragger {...settings}>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </div> */}




        </>
    )
}

export default EnglishProficiency;
