

import React from 'react'

function ApplicationProfileCount({ data }) {


    const [count, setcount] = React.useState(15)

    React.useEffect(() => {
        calculateCount()
    })

    console.log('appcount', data)




    const calculateCount = () => {
        var initialCount = 2.8
        if (data?.address) { initialCount = initialCount + 3.2 }
        if (data?.cityName) { initialCount = initialCount + 3.2 }
        if (data?.state) { initialCount = initialCount + 3.2 }
        if (data?.countryName) { initialCount = initialCount + 3.2 }
        if (data?.zipcode) { initialCount = initialCount + 3.2 }

        if (data?.educationBackground?.length) {
            initialCount = initialCount + 14
        }
        if (data?.testscore) {
            initialCount = initialCount + 14
        }

        if (data?.address) initialCount = initialCount + 1.4
        if (data?.state) initialCount = initialCount + 1.4
        if (data?.cityName) initialCount = initialCount + 1.4
        if (data?.zipcode) initialCount = initialCount + 1.4
        if (data?.countryName) initialCount = initialCount + 1.4
        if (data?.emergencyNomineeName) initialCount = initialCount + 1.4
        if (data?.emergencyNomineeContact) initialCount = initialCount + 1.4
        if (data?.nomineeRelation) initialCount = initialCount + 1.4

        if (data?.workExperience?.length) {
            initialCount = initialCount + 14
        }
        if (data?.extraCarricularActivitys?.length) {
            initialCount = initialCount + 14
        }

        if (data?.Recommendation?.length) {
            initialCount = initialCount + 14
        }

        setcount(Math.round(initialCount * 100) / 100)
    }


    return (
        <div className="document-section">
            <div className="applicationTitlename">
                Application Profile
            </div>
            <div className="row">
                <div className="col-8 complete-detail">
                    Let's make your profile noticeble and distinct
                </div>
                <div className="col-4">
                    <div className="progress-bar-contr">
                        <span style={{ width: `${count}%` }}></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationProfileCount