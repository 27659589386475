import React from "react"

import { Button } from "antd"
import { DeleteFilled } from '@ant-design/icons';
import ExtraCarricular from "./extraCarricular";


function ExtraCurricularHandler(props) {

    const [showAdd, setshowAdd] = React.useState(false)
    const [addList, setaddList] = React.useState([])


    const addNew = () => {
        var templist = [...addList]
        templist.push(1)
        setaddList(templist)
    }

    const removeFromArray = (index)=>{
        var templist = [...addList]
        templist.splice(index,1)
        setaddList(templist)
    }

    return (
        <div className="p-info-list px-3 pt-0">
            <div className="edu-background px-3">
                {/* <div className="title">Work Experience</div> */}

                {!props.data.data.user.extraCarricularActivitys.length && <ExtraCarricular
                    userValue={props.data}
                    recordID={'tempID'}
                    add={true}
                    showBtn={addNew}
                />}

                {props.data?.data &&
                    <ExtraCarricular
                        userValue={props.data}
                        recordID={'tempID'}
                        add={false}
                        showBtn={addNew}
                    />
                }

            </div>
            {addList.map((item, index) => <>
                <Button  onClick={()=>removeFromArray(index) } danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red',float:'right'}}  />
                <ExtraCarricular
                    userValue={props.data}
                    recordID={'tempID'}
                    add={true}
                    showBtn={addNew}
                />
            </>)}

        </div>
        
    )
}

export default ExtraCurricularHandler