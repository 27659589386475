import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import {FILTER_PAYMENT_BY_USERID } from "../components/graphql"
import moment from "moment"

function OrderHistory(props) {
  const userID = localStorage.getItem("userId")
  if (!userID) {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      if (userID !== undefined) {
        window.location.replace("/")
      }
    }
  }
  const userPaymentData = useQuery(FILTER_PAYMENT_BY_USERID, {
    variables: { userId: userID },
  }); 
  return (
    <div id="order-history" role="tabpanel" aria-labelledby="pills-journey-tab">
        <div className="pricing-order-history">
            <div className="cards">
                <div className="card-body">
                    <table className="responsive-table">
                        <thead>
                            <th>Razorpay Payment ID</th>
                            <th>Date</th>
                            {/* <th>Phone Number</th> */}
                            <th>Order Items</th>
                            <th>Transaction Amount</th>
                            <th>Transaction Status</th>
                        </thead>
                        <tbody>
                          { userPaymentData.data ? 
                              userPaymentData.data.pricingTransactionInformations?
                                userPaymentData?.data?.pricingTransactionInformations.length !==0 ?
                                userPaymentData.data.pricingTransactionInformations.map((data, index) => (
                                <tr>
                                    <td data-th="Razorpay Payment ID"><span>{data.razorpayPaymentID}</span></td>
                                    <td data-th="Date"><span>{moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span></td>
                                    {/* <td data-th="Phone Number"><span>{data.phoneNumber}</span></td> */}
                                    <td data-th="Order Items"><span>{data.items?
                                        <p dangerouslySetInnerHTML=
                                        {{__html: (data.items).replace(/(?:\r\n|\r|\n)/g, "<br />")
                                            }}/>:""}</span></td>
                                    <td data-th="Transaction Amount"><span>{data.transactionAmount}{" "}{data.transactionCurrency}</span></td>
                                    <td data-th="Transaction Status" className="status"><span>{data.transactionStatus}</span></td>
                                </tr>
                                ))  
                                : <tr><td className="text-center" colspan="5">No data found</td></tr> 
                              : <tr><td className="text-center" colspan="5">No data found</td></tr> 
                            : <tr><td className="text-center" colspan="5">No data found</td></tr>
                          }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}
export default OrderHistory
