import React, { useState, useEffect } from "react"

import { gql, useQuery } from '@apollo/client';

import { USER_DOCUMENTS } from './graphql'


import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Identity from "./upload/identity";
import EducationUpload from "./upload/education";
import EnglishProficiency from "./upload/proficiency";
import ExtraCaricular from "./upload/extraCaricular";
import Recommandation from "./upload/recommandation";
import OtherDocument from "./upload/other";
import WorkExperiance from "./upload/work";
import DocumentCount from "./document-count";

function Document({ id }) {

  // const [id, setuserId] = useState(localStorage.getItem("userId"))

  const userDocumentData = useQuery(USER_DOCUMENTS, {
    variables: { id: id },
    pollInterval: 0,
  })

  

  console.log("====Document====",userDocumentData)





  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    // border: `1.5px solid #99bbda`,
    // '&:not(:last-child)': {

    // },
    '&:before': {
      display: 'none',
    },
    borderRadius: '5px'
  }));


  return (
    <div id="document" role="tabpanel" aria-labelledby="pills-journey-tab">
      <div className="document-section">
        <div className="name">
          Application Documents
        </div>
        <DocumentCount userDocumentData={userDocumentData} />

        <div className="p-info">

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda',padding:'10px !important' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Identity Documents</div>
                  <div>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda',padding:'10px !important' }}>

                <Identity idDoc={userDocumentData?.data?.user?.IDDocuments} id={id} />


              </AccordionDetails>
            </Accordion>

          </div>

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Education Documents</div>
                  <div>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda',margin:'10px !important' }}>

                <EducationUpload id={id} eduDoc={userDocumentData?.data?.user?.EDUDocuments} />

              </AccordionDetails>
            </Accordion>

          </div>

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Work Experience Documents</div>
                  <div>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda' }}>

                <WorkExperiance id={id} expDoc={userDocumentData?.data?.user?.WorkExperianceDocuments} />

              </AccordionDetails>
            </Accordion>

          </div>

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">English Proficiency Documents</div>
                  <div>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda' }}>

                <EnglishProficiency proDoc={userDocumentData?.data?.user?.EnglishProficiencyDocs} id={id} />
              </AccordionDetails>
            </Accordion>

          </div>

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Extra Carricular Documents</div>
                  <div>

                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda' }}>

                <ExtraCaricular extraDocs={userDocumentData?.data?.user?.ExtraCarricular} id={id} />

              </AccordionDetails>
            </Accordion>

          </div>

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Recommandation Documents</div>
                  <div>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda' }}>

                <Recommandation recommandDoc={userDocumentData?.data?.user?.RecommendationDocuments} id={id} />

              </AccordionDetails>
            </Accordion>

          </div>

          <div className="mb-2">
            <Accordion style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Other Documents</div>
                  <div>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails style={{ border: '1px solid #99bbda' }}>

                <OtherDocument otherDoc={userDocumentData?.data?.user?.otherDocs} id={id} />
              </AccordionDetails>
            </Accordion>

          </div>

        </div>




      </div>
    </div>
  )
}
export default React.memo(Document)
