import React from 'react'

function DocumentCount({ userDocumentData }) {

  const [count, setcount] = React.useState(0)
  React.useEffect(() => {
    countCalculator()
  }, [userDocumentData?.data?.user])


  const countCalculator = () => {
    var currentProgress = 0

    console.log('datacount', 'edudoc', userDocumentData?.data?.user?.EnglishProficiencyDocs)
    console.log('datacount', 'length', userDocumentData?.data?.user?.EnglishProficiencyDocs?.length)


    if (Object.keys(userDocumentData?.data?.user?.IDDocuments || {}).length) {
      if (userDocumentData?.data?.user?.IDDocuments?.Cv || userDocumentData?.data?.user?.IDDocuments?.Passport || userDocumentData?.data?.user?.IDDocuments?.PassportBack) {
        currentProgress = currentProgress + 14
        console.log('datacount', 'id', currentProgress)
      }

    }
    if (Object.keys(userDocumentData?.data?.user?.EDUDocuments || {}).length) {
      var eduDocs = userDocumentData?.data?.user?.EDUDocuments
      if (eduDocs?.PGCertificate || eduDocs?.PGConsolidateMarksheet || eduDocs?.PGMarksheet || eduDocs?.UGCertificate || eduDocs?.UGConsolidateMarksheet || eduDocs?.UGMarksheet || eduDocs?.plustwo || eduDocs?.sslc) {
        currentProgress = currentProgress + 14
        console.log('datacount', 'edu', currentProgress)
      }

    }

    if (userDocumentData?.data?.user?.WorkExperianceDocuments?.length) {
      currentProgress = currentProgress + 14
      console.log('datacount', 'wrk', currentProgress)

    }
    if (Object.keys(userDocumentData?.data?.user?.EnglishProficiencyDocs || {}).length) {
      if (userDocumentData?.data?.user?.EnglishProficiencyDocs?.Test) {
        currentProgress = currentProgress + 14
        console.log('datacount', 'ep', currentProgress)
      }


    }
    if (userDocumentData?.data?.user?.ExtraCarricular?.length) {
      currentProgress = currentProgress + 14
      console.log('datacount', 'ec', currentProgress)

    }
    if (userDocumentData?.data?.user?.RecommendationDocuments?.length) {
      currentProgress = currentProgress + 14
      console.log('datacount', 're', currentProgress)

    }
    if (Object.keys(userDocumentData?.data?.user?.otherDocs || {}).length) {
      if (userDocumentData?.data?.user?.name) {
        currentProgress = currentProgress + 14
        console.log('datacount', 'od', currentProgress)

      }


    }

    setcount(currentProgress)
  }


  return (
    <div className="row">
      <div className="col-8 complete-detail">
        File extensions supported:pdf,doc,docx,jpeg,jpg,png
      </div>
      <div className="col-4">
        <div className="progress-bar-contr">
          <span style={{ width: `${count}%` }}></span>
        </div>
      </div>
    </div>
  )
}

export default DocumentCount