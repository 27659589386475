import React, { useState } from "react"
import { Link } from "gatsby"
import LinesEllipsis from "react-lines-ellipsis"
import trends_placeholder from "../assets/images/blog/trends_placeholder.jpg"
import { useQuery } from "@apollo/client"
import { USER_JOURNEY_ARTICLES_QUERY } from "../components/graphql"


function Recommendation(props) {
  const [currentJourney, setCurrentJourney] = useState("")
  var getCurrentJourneyStep = ""
  var finalJouneyName = ""
  if (props.userValue) {
    if (props.userValue.data.user.journeyStepContent) {
      props.userValue.data.user.journeyStepContent.map(
        (journeyContent, index) => {
          if (journeyContent.active === true) {
            getCurrentJourneyStep = journeyContent.journeystep.id
            finalJouneyName = journeyContent.journeystep.name
          }
          if (
            index ===
            props.userValue.data.user.journeyStepContent.length - 1
          ) {
            console.log("finalJouneyName", finalJouneyName)
            if (currentJourney !== getCurrentJourneyStep) {
              setCurrentJourney(getCurrentJourneyStep)
            }
          }
        }
      )
    }
  }

  const getUsersJourneyArticles = useQuery(USER_JOURNEY_ARTICLES_QUERY, {
    variables: { id: currentJourney },
  })
  console.log("currentJourney", currentJourney)
  console.log(
    "getUsersJourneyArticles ---------------------",
    getUsersJourneyArticles.data
  )
  return (
    <div
      id="recommendation"
      className="wishlist-recomm"
      role="tabpanel"
      aria-labelledby="pills-recommendation-tab"
    >
      <div className="wishlisted-colleges">
        {/* <div className="title">Recommended Colleges And Universities</div> */}
        {/* <div className="w-colleges">
                    <div className="list">
                        <div className="image"><img src={univ1_img} alt="" /></div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="far fa-heart"></i></div>
                            </div>
                            <div className="school">University of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">M.b.a.<span>Part Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image"><img src={univ2_img} alt="" /></div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image"><img src={univ3_img} alt="" /></div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="far fa-heart"></i></div>
                            </div>
                            <div className="school">University of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">M.b.a. <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image"><img src={univ2_img} alt="" /></div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image"><img src={univ4_img} alt="" /></div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="far fa-heart"></i></div>
                            </div>
                            <div className="school">Strasbourg University</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">M.b.a. <span>Full Time</span></div>
                        </div>
                    </div>
                    
                </div> */}
        <div className="bookmarks recomms">
          <div className="title">Read Articles</div>
          <p>
            Personalizing the reading experience for out believers, get things
            done seamlessly with this articles knowledge and you'll always be a
            step ahead.
          </p>
          <div className="deals row-top row">
            {getUsersJourneyArticles.data
              ? getUsersJourneyArticles.data.journeyStep
                ? getUsersJourneyArticles.data.journeyStep.articles.map(
                    (content, index) => {
                      console.log("content of articles", content)
                      return (
                        <div key={index} className="col-md-6 col-lg-3 mb-30">
                          <div className="deal-list right">
                            <div className="image">
                              {content.image.length >= 1 ? (
                                <img
                                  src={
                                    content.image[0].url
                                      ? process.env.BACKEND_URL +
                                        content.image[0].url
                                      : trends_placeholder
                                  }
                                  alt=""
                                />
                              ) : (
                                <img src={trends_placeholder} alt="" />
                              )}
                            </div>
                            <div className="details">
                              <h6>
                                <img
                                  src={
                                    content.topic.thumbnail
                                      ? process.env.BACKEND_URL +
                                        content.topic.thumbnail.url
                                      : ""
                                  }
                                  alt=""
                                />
                                <span>{content.topic.title}</span>
                              </h6>
                              <div className="dtitle">
                                <Link
                                  to={`/blog/${content.url}/`}
                                  // state={{ id: content.id }}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <LinesEllipsis
                                    text={content.title}
                                    maxLine="3"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  )
                : null
              : null}
            {/* <div className="col-md-12 col-lg-6">
              <div className="row mb-50">
                <div className="col-md-6 col-lg-6">
                  <div className="deal-list right">
                    <div className="image">
                      <img src={travel_img} alt="" />
                    </div>
                    <div className="details">
                      <h6>TRAVEL PREPARATION</h6>
                      <div className="dtitle">
                        Good Deals When Travelling By Train With Passenger
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 scholar">
                  <div className="deal-list right">
                    <div className="image">
                      <img src={app_process_img} alt="" />
                      <div className="overlay">
                        <div className="heart">
                          <i className="far fa-heart"></i>150
                        </div>
                        <div className="tag">
                          <img src={path1_img} alt="" />
                          24
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <h6>SCHOLARSHIP</h6>
                      <div className="dtitle">
                        Scholarships For Syrian Students In Exile In France
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="deal-list right">
                    <div className="image">
                      <img src={travel_img} alt="" />
                    </div>
                    <div className="details">
                      <h6>TRAVEL PREPARATION</h6>
                      <div className="dtitle">
                        Good Deals When Travelling By Train With Passenger
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="deal-list right">
                    <div className="image">
                      <img src={scholor_img} alt="" />
                    </div>
                    <div className="details">
                      <h6>SCHOLARSHIP</h6>
                      <div className="dtitle">
                        Scholarships For Syrian Students In Exile In France
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-12 col-lg-6">
              <div className="deal-list left">
                <div className="image">
                  <img src={recomm_right_img} alt="" />
                </div>
                <div className="details">
                  <h6>TRAVEL PREPARATION</h6>
                  <div className="dtitle">
                    Scholarships For Syrian Students In Exile In France
                  </div>
                  <p>
                    A minimum of a second className honors degree in a
                    computing-related subject A minimum of a second className
                    honors degree in a computing-related subjectA minimum of a
                    second className honors degree in a computing...
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="deals row-bottom row">
            <div className="col-md-6 col-lg-3">
              <div className="deal-list right">
                <div className="image">
                  <img src={app_process_img} alt="" />
                </div>
                <div className="details">
                  <h6>APPLICATION PROCESS</h6>
                  <div className="dtitle">
                    Scholarships For Syrian Students In Exile In France
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-12 col-lg-6">
              <div className="deal-list left">
                <div className="image">
                  <img src={good_deals_img} alt="" />
                </div>
                <div className="details">
                  <h6>TRAVEL PREPARATION</h6>
                  <div className="dtitle">
                    Good Deals When Travelling By Train With Passenger
                  </div>
                  <p>a minimum of a second className honors degree in a</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
export default Recommendation
