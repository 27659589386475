import React, { useState } from "react"
import BounceIndicator from "react-activity/lib/Bounce"

import axios from 'axios'
import {
  TextField,
  MenuItem,
} from "@material-ui/core"

import { Button } from 'antd'
import { useQuery, useMutation } from "@apollo/client"
import {
  COUNTRY_QUERY,
  UPDATE_USER_MUTATION,
  VALIDATE_USER_EMAIL_ID,
} from "../graphql"

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from "@material-ui/lab/Autocomplete"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { validateEmailID } from "../../../utils"
import apiUrl from "../../api/apiUrl"
import { CheckOutlined } from '@ant-design/icons';

// import error_logo from "../../assets/images/error_info.png"

export default function UserUpdate(props) {
  console.log("===================", props)
  const userID = props.userValue.data.user.id
  const countryQuery = useQuery(COUNTRY_QUERY)
  const [updateUser] = useMutation(UPDATE_USER_MUTATION)
  const [firstName, setFirstName] = useState(
    props.userValue.data.user.firstName
  )
  const [lastName, setLastName] = useState(props.userValue.data.user.lastName)
  const [mobileNumber, setMobileNumber] = useState(
    props.userValue.data.user.mobileNumber
  )
  const [dateOfBirth, setDateOfBirth] = useState(
    props.userValue.data.user.dateOfBirth
  )
  const [email, setEmail] = useState(props.userValue.data.user.email)
  const [cityName, setCityName] = useState(props.userValue.data.user.cityName)
  const countryID = props.userValue.data.user.countryOfBirth
    ? props.userValue.data.user.countryOfBirth.id
    : ""

  // console.log(props.userValue.data.user.nationality.id, "===na===")

  const nationalityID = props.userValue.data.user.nationality
    ? props.userValue.data.user.nationality.id
    : ""


  const passportCountryID = props.userValue.data.user.passportIssueCountry
    ? props.userValue.data.user.passportIssueCountry.id
    : ""



  const defaultCountryData = props.userValue.data.user.countryOfBirth
    ? props.userValue.data.user.countryOfBirth
    : ""

  const defaultNationalityData = props.userValue.data.user.nationality
    ? props.userValue.data.user.nationality
    : ""

  const defaultPassportCountryData = props.userValue.data.user.passportIssueCountry
    ? props.userValue.data.user.passportIssueCountry
    : ""

  /* 

     middleName
    Gender
    nationality
    maritalStatus
    isDiffrentlyAbled
    passportNo
    passportIssueDate
    passportExpiryDate
    passportIssueCountry
    placeOfBirthAsPerPassport
    adhaarCardNo
    levelOfFrench

  */

  const [salutation, setsalutation] = useState(props?.userValue?.data?.user?.salutaion)
  const [middleName, setmiddleName] = useState(props?.userValue?.data?.user?.middleName)
  const [gender, setgender] = useState(props?.userValue?.data?.user?.Gender)
  const [maritalStatus, setmaritalStatus] = useState(props?.userValue?.data?.user?.maritalStatus)
  const [diffrentlyAbled, setdiffrentlyAbled] = useState(props?.userValue?.data?.user?.isDiffrentlyAbled)
  const [passportNo, setpassportNo] = useState(props?.userValue?.data?.user?.passportNo)
  const [passportIssueDate, setpassportIssueDate] = useState(props?.userValue?.data?.user?.passportIssueDate)
  const [passportExpiryDate, setpassportExpiryDate] = useState(props?.userValue?.data?.user?.passportExpiryDate)
  const [placeofBirth, setplaceofBirth] = useState(props?.userValue?.data?.user?.placeOfBirthAsPerPassport)
  const [aadharNo, setaadharNo] = useState(props?.userValue?.data?.user?.adhaarCardNo)
  const [levelOfFrench, setlevelOfFrench] = useState(props?.userValue?.data?.user?.levelOfFrench)

  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState({})

  const [salutaionError, setsalutaionError] = useState('')
  const [middlenameError, setmiddlenameError] = useState('')
  const [genderError, setgenderError] = useState('')
  const [nationalityError, setnationalityError] = useState('')
  const [maritalstatusError, setmaritalstatusError] = useState('')
  const [diffrentlyError, setdiffrentlyError] = useState('')
  const [passportNoError, setpassportNoError] = useState('')
  const [passportIssueDateError, setpassportIssueDateError] = useState('')
  const [passortExpiryDateError, setpassortExpiryDateError] = useState('')
  const [passportIssueCountryError, setpassportIssueCountryError] = useState('')
  const [placeOfBirthError, setplaceOfBirthError] = useState('')
  const [adahaarNoError, setadahaarNoError] = useState('')
  const [levelofFrenchError, setlevelofFrenchError] = useState('')


  const [countryName, setCountryName] = useState(countryID)
  const [nationality, setnationality] = useState(nationalityID)
  const [passportIssueCountry, setpassportIssueCountry] = useState(passportCountryID)

  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  // const [mobileNumberError, setMobileNumberError] = useState("")
  const [dateOfBirthError, setDateOfBirthError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [cityNameError, setCityNameError] = useState("")
  const [countryNameError, setCountryNameError] = useState("")
  const [defaultCountry, setDefaultCountry] = useState(
    defaultCountryData.countryName
  )
  const [defaultNationality, setdefaultNationality] = useState(defaultNationalityData.countryName)

  const [defaultPassportCountry, setdefaultPassportCountry] = useState(defaultPassportCountryData.countryName)

  const existingUserEmailCheck = useQuery(VALIDATE_USER_EMAIL_ID, {
    variables: { email: email },
  })




  const countryProps = {
    options: countryQuery.data ? countryQuery.data.countries : [],
    getOptionLabel: option => option.countryName,
  }

  const handleSubmitButton = () => {
    setFirstNameError("")
    setLastNameError("")
    setFirstNameError("")
    setsalutaionError('')
    setmiddlenameError('')
    setgenderError('')
    setnationalityError('')
    setmaritalstatusError('')
    setdiffrentlyError('')
    setpassportNoError('')
    setpassportIssueDateError('')
    setpassortExpiryDateError('')
    setpassportIssueCountryError('')
    setplaceOfBirthError('')
    setadahaarNoError('')
    setlevelofFrenchError('')



    var userLength = existingUserEmailCheck.data
      ? existingUserEmailCheck.data.users.length
      : -1
    var sameUser = false
    var noUser = false
    if (userLength === 1) {
      if (existingUserEmailCheck.data.users[0].id === userID) {
        sameUser = true
      }
    } else {
      noUser = true
    }
    if (!firstName) {
      setFirstNameError("Enter a valid first name")
    } else if (!lastName) {
      setLastNameError("Enter a valid last name")
      setFirstNameError("")
    }

    // if (!salutation) {
    //   setsalutaionError('This Field is Required')
    // }
    // if (!middleName) {
    //   setmiddlenameError('This Field is Required')
    // }

    if (!gender) {
      setgenderError('This Field is Required')
    }


    // if (!nationality) {
    //   setnationalityError('This Field is Required')
    // }
    // if (!maritalStatus) {
    //   setmaritalstatusError('This Field is Required')
    // }

    // if (!diffrentlyAbled) {
    //   setdiffrentlyError('This Field is Required')
    // }

    // if (!passportNo) {
    //   setpassportNoError('This Field is Required')
    // }

    // if (!passportIssueDate) {
    //   setpassportIssueDateError('This Field is Required')
    // }

    // if (!passportExpiryDate) {
    //   setpassortExpiryDateError('This Field is Required')
    // }

    // if (!passportIssueCountry) {
    //   setpassportIssueCountryError('This Field is Required')
    // }

    // if (!placeofBirth) {
    //   setplaceOfBirthError('This Field is Required')
    // }

    // if (!aadharNo) {
    //   setadahaarNoError('This Field is Required')
    // }

    // if (!levelOfFrench) {
    //   setlevelofFrenchError('This Field is Required')
    // }

    else if (!dateOfBirth) {
      setDateOfBirthError("Enter a valid date of birth")

    } else {
      if (!validateEmailID(email)) {
        setEmailError("Enter a valid mail address")

      } else {
        if (!noUser) {
          console.log('no user')
          if (!sameUser) {
            setEmailError("EmailID already registered")

          } else {
            if (!cityName) {
              setCityNameError("Enter a valid city name")

            } else if (!countryName) {
              setCountryNameError("Enter a valid country name")
            } else {
              setCountryNameError("")
              handleSubmit()
            }
            handleSubmit()
          }
        } else {
          if (!cityName) {
            setCityNameError("Enter a valid city name")
            setEmailError("")
          } else if (!countryName) {
            setCountryNameError("Enter a valid country name")
            setCityNameError("")
          } else {
            setCountryNameError("")
            handleSubmit()
          }
        }
      }
    }
  }


  const checkData = () => {




  }




  const handleSubmit = async () => {
    var isEmailChanged =
      props.userValue.data.user.secondaryEmail === email ? false : true
    var isMobileChanged =
      props.userValue.data.user.mobileNumber === mobileNumber ? false : true
    var mailOTP = isEmailChanged ? "" : props.userValue.data.user.mailOTP
    var mobileOTP = isMobileChanged ? "" : props.userValue.data.user.mobileOTP
    var isMobileVerified = isMobileChanged
      ? false
      : props.userValue.data.user.isMobileVerified
    var isMailVerified = isEmailChanged
      ? false
      : props.userValue.data.user.isMailVerified
    try {
      setIsLoading(true)
      // await updateUser({
      //   variables: {
      //     id: userID,
      //     firstName: firstName,
      //     lastName: lastName,
      //     username: email.split("@")[0],
      //     mobileNumber: mobileNumber ? mobileNumber : "+33",
      //     dateOfBirth: dateOfBirth,
      //     email: email,
      //     cityName: cityName,
      //     countryID: countryName,
      //     mailOTP: mailOTP,
      //     mobileOTP: mobileOTP,
      //     isMobileVerified: isMobileVerified,
      //     isMailVerified: isMailVerified,
      //   },
      // })

      const requestHeader = {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
        }
      }

      await axios.post(apiUrl.updateProfile, {
        id: userID,
        firstName: firstName,
        lastName: lastName,
        salutaion: salutation,
        dateOfBirth: dateOfBirth,
        email: email,
        cityName: cityName,
        countryOfBirth: countryName,
        middleName: middleName,
        Gender: gender,
        nationality: nationality,
        maritalStatus: maritalStatus,
        isDiffrentlyAbled: diffrentlyAbled,
        passportNo: passportNo,
        passportIssueDate: passportIssueDate,
        passportExpiryDate: passportExpiryDate,
        passportIssueCountry: passportIssueCountry,
        placeOfBirthAsPerPassport: placeofBirth,
        adhaarCardNo: aadharNo,
        levelOfFrench: levelOfFrench,

      }, requestHeader).then(response => {
        setIsLoading(false);
        setNotification({ error: false, message: 'Data saved successfully' });
        console.log("reponse", response?.data?.data)
        localStorage.setItem("userName", response?.data?.data?.username)
        localStorage.setItem("firstName", response?.data?.data?.firstName)
      })

    } catch (e) {
      setNotification({ error: true, message: 'failed to update user profile , Try again later' })
      setIsLoading(false)
      console.log("update user error", e.message)
    }
  }

  var onTagsChange = (event, values) => {
    if (values) {
      setCountryName(values.id)
    } else {
      setCountryName("")
    }
  }

  var onNationalityChange = (event, values) => {
    if (values) {
      setnationality(values.id)
    } else {
      setnationality("")
    }
  }

  var onPassportIssueCountry = (event, values) => {
    if (values) {
      setpassportIssueCountry(values.id)
    } else {
      setpassportIssueCountry("")
    }
  }

  return (
    <div>
      <div className="row w-100 mx-2">
        <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
      </div>
      <div className="fw-bold mb-3 ms-2">Edit Personal Information</div>

      <div className="row w-100 m-0">
        <div className="col-4">
          <TextField
            select
            // autoFocus
            margin="dense"
            className="w-100"
            id="salutaion"
            label="Salutaion"
            type="text"
            value={salutation}
            onChange={e => {
              setsalutation(e.target.value)
            }}
            helperText={salutaionError ? salutaionError : ""}
            error={salutaionError}
          >

            <MenuItem value={'Mr'} >
              Mr.
            </MenuItem>
            <MenuItem value={'Ms'} >
              Ms.
            </MenuItem>
            <MenuItem value={'Mrs'} >
              Mrs.
            </MenuItem>

            <MenuItem value={'Miss'} >
              Miss.
            </MenuItem>


          </TextField>
        </div>
      </div>
      <div className="row w-100 m-0">

        <div className="col">
          <TextField
            // autoFocus
            margin="dense"
            className="w-100"
            id="first-name"
            label="First Name"
            type="text"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value)
            }}
            helperText={firstNameError ? firstNameError : ""}
            error={firstNameError}
          />
          {"    "}

        </div>
        <div className="col">
          <TextField
            // autoFocus
            margin="dense"
            className="w-100"
            id="middle-name"
            label="Middle Name"
            type="text"
            value={middleName}
            onChange={e => {
              setmiddleName(e.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={middlenameError ? middlenameError : ""}
            error={middlenameError}
          />
          {"    "}

        </div>
        <div className="col">

          <TextField
            className="w-100"
            margin="dense"
            id="last-name"
            label="Last Name"
            type="text"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value)
            }}
            helperText={lastNameError ? lastNameError : ""}
            error={lastNameError}
          />
        </div>
      </div>
      <div className="row w-100 m-0">

        <div className="col">

          <TextField
            select
            className="w-100"
            margin="dense"
            id="last-name"
            label="Gender"
            type="text"
            value={gender}
            onChange={e => {
              setgender(e.target.value)
            }}
            helperText={genderError ? genderError : ""}
            error={genderError}
          >
            <MenuItem value={'Male'} >
              Male
            </MenuItem>
            <MenuItem value={'Female'} >
              Female
            </MenuItem>
            <MenuItem value={'Other'} >
              Other
            </MenuItem>
          </TextField>



        </div>
        <div className="col">
          <TextField
            className="w-100"
            margin="dense"
            id="date-of-birth"
            label="Date of Birth"
            type="date"
            // defaultValue={"2017-05-24"}
            placeholder=""
            InputLabelProps={{
              shrink: true,
            }}
            value={dateOfBirth}
            onChange={e => {
              setDateOfBirth(e.target.value)
            }}
            helperText={dateOfBirthError ? dateOfBirthError : ""}
            error={dateOfBirthError}
          />

        </div>

        <div className="col">
          <div className="col">
            <Autocomplete
              {...countryProps}
              id="debug"
              className="w-100"
              debug
              onChange={onTagsChange}
              defaultValue={{ countryName: defaultCountry }}
              renderInput={params => (
                <TextField
                  className="w-100"
                  {...params}
                  label="Country of Birth"
                  margin="normal"
                  error={countryNameError}
                />
              )}
            />

          </div>


        </div>

      </div>
      <div className="row w-100 m-0">
        <div className="col">
          <Autocomplete
            {...countryProps}
            id="nationality"
            className="w-100"
            debug
            onChange={onNationalityChange}
            defaultValue={{ countryName: defaultNationality }}
            renderInput={params => (
              <TextField
                className="w-100"
                {...params}
                label="Nationality"
                margin="normal"
                error={nationalityError}
              />
            )}
          />
        </div>
        <div className="col">
          <TextField
            select
            className="w-100"
            margin="dense"
            id="marital-status"
            label="Marital Status"
            type="text"
            value={maritalStatus}
            onChange={e => {
              setmaritalStatus(e.target.value)
            }}
            helperText={maritalstatusError ? maritalstatusError : ""}
            error={maritalstatusError}
          >
            <MenuItem value={'Married'}> Married</MenuItem>
            <MenuItem value={'Unmarried'}> Unmarried</MenuItem>
            <MenuItem value={'Widowed'}> Widowed</MenuItem>

          </TextField>
        </div>
        <div className="col">

          <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: 'small' }}>Are you Diffrently abled</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={diffrentlyAbled}
            onChange={(e) => setdiffrentlyAbled(e.target.value)}>
            <FormControlLabel className="" value="true" control={<Radio size="small" />} label="Yes" />
            <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
          </RadioGroup>


        </div>

      </div>

      <div className="row w-100 m-0">
        <div className="col">
          <TextField
            className="w-100"
            margin="dense"
            id="passport"
            label="Passport Number"
            type="text"
            value={passportNo}
            onChange={e => {
              setpassportNo(e.target.value)
            }}
            helperText={passportNoError ? passportNoError : ""}
            error={passportNoError}
          />
        </div>
        <div className="col">
          <TextField
            className="w-100"
            margin="dense"
            id="date-of-birth"
            label="Passport Issue Date"
            type="date"
            // defaultValue={"2017-05-24"}
            placeholder=""
            InputLabelProps={{
              shrink: true,
            }}
            value={passportIssueDate}
            onChange={e => {
              setpassportIssueDate(e.target.value)
            }}
            helperText={passportIssueDateError ? passportIssueDateError : ""}
            error={passportIssueDateError}
          />
        </div>
        <div className="col">
          <TextField
            className="w-100"
            margin="dense"
            id="date-of-birth"
            label="Passport Expiry Date"
            type="date"
            // defaultValue={"2017-05-24"}
            placeholder=""
            InputLabelProps={{
              shrink: true,
            }}
            value={passportExpiryDate}
            onChange={e => {
              setpassportExpiryDate(e.target.value)
            }}
            helperText={passortExpiryDateError ? passortExpiryDateError : ""}
            error={passortExpiryDateError}
          />
        </div>

      </div>

      <div className="row w-100 m-0">
        <div className="col-4">
          <Autocomplete
            {...countryProps}
            id="debug"
            className="w-100"
            debug
            onChange={onPassportIssueCountry}
            defaultValue={{ countryName: defaultPassportCountry }}
            renderInput={params => (
              <TextField
                className="w-100"
                {...params}
                label="Passport Issue Country"
                margin="normal"
                error={passportIssueCountryError}
              />
            )}
          />

        </div>
        <div className="col-4">
          <TextField
            className="w-100"
            margin="dense"
            id="city-name"
            label="Place of birth as per passport"
            type="text"
            // fullWidth
            value={placeofBirth}
            onChange={e => {
              setplaceofBirth(e.target.value)
            }}
            helperText={placeOfBirthError ? placeOfBirthError : ""}
            error={placeOfBirthError}
          />



        </div>

      </div>

      <div className="row w-100 m-0">
        <div className="col-4">
          <TextField
            className="w-100"
            margin="dense"
            id="city-name"
            label="Aadhaar Card Number"
            type="text"
            // fullWidth
            value={aadharNo}
            onChange={e => {
              setaadharNo(e.target.value)
            }}
            helperText={adahaarNoError ? adahaarNoError : ""}
            error={adahaarNoError}
          />{" "}

        </div>
        <div className="col-4">
          <TextField
            select
            className="w-100"
            margin="dense"
            id="city-name"
            label="Level of french"
            type="text"
            // fullWidth
            value={levelOfFrench}
            onChange={e => {
              setlevelOfFrench(e.target.value)
            }}
            helperText={levelofFrenchError ? levelofFrenchError : ""}
            error={levelofFrenchError}
          >
            <MenuItem value={'Beginner'}>Beginner</MenuItem>
            <MenuItem value={'Elementary'}>Elementary</MenuItem>
            <MenuItem value={'Intermediate'}>Intermediate</MenuItem>
            <MenuItem value={'Upper Intermediate'}>Upper Intermediate</MenuItem>


          </TextField>


        </div>

      </div>

      <div className="row w-100">
        <div className="col mt-3 mb-3 ms-3">

          {/* <Button className="cancel-btn" onClick={handleClose} color="primary">
            Cancel
          </Button> */}
          {/* <Button
            className="save-btn my-3"
            onClick={handleSubmitButton}
            color="primary"
          >
            Save
          </Button> */}
          <div className="cta-primary">
            <Button className="ms-3" type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
              Save
            </Button>
          </div>


          {/* <LoadingButton variant="contained" loading={false} style={{
            border: "none",
            outline: "none",
            backgroundColor:"red"
          }}  >
            Save
          </LoadingButton> */}

          {/* <LoadingButton variant="contained" onClick={checkData}
            loading={false}
            loadingPosition="start"

            variant="outlined"
          >
            Save
          </LoadingButton> */}

        </div>
      </div>

    </div>
  )
}
