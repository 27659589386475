import React, { useState } from "react"
// import wishlist_college_img from "../assets/images/wishlist/wishlist_college.png"
// import location_img from "../assets/images/location.png"
import location_img from "../assets/images/location.svg" 
import scholor_img  from "../assets/images/wishlist/scholor.png"
import good_deals_img from "../assets/images/wishlist/good_deals.png"
import travel_img from "../assets/images/wishlist/travel.png"
import app_process_img from "../assets/images/wishlist/app_process.png"
import { useQuery, useMutation, useApolloClient } from "@apollo/client" 
import {GET_ALL_FAVOURITE_ARTICLES_USER,
     GET_ALL_WISHLISTED_UNIVERSITIES_USER, 
     UPDATE_WISHLIST_UNIVERSITIES_DATA, 
     UPDATE_WISHLIST_UNIVERSITIES_USER,
      USER_WISHLIST_BOOKMARK_QUERY } from "../components/graphql" 
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from "gatsby"
import trends_placeholder from "../assets/images/blog/trends_placeholder.jpg"
import editor_placeholder from "../assets/images/blog/editor_placeholder.jpg"

function Wishlist(props){
    const client = useApolloClient() 
    const [updateUserWishlist] = useMutation(UPDATE_WISHLIST_UNIVERSITIES_USER) 
  const [updateUniversityWishlist] = useMutation(UPDATE_WISHLIST_UNIVERSITIES_DATA) 
    console.log("propsssssssssssssssssssssssss",props)
    const [userID, setUserID] = useState(undefined) 
    const [liked,setLiked]=useState(true) 
    const userWishlistBookmarkData = useQuery(USER_WISHLIST_BOOKMARK_QUERY, {
        variables: { id: userID }, 
        pollInterval: 3000,

    })
    React.useEffect(() => {
        console.log("useEffect called")
        setUserID(localStorage.getItem("userId"))
      }, [])
    console.log("/////////////////////////",userWishlistBookmarkData)
    
    const Unwish=(content)=>{ 
 
          
        client 
          .query({ 
            query: GET_ALL_WISHLISTED_UNIVERSITIES_USER, 
            variables: { id: userID }, 
          }) 
          .then(res => { 
          
            if (res.data.user) { 
              var articleList = [] 
     
              if (res.data.user.wishlisted_universities.length > 0) { 
                res.data.user.wishlisted_universities.map(record => { 
                  if (content.id !== record.id)  { 
                    articleList.push(record.id) 
                  } 
                }) 
              } 
            // if (res.data.user.wishlisted_universities.length > 0) { 
            // articleList= res.data.user.wishlisted_universities.filter(record=>(record.id !== content.id)) 
            // } 
              updateUserWishlist({ 
                variables: { 
                  id: userID, 
                  wishlistID: articleList 
                }, 
              }).then(response => { 
            
                var count = 0 
                if (content.wishlistCount) { 
                  count = content.wishlistCount 
                } 
                count = count - 1 
                updateUniversityWishlist({ 
                  variables: { 
                    id: content.id, 
                    wishlistCount: count, 
                  }, 
                }) 
                  .then(response => { 
                    console.log("response",response) 
                  }) 
                  .finally(e => { 
                    setLiked(false) 
                  } 
                  ) 
              }) 
            } 
          }) 
    } 



  const RenderCourseName = data =>{
    var courseNameValue=""
    if(data){
      for(var i=0;i < data.length;i++){
        if(i===0){
          courseNameValue+=data[i].courseName
        }
        else{
          courseNameValue+=","+data[i].courseName
        }
      }     
    }
    return courseNameValue
  }
     
    return(
        <div id="wishlist" className="wishlist-recomm" role="tabpanel"
        aria-labelledby="pills-wishlist-tab">
            <div className="wishlisted-colleges">
                {/* <div className="title">Wish Listed Colleges</div>
                <div className="w-colleges">
                    <div className="list">
                        <div className="image">
                            <img src={wishlist_college_img} alt="" />
                        </div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> 
                                <span>Paris, France</span>
                                <div className="wish-icon">
                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image">
                            <img src={wishlist_college_img} alt="" />
                        </div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image">
                            <img src={wishlist_college_img} alt="" />
                        </div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image">
                            <img src={wishlist_college_img} alt="" />
                        </div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image">
                            <img src={wishlist_college_img} alt="" />
                        </div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="image">
                            <img src={wishlist_college_img} alt="" />
                        </div>
                        <div className="detail">
                            <div className="loc">
                                <img src={location_img} alt="" /> <span>Paris, France</span>
                                <div className="wish-icon"><i className="fa fa-heart" aria-hidden="true"></i></div>
                            </div>
                            <div className="school">Fashion School of Paris</div>
                            <div className="manage">International School Of Management</div>
                            <div className="time">B.des <span>Full Time</span></div>
                        </div>
                    </div>
                </div> */}
                  {userWishlistBookmarkData.data?
                <>
                    {userWishlistBookmarkData.data.user?
                    <>
                    {userWishlistBookmarkData.data.user.wishlisted_universities.length > 0? 
                        <div className="wishlisted-colleges"> 
                        <div className="title">Wish Listed Colleges</div> 
                        <div className="w-colleges"> 
                        {userWishlistBookmarkData.data.user.wishlisted_universities.map( 
                            (content, index) => ( 
          
                                <div className="list" key={content.id}> 
                                {content.thumbnailImage?<div className="image"> 
                                <img 
                                src={`${process.env.BACKEND_URL}${content.thumbnailImage?.url}`} 
                                alt="thumbnailImage" 
                              />                                 
                              </div>: <div className="image"> 
                              <img 
                              src={require("../assets/images/default-university-thumbnail.png")} 
                              alt="thumbnailImage" 
                            />                                 
                            </div>}
                                <div className="detail"> 
                                    <div className="loc"> 
                                       
                                      
                                        <div className="wish-icon" onClick={()=>Unwish(content)} style={{cursor:"pointer"}}> 
                                          {liked &&  <i className="fa fa-heart" aria-hidden="true"></i>} 
                                          {!liked &&  <i className="fa fa-heart" aria-hidden="true"></i>} 
                                        </div> 
                                    </div> 
                                    <div className="school">{content.universityName?content.universityName:""}</div> 
                                    <div className="manage">{content.universitySubtitle?content.universitySubtitle:""}</div> 
                                    <div className="loc">
                                    <img src={location_img} alt="" />  
                                    <span>{content.stateName.stateName?content.stateName.stateName:""}, {content.countryName.countryName?content.countryName.countryName:""}</span> 
                                    </div>
                                    
                                    <div className="time">{content.course_names? <>{ RenderCourseName(content.course_names)}</> :""} <span>{content.course_format.courseFormat?content.course_format.courseFormat:""}</span></div> 
                                </div> 
                            </div> 
                                       
                              
                            ))} 
                        </div> 
                        </div> 
                    :null 
                 } 






                    {userWishlistBookmarkData.data.user.bookmarkArticles.length > 0?
                        <div className="bookmarks">
                            <div className="title">Bookmarked Articles</div>
                            <p>Personalizing the reading experience for out believers, get things done seamlessly with this articles knowledge and you'll always be a step ahead.</p>
                            <div className="deals row-top row">
                                {userWishlistBookmarkData.data.user.bookmarkArticles.map(
                                    (content, index) => (
                                        <>
                                            {
                                                index === 0 || (index === userWishlistBookmarkData.data.user.bookmarkArticles.length-1 && (index%2) !==0)?
                                                    <div className="col-md-12 col-lg-6">
                                                        <div className="deal-list left">
                                                            <div className="image">
                                                                {content.image.length >=2?
                                                                 <img src={
                                                                    content.image[1].url
                                                                        ? process.env.BACKEND_URL +
                                                                        content.image[1].url
                                                                        : editor_placeholder
                                                                    } alt="" />
                                                                    :
                                                                    <img src={ editor_placeholder} alt="" />
                                                                    }
                                                            </div>
                                                            <div className="details">
                                                                <h6>{content.topic.title}</h6>
                                                                <div className="dtitle editicon">
                                                                    <Link
                                                                    // state={{ id: content.id }}
                                                                    to={`/blog-detail?p=${content.url}/`}
                                                                    style={{
                                                                        textDecoration: "none",
                                                                    }}
                                                                    >
                                                                    <LinesEllipsis
                                                                        text={content.title}
                                                                        maxLine='3'
                                                                        ellipsis='...'
                                                                        trimRight
                                                                        basedOn='letters'
                                                                    />
                                                                    </Link>
                                                                </div>
                                                                <p>
                                                                    <LinesEllipsis
                                                                        text={content.description}
                                                                        maxLine='6'
                                                                        ellipsis='...'
                                                                        trimRight
                                                                        basedOn='letters'
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            :
                                            <div className="col-md-6 col-lg-3 mb-15">
                                            <div className="deal-list right">
                                                <div className="image">
                                                    {content.image.length >=1?
                                                    <img src={
                                                    content.image[0].url
                                                        ? process.env.BACKEND_URL +
                                                        content.image[0].url
                                                        : trends_placeholder
                                                    } alt="" />
                                                    :
                                                    <img src={ trends_placeholder} alt="" />
                                                    }
                                                </div>
                                                <div className="details">
                                                    <h6>{content.topic.title}</h6>
                                                    <div className="dtitle editicon">
                                                        <Link
                                                        // state={{ id: content.id }}
                                                        to={`/blog-detail?p=${content.url}/`}
                                                        style={{
                                                            textDecoration: "none",
                                                        }}
                                                        >
                                                        <LinesEllipsis
                                                            text={content.title}
                                                            maxLine='3'
                                                            ellipsis='...'
                                                            trimRight
                                                            basedOn='letters'
                                                        />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>								
                                            </div> 
                                            }
                                        </>
                                    ))
                                }
                            </div>
                            {/* <div className="deals row-bottom row">
                                {userWishlistBookmarkData.data.user.bookmarkArticles.map(
                                        (content, index) => (
                                            <>
                                                {
                                                    index === 3 || index ===4?
                                                    <div className="col-md-6 col-lg-3">
                                                    <div className="deal-list right">
                                                        <div className="image">
                                                        {content.image.length >=1?
                                                            <img src={
                                                            content.image[0].url
                                                                ? process.env.BACKEND_URL +
                                                                content.image[0].url
                                                                : trends_placeholder
                                                            } alt="" />
                                                            :
                                                            <img src={ trends_placeholder} alt="" />
                                                            }
                                                        </div>
                                                        <div className="details">
                                                            <h6>{content.topic.title}</h6>
                                                            <div className="dtitle editicon">
                                                                <Link
                                                                to={`/blog-detail`}
                                                                state={{ id: content.id }}
                                                                style={{
                                                                    textDecoration: "none",
                                                                }}
                                                                >
                                                                <LinesEllipsis
                                                                    text={content.title}
                                                                    maxLine='3'
                                                                    ellipsis='...'
                                                                    trimRight
                                                                    basedOn='letters'
                                                                />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>								
                                                </div> 
                                                :null}
                                                { index ===5 ?
                                                 <div className="col-md-12 col-lg-6">
                                                     <div className="deal-list left">
                                                         <div className="image">
                                                         {content.image.length >=2?
                                                            <img src={
                                                            content.image[1].url
                                                                ? process.env.BACKEND_URL +
                                                                content.image[1].url
                                                                : editor_placeholder
                                                            } alt="" />
                                                            :
                                                            <img src={ editor_placeholder} alt="" />
                                                            }
                                                         </div>
                                                         <div className="details">
                                                             <h6>{content.topic.title}</h6>
                                                             <div className="dtitle editicon">
                                                                <Link
                                                                to={`/blog-detail`}
                                                                state={{ id: content.id }}
                                                                style={{
                                                                    textDecoration: "none",
                                                                }}
                                                                >
                                                                <LinesEllipsis
                                                                    text={content.title}
                                                                    maxLine='3'
                                                                    ellipsis='...'
                                                                    trimRight
                                                                    basedOn='letters'
                                                                />
                                                                </Link>
                                                            </div>
                                                            <p>
                                                                <LinesEllipsis
                                                                    text={content.description}
                                                                    maxLine='6'
                                                                    ellipsis='...'
                                                                    trimRight
                                                                    basedOn='letters'
                                                                />
                                                            </p>
                                                         </div>
                                                     </div>
                                                    </div>
                                                :null}
                                            </>
                                        ))
                                    }
                            </div> */}
                        </div>
                     :null}
                    </>
                    :null}
                </>
                :null}
            </div>
        </div>
    )
}
export default Wishlist