import React, { useState, useEffect } from "react"
import moment from "moment"
import location_img from "../assets/images/location.png"
import dob_img from "../assets/images/dob.png"
import mobile_img from "../assets/images/mobile.png"
import mail_img from "../assets/images/mail.png"
import edit_img from "../assets/images/editicon.png"
import add_img from "../assets/images/plus.png"
import check_img from '../assets/images/check_green.png'
import { useMutation, useQuery } from "@apollo/client"
import UserUpdateFormDialog from "./dialog/userUpdateDialog"
import UserUpdateEducationFormDialog from "./dialog/educationDialog"
import UserUpdateFutureEducationFormDialog from "./dialog/futureEducatioPrefDialog"
import UserUpdateWorkExperienceFormDialog from "./dialog/workExperienceDialog"
import UserUpdateCompanyDetailFormDialog from "./dialog/companyDetailDialog"
import UserUpdateStudyAbroadExamFormDialog from "./dialog/studyAdroadExamDialog"
import { Badge } from "reactstrap"
import {
  PROFILE_VERIFICATION,
  USER_MOBILE_OTP_UPDATE,
  USER_MAIL_OTP_UPDATE,
  USER_MOBILE_VERIFY_STATUS_UPDATE,
  USER_MAIL_VERIFY_STATUS_UPDATE,
} from "../components/graphql"
import error_logo from "../assets/images/error_info.png"
import { TextField } from "@material-ui/core"
import ApplicationProfileCount from "./applicationProfileCount"

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

import WorkExperianceHandler from "./dialog/workExperianceHandler"
import RecommendationHandler from "./dialog/recommendationHandler"
import ExtraCurricularHandler from "./dialog/extraCurricularHandler"


import UserUpdate from "./dialog/userUpdate"
import UserUpdateEducation from "./dialog/education"
import UserUpdateCompanyDetail from "./dialog/companyDetail"
import UserUpdateWorkExperience from "./dialog/workExperience"
import UserUpdateFutureEducation from "./dialog/futureEducatioPref"
import UserUpdateStudyAbroadExam from "./dialog/studyAdroadExam"
import AddressContact from "./dialog/addressContact"
import ExtraCarricular from "./dialog/extraCarricular"
import Recommendation from "./dialog/recommandation"
import TestScore from "./dialog/testScore"
import 'antd/dist/antd.css';
import EducationHandler from "./dialog/educationHandler"


function Profile(props) {

  const dateOfBirth = props.userValue.data.user.dateOfBirth
    ? new Date(props.userValue.data.user.dateOfBirth)
    : null
  const dobValue = dateOfBirth
    ? moment(dateOfBirth).format("Do MMMM, YYYY")
    : false
 
  const [editCompanyDetails, setEditCompanyDetails] = useState(false)
  const [addCompanyDetails, setAddCompanyDetails] = useState(false)
  // const [addStudyAbroadExam,setAddStudyAbroadExam] = useState(false)
  const [editStudyAbroadExam, setEditStudyAbroadExam] = useState(false)
  const [isVerificationOnprocess, setIsVerificationOnprocess] = useState(false)
  const [tempID, setTempID] = useState(false)
  const [profileVerification] = useMutation(PROFILE_VERIFICATION)
  const [mobileOTPUpdate] = useMutation(USER_MOBILE_OTP_UPDATE)
  const [mailOTPUpdate] = useMutation(USER_MAIL_OTP_UPDATE)
  const [mobileStatusUpdate] = useMutation(USER_MOBILE_VERIFY_STATUS_UPDATE)
  const [mailStatusUpdate] = useMutation(USER_MAIL_VERIFY_STATUS_UPDATE)
  const [typedMobileOTP, setTypedMobileOTP] = useState("")
  const [typedMailOTP, setTypedMailOTP] = useState("")



  





  //accordion state

  const [expanded, setExpanded] = React.useState(false);
  const [showAbroad, setshowAbroad] = React.useState(true)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const refreshPage = () => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.reload()
    }
  }



  var otpSequence = (Math.floor(Math.random() * 10000) + 10000)
    .toString()
    .substring(1)

  var mailOTP =
    props.userValue.data.user.mailOTP === null
      ? ""
      : props.userValue.data.user.mailOTP

  var mobileOTP =
    props.userValue.data.user.mobileOTP === null
      ? ""
      : props.userValue.data.user.mobileOTP

  var isMailVerfied = props.userValue.data.user.isMailVerified

  var isMobileVerfied = props.userValue.data.user.isMobileVerified

  var onMobileOTPChange = e => {
    setTypedMobileOTP(e.target.value)
  }

  var onMailOTPChange = e => {
    setTypedMailOTP(e.target.value)
  }

  useEffect(() => {
    console.log('userrrrrrrrrrrrr vale', props.userValue.data.user)
  }, []);


  // console.log("typedMobileOTP", typedMobileOTP)
  // console.log("typedMailOTP", typedMailOTP)
  // console.log("mailOTP", mailOTP)
  // console.log("mobileOTP", mobileOTP)

  const updateMobileStatus = async () => {
    try {
      await mobileStatusUpdate({
        variables: {
          status: true,
          userId: props.userValue.data.user.id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
        },
      }).then(mobileStatusResponse => {
        console.log("mobileStatusResponse", mobileStatusResponse)
      })
    } catch (mobileStatusUpdate_error) {
      console.log("mobile status update error", mobileStatusUpdate_error)
    }
  }

  const updateMailStatus = async () => {
    try {
      await mailStatusUpdate({
        variables: {
          status: true,
          userId: props.userValue.data.user.id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
        },
      }).then(mailStatusResponse => {
        console.log("mailStatusResponse", mailStatusResponse)
      })
    } catch (mailStatusUpdate_error) {
      console.log("mail status update error", mailStatusUpdate_error)
    }
  }

  const updateMobileOTP = async otp => {
    try {
      await mobileOTPUpdate({
        variables: {
          otp: otp,
          userId: props.userValue.data.user.id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
        },
      }).then(mobileResponse => {
        // console.log("mobileResponse", mobileResponse)
      })
    } catch (mobileOtpupdate_error) {
      console.log("mobile otp update error", mobileOtpupdate_error)
    }
  }

  const updateMailOTP = async otp => {
    try {
      await mailOTPUpdate({
        variables: {
          otp: otp,
          userId: props.userValue.data.user.id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
        },
      }).then(mailResponse => {
        // console.log("mailResponse", mailResponse)
      })
    } catch (mailOtpupdate_error) {
      console.log("mail otp update error", mailOtpupdate_error)
    }
  }

  const profileVerificationFunction = async (verifyEmail, verifyPhone) => {
    try {
      await profileVerification({
        variables: {
          email: verifyEmail,
          userId: props.userValue.data.user.id,
          otp: otpSequence,
          userName: props.userValue.data.user.firstName,
          phone: verifyPhone,
        },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
        },
      }).then(profileVerificationResponse => {
        // console.log("profileVerificationResponse", profileVerificationResponse)
        // console.log("otpSequence", otpSequence)
        if (verifyEmail === "") {
          updateMobileOTP(otpSequence)
        } else {
          updateMailOTP(otpSequence)
        }
      })
    } catch (e) {
      console.log("profile verification error", e)
      // setPasswordError("Email and password doesn't match")
    }
  }

  console.log("props.userValue.data.user :  ", props.userValue.data.user)



  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    // border: `1.5px solid #99bbda`,
    // '&:not(:last-child)': {

    // },
    '&:before': {
      display: 'none',
    },
    borderRadius: '5px'
  }));

  return (
    <div id="profile" role="tabpanel" aria-labelledby="pills-profile-tab">

      <ApplicationProfileCount data={props?.userValue?.data?.user} />


      <div className="p-info">

        <div className="my-3">
          <Accordion style={{ border: '1.5px solid #99bbda' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"

            >
              <div className="d-flex justify-content-between w-100">
                <div className="ms-5 bd-highlight fw-bold">Personal Information</div>

                <div>

                  {props?.userValue?.data?.user?.firstName && props?.userValue?.data?.user?.lastName && props?.userValue?.data?.user?.dateOfBirth && <div className="d-flex justify-content-between w-100" id="vbagde">
                      <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                      <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                    </div>}

                </div>

              </div>

            </AccordionSummary>
            <AccordionDetails style={{ border: '1px solid #99bbda' }}>

              <UserUpdate userValue={props.userValue} />

            </AccordionDetails>
          </Accordion>

        </div>


        {props.userValue.data.user.userRole ? (
          <>

            {props.userValue.data.user.userRole.name !== "Consultant" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <div className="d-flex justify-content-between w-100">
                    <div className="ms-5 bd-highlight fw-bold">Address {'&'} Contact</div>
                    <div>
                      {props?.userValue?.data?.user?.address && 
                      props?.userValue?.data?.user?.state &&  
                      props?.userValue?.data?.user?.cityName &&  
                      props?.userValue?.data?.user?.zipcode &&  
                      props?.userValue?.data?.user?.countryName &&  
                      props?.userValue?.data?.user?.emergencyNomineeName && 
                      props?.userValue?.data?.user?.emergencyNomineeContact && 
                      props?.userValue?.data?.user?.nomineeRelation && 
                      <div className="d-flex justify-content-between w-100" id="vbagde">
                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails >
                  <div className="p-info-list p-0">
                    <div className="edu-background m-3">
                      <AddressContact
                        userValue={props.userValue}
                        recordID={tempID}
                        add={true}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

            ) : null}




            {props.userValue.data.user.userRole.name !== "Consultant" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <div className="d-flex justify-content-between w-100">
                    <div className="ms-5 bd-highlight fw-bold">Education Background</div>
                    <div>
                      {props?.userValue?.data?.user?.educationBackground.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">
                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails >
                 <EducationHandler data={props.userValue}/>
                </AccordionDetails>
              </Accordion>

            ) : null}

            {props.userValue.data.user.userRole.name === "Consultant" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-100 mt-2 ">
                    <div className="ms-5 bd-highlight fw-bold">Company Details</div>
                    <div>

                      {props?.userValue?.data?.user?.companyDetail.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">

                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}

                    </div>

                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="p-info-list p-0">
                    <div className="edu-background m-3">
                      {/* <div className="title">Company Details</div> */}
                      {props.userValue.data
                        ? props.userValue.data.user.companyDetail.map(
                          (content, index) => (

                            <Accordion className="w-100" onClick={() => { setTempID(content.id) }}>
                              <AccordionSummary className="w-100" expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header2">
                                <>
                                  {index + 1 ===
                                    props.userValue.data.user.companyDetail.length ? (
                                    <div className="list last" key={index}>
                                      <div>{content.companyName}</div>
                                      <div className="blue-text">
                                        {content.companyURL}
                                      </div>

                                      <p className="width-70">
                                        {content.companyAddress}{" "}
                                      </p>
                                      <div
                                        className="icons"
                                        role="presentation"
                                      // onClick={() => {
                                      //   setEditCompanyDetails(!editCompanyDetails)
                                      //   setTempID(content.id)
                                      // }}
                                      // onKeyDown={() => {
                                      //   setEditCompanyDetails(!editCompanyDetails)
                                      //   setTempID(content.id)
                                      // }}
                                      >
                                        {/* <img src={edit_img} alt="" />
                                        <span>Edit</span> */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="list">
                                      <div>{content.companyName}</div>
                                      <div className="blue-text">
                                        {content.companyURL}
                                      </div>

                                      <p className="width-70">
                                        {content.companyAddress}{" "}
                                      </p>
                                      <div
                                        className="icons"
                                        role="presentation"
                                      // onClick={() => {
                                      //   setEditCompanyDetails(!editCompanyDetails)
                                      //   setTempID(content.id)
                                      // }}
                                      // onKeyDown={() => {
                                      //   setEditCompanyDetails(!editCompanyDetails)
                                      //   setTempID(content.id)
                                      // }}
                                      >
                                        {/* <img src={edit_img} alt="" />
                                    <span>Edit</span> */}
                                      </div>
                                    </div>
                                  )}
                                </>
                              </AccordionSummary>
                              <AccordionDetails>
                                <UserUpdateCompanyDetail
                                  userValue={props.userValue}
                                  recordID={tempID}
                                  add={false}
                                />
                              </AccordionDetails>
                            </Accordion>
                          )
                        )
                        : null}


                      <Accordion className="w-100" >
                        <AccordionSummary className="w-100" expandIcon={<img src={add_img} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header2">
                          <div className="editicon t-right">
                            <div
                              className="icons"
                              role="presentation"
                            // onClick={() => setAddCompanyDetails(!addCompanyDetails)}
                            // onKeyDown={() => setAddCompanyDetails(!addCompanyDetails)}
                            >
                              {/* <img src={add_img} alt="" /> */}
                              {/* <span>Add</span> */}
                            </div>
                          </div>
                          {addCompanyDetails ? (
                            <UserUpdateCompanyDetailFormDialog
                              userValue={props.userValue}
                              add={true}
                            />
                          ) : null}
                          {editCompanyDetails ? (
                            <UserUpdateCompanyDetailFormDialog
                              userValue={props.userValue}
                              recordID={tempID}
                              add={false}
                            />
                          ) : null}
                        </AccordionSummary>
                        <AccordionDetails>
                          <UserUpdateCompanyDetail
                            userValue={props.userValue}
                            add={true}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : null}

            {props.userValue.data.user.userRole.name !== "Consultant" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <div className="d-flex justify-content-between w-100">
                    <div className="ms-5 bd-highlight fw-bold">Test Score</div>
                    <div>
                      {props?.userValue?.data?.user?.testscore  && <div className="d-flex justify-content-between w-100" id="vbagde">
                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails >
                  <div className="p-info-list p-0">
                    <div className="edu-background m-3">
                      <TestScore
                        userValue={props.userValue}
                        recordID={tempID}
                        add={true}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

            ) : null}

            {/* {props.userValue.data.user.userRole.name === "Student" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"

                >
                  <div className="d-flex justify-content-between w-100 mt-2 ">
                    <div className="ms-5 bd-highlight fw-bold">Future Education Preferences</div>
                    <div>

                      {props?.userValue?.data?.user?.futureEducationPreferences.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">

                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}

                    </div>

                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="p-info-list">
                    <div className="edu-background m-3">
                      {props.userValue.data
                        ?
                        <>

                          <UserUpdateFutureEducation
                            userValue={props.userValue}
                            recordID={tempID}
                            add={false}
                          />

                        </>

                        : null}

                      <UserUpdateFutureEducation
                        userValue={props.userValue}
                        recordID={tempID}
                        add={true}
                      />

                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

            ) : null} */}
            <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"

              >
                <div className="d-flex justify-content-between w-100">
                  <div className="ms-5 bd-highlight fw-bold">Work Experience</div>
                  <div>
                    {props?.userValue?.data?.user?.workExperience.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">
                      <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                      <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                    </div>}

                  </div>

                </div>

              </AccordionSummary>
              {/* <div className="p-info-list p-3 pt-0">
                <div className="edu-background m-3">
                  <div className="title">Work Experience</div>
                  {props.userValue.data ?
                    <UserUpdateWorkExperience
                      userValue={props.userValue}
                      recordID={tempID}
                      add={false}
                    />
                    : null}

                </div>
                <UserUpdateWorkExperience
                  userValue={props.userValue}
                  recordID={tempID}
                  add={true} />
              </div> */}

              <WorkExperianceHandler data={props.userValue} />
              
            </Accordion>


            {props.userValue.data.user.userRole.name !== "Consultant" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <div className="d-flex justify-content-between w-100">
                    <div className="ms-5 bd-highlight fw-bold">Extracurricular </div>
                    <div>
                      {props?.userValue?.data?.user?.extraCarricularActivitys.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">
                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails >
                <ExtraCurricularHandler data={props.userValue} />

                </AccordionDetails>
              </Accordion>

            ) : null}

            {props.userValue.data.user.userRole.name !== "Consultant" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <div className="d-flex justify-content-between w-100">
                    <div className="ms-5 bd-highlight fw-bold">Recommendation</div>
                    <div>
                      {props?.userValue?.data?.user?.Recommendation.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">
                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails >
                  <RecommendationHandler data={props.userValue} />

                  {/* <div className="p-info-list p-0  m-3">
                    <div className="edu-background">
                      <Recommendation
                        userValue={props.userValue}
                        recordID={tempID}
                        add={false}
                      />
                    </div>
                    <Recommendation
                      userValue={props.userValue}
                      recordID={tempID}
                      add={true}
                    />
                  </div> */}
                </AccordionDetails>
              </Accordion>

            ) : null}


            {/* {props.userValue.data.user.userRole.name === "Student" ? (
              <Accordion className="mb-2" style={{ border: '1.5px solid #99bbda' }}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex justify-content-between w-100 mt-2 ">
                    <div className="ms-5 bd-highlight fw-bold">Study Abroad Exams</div>
                    <div>
                      {props?.userValue?.data?.user?.studyAbroadExamContent.length > 0 && <div className="d-flex justify-content-between w-100" id="vbagde">
                        <div className="p-1" style={{ color: '#1098fe' }}>Completed!</div>
                        <img className="align-self-center" src={check_img} width="30" height="30" style={{ align: 'center' }} />
                      </div>}

                    </div>

                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="p-info-list">
                    <div className="edu-background sty-abd">
                      <div className="title">Study Abroad Exams</div>

                      <div className="editicon t-right">
                        <div className="icons" role="presentation">
                          <img src={add_img} alt="" /> <span>Add</span>
                        </div>
                        {props.userValue.data.user.studyAbroadExamContent.length ? (
                          <div
                            className="icons"
                            role="presentation"
                          onClick={() =>
                            setEditStudyAbroadExam(!editStudyAbroadExam)
                          }
                          onKeyDown={() =>
                            setEditStudyAbroadExam(!editStudyAbroadExam)
                          }
                          >


                            <img src={edit_img} alt="" /> <span>Edit</span>


                          </div>
                        ) : (
                          ""
                        )}
                        {addStudyAbroadExam ? <UserUpdateStudyAbroadExamFormDialog userValue={props.userValue} add={true}/>:null}
                        {editStudyAbroadExam ? (
                          <UserUpdateStudyAbroadExam
                            userValue={props.userValue}
                            add={false}
                          />
                        ) : null}
                      </div>

                      <UserUpdateStudyAbroadExam
                        userValue={props.userValue}
                        add={false}
                      />

                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : null} */}
          </>
        ) : (
          ""
        )
        }
      </div >
    </div >
  )
}
export default Profile
