import React, { useState } from 'react';
import axios from 'axios';

import { Upload, message, Button, Spin } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';

import 'antd/lib/upload/style/index.css'
import 'antd/lib/message/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/spin/style/index.css'

import apiUrl from '../../api/apiUrl';


const { Dragger } = Upload;

function Identity({ idDoc, id }) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [passportIsLoading, setpassportIsLoading] = useState(false)
    const [passportBackIsloading, setpassportBackIsloading] = useState(false)
    const [cvIsloading, setcvIsloading] = useState(false)
    const [documents, setdocuments] = useState(idDoc)

    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"

    console.log('uudata', idDoc)

    const uploadFile = async (e, feild) => {
        var IDDocuments = { Passport: documents?.Passport, PassportBack: documents?.PassportBack, Cv: documents?.Cv }
        try {
            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            IDDocuments[feild] = uploadResp.data[0].id
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { IDDocuments: IDDocuments })
            updateUser?.data?.IDDocuments && setdocuments(updateUser?.data?.IDDocuments)
            setpassportIsLoading(false)
            setpassportBackIsloading(false)
            setcvIsloading(false)
        } catch (error) {
            console.log(error)
            setpassportIsLoading(false)
            setpassportBackIsloading(false)
            setcvIsloading(false)
        }
    }


    const deleteFile = async (feild) => {
        try {
            var IDDocuments = { Passport: documents?.Passport, PassportBack: documents?.PassportBack, Cv: documents?.Cv }
            IDDocuments[feild] = null

            var response = await axios.put(`${apiUrl.updateUserProfile}${id}`, { IDDocuments: IDDocuments })
            response?.data?.IDDocuments && setdocuments(response?.data?.IDDocuments)
            setpassportIsLoading(false)
            setpassportBackIsloading(false)
            setcvIsloading(false)
        } catch (error) {
            console.log(error)
            setpassportIsLoading(false)
            setpassportBackIsloading(false)
            setcvIsloading(false)

        }
    }




    return (
        <>

            <div className="row d-flex justify-content-between mt-3">
                <div className="col text-start">
                    <label>Passport <span className="text-danger">*</span> </label>

                </div>
                {documents?.Passport ?
                    <>
                        <div className="col text-center">
                            <Button href={`${apiUrl.host}${documents?.Passport?.url}`} target="_blank">View Document</Button>
                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('Passport'); setpassportIsLoading(true) }} />
                        </div>

                    </> :

                    <div className="col">
                        {!passportIsLoading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'Passport'); setpassportIsLoading(true) }}>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger> : <Spin indicator={antIcon} />}
                    </div>
                }
            </div>

            <div className="row mt-4">
                <div className="col d-flex">
                    <label>Passport Back <span className="text-danger">*</span></label>
                </div>
                {documents?.PassportBack ?
                    <>
                        <div className="col text-center">
                            <Button href={`${apiUrl.host}${documents?.PassportBack?.url}`} target="_blank">View Document</Button>
                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('PassportBack'); setpassportBackIsloading(true) }} />
                        </div>

                    </> :

                    <div className="col">
                        {!passportBackIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'PassportBack'); setpassportBackIsloading(true) }}>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger> : <Spin indicator={antIcon} />}
                    </div>

                }

            </div>

            <div className="row mt-4">
                <div className="col">
                    <label>CV <span className="text-danger">*</span></label>

                </div>
                {documents?.Cv ?
                    <>
                        <div className="col text-center">
                            <Button href={`${apiUrl.host}${documents?.Cv?.url}`} target="_blank">View Document</Button>
                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('Cv'); setcvIsloading(true) }} />
                        </div>
                    </> :
                    <div className="col">
                        {!cvIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'Cv'); setcvIsloading(true) }}>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger> : <Spin indicator={antIcon} />}
                    </div>
                }
            </div>



        </>
    )
}

export default React.memo(Identity);
