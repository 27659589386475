import React, { useState, Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useQuery, useMutation } from "@apollo/client"
import { USER_QUERY, USER_IMAGE_UPDATE } from "../components/graphql"
import Dashboard from "../components/dashboard"
import Journey from "../components/journey"
import Wishlist from "../components/wishlist"
import Recommendation from "../components/recommendation"
import MyAccount from "../components/my-account"
import Profile from "../components/profile"
import dashboard_icon from "../assets/images/dashboard.png"
import default_pic from "../assets/images/default.png"
import { navigate } from "gatsby";
import OrderHistory from "../components/orderHistory"
import profile_icon from "../assets/images/profile.png"
import active_profile_icon from "../assets/images/activeprofile-icon.svg"
import journey_icon from "../assets/images/journey.png"
import myactivity_icon from "../assets/images/myactivity.png"
import wishlist_icon from "../assets/images/wishlist.png"
import recommandation_icon from "../assets/images/recommandation.png"
import myaccount_icon from "../assets/images/myaccount.png"
import document_icon from "../assets/images/documents.svg"
import document_icon_new from "../assets/images/documents2.svg"


// import default_profile_img from "../assets/images/default-profile.png"
import ImageUploading from "react-images-uploading"
import $ from "jquery"
import axios from "axios";
import ProfileComplete from "../components/dialog/profileCompleteDialog"

// import { ApolloProvider,ApolloClient } from '@apollo/client';

// import { createUploadLink } from "apollo-upload-client";
// import { InMemoryCache } from "apollo-cache-inmemory";
// const cache = new InMemoryCache();

// const client = new ApolloClient({
//   // cache,
//   link: createUploadLink({
//     uri: "http://15.207.72.69:1337/graphql"
//   })
// });


//importing documents

import Document from '../components/document'
import EditProfile from "../components/editProfile"

import ProfilePicture from "profile-picture"
import "../assets/css/ProfilePicture.css"
import { useLocation } from "@reach/router";

function UserProfile() {
  const location = useLocation()
  // const { from } = location.state

  const [dashboard, setDashboard] = useState(location.state?.orderHistory?false:true)
  const [profile, setProfile] = useState(false)
  const [editProfile, seteditProfile] = useState(false);
  const [journey, setJourney] = useState(false)
  const [activity, setActivity] = useState(false)
  const [wishlist, setWishlist] = useState(false)
  const [recommandation, setRecommandation] = useState(false)
  const [account, setAccount] = useState(false)
  const [orderHistory,setOrderHistory] = useState(location.state?.orderHistory?true:false)
  const [showMenu, setShowMenu] = useState(false)
  const [userId, setUserID] = useState(undefined)
  const [images, setImages] = React.useState([])
  const [document, setdocument] = useState(false);
  const [uploadUserImage] = useMutation(USER_IMAGE_UPDATE)
  const [myActivityUserName, setMyActivityUserName] = useState("")

  React.useEffect(() => {
    console.log("useEffect called")
    setUserID(localStorage.getItem("userId"))
  }, []);


  console.log("userId----------", userId)
  if (!userId) {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      if (userId !== undefined) {
        window.location.replace("/")
      }
    }
  }

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex)
    setImages(imageList)
    setShowMenu(true)
  }

  const userData = useQuery(USER_QUERY, {
    variables: { id: userId },
    pollInterval: 7000,
  });
  
  console.log("////////////", userData)
  var userEmail = ""
  if (userData) {
    console.log("innnn111111")
    if (userData.data) {
      console.log("innnn2222222222")
      if (userData.data.user) {
        console.log("innnn333333333333333")
        if (userData.data.user.email) {
          console.log("innnn4444444444")
          userEmail = userData.data.user.email
          axios({
            method: 'get',
            url: `${process.env.NODEBB_URL}/api/user/email/${userEmail}`,
            headers: {
              "_uid": "1",
              "Authorization": `Bearer ${process.env.NOBEBB_TOKEN}`
            }
          })
            .then(function (response) {
              console.log("11111111111111111111111111111####", response);
              if (response) {
                if (response.data) {
                  console.log("innnnnnnnnnnnnnnnnnnnn", response.data)
                  if (response.data.userslug) {
                    setMyActivityUserName(response.data.userslug)
                  }
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            })
        }
      }
    }
  }
  var universityName = ""
  // if (userData.data) {
  //   if (userData.data.user.userRole) {
  //     if (userData.data.user.userRole.name === "Student") {
  //       if (userData.data.user.educationBackground.length > 0) {
  //         universityName =
  //           userData.data.user.educationBackground[
  //             userData.data.user.educationBackground.length - 1
  //           ].universityOrInstitute
  //       }
  //     }
  //   }
  // }
  
  console.log("99999999999999999", userData.data)
  const profileImage = userData.data
    ? (userData.data.user ? userData.data.user.profileImageContent
      ? userData.data.user.profileImageContent
      : "" : "")
    : undefined
  console.log("profileImage", userData.data)

  const profilePictureRef = React.createRef()


  // const b64toBlob = (b64Data, contentType, sliceSize) => {
  //         contentType = contentType || '';
  //         sliceSize = sliceSize || 512;

  //         var byteCharacters = atob(b64Data);
  //         var byteArrays = [];

  //         for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //             var slice = byteCharacters.slice(offset, offset + sliceSize);

  //             var byteNumbers = new Array(slice.length);
  //             for (var i = 0; i < slice.length; i++) {
  //                 byteNumbers[i] = slice.charCodeAt(i);
  //             }

  //             var byteArray = new Uint8Array(byteNumbers);

  //             byteArrays.push(byteArray);
  //         }

  //     console.log("/////////////////////////////",byteArrays)
  //     var blob = new Blob(byteArrays, {type: contentType});
  //     console.log("blobbbbbbbbbbbbbbbbb",blob)
  //     return blob;
  // }
  // const dataURLtoFile = (dataurl, filename) =>{

  //     var arr = dataurl.split(','),
  //         mime = arr[0].match(/:(.*?);/)[1],
  //         bstr = atob(arr[1]),
  //         n = bstr.length,
  //         u8arr = new Uint8Array(n);

  //     while(n--){
  //         u8arr[n] = bstr.charCodeAt(n);
  //     }

  //     console.log("---------==========",u8arr)
  //     return new File([u8arr], filename, {type:mime});
  // }
  // const urltoFile = (url, filename, mimeType)=>{
  //     return (fetch(url)
  //         .then(function(res){return res.arrayBuffer();})
  //         .then(function(buf){return new File([buf], filename,{type:mimeType});})
  //     );
  // }

  // function blobToFile(theBlob, fileName) {
  //   return new File([theBlob], fileName, {
  //     lastModified: new Date().getTime(),
  //     type: theBlob.type,
  //   })
  // }

  // const base64ToBlob = (dataurl) => {
  //   const arr = dataurl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1]
  //   const sliceSize = 1024;
  //   const byteChars = window.atob(arr[1]);
  //   const byteArrays = [];

  //   for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
  //     let slice = byteChars.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);

  //     byteArrays.push(byteArray);
  //   }

  //   return new Blob(byteArrays, {type: mime});
  // }

  // const getFilename = (dataUrl) => {
  //   const arr = dataUrl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];

  //   return Math.round(+new Date()/1000) + '.' + mime.split('/').pop();
  // }

  // const dataURLtoFile = (dataurl, filename) => {
  //   const arr = dataurl.split(',')
  //   const mime = arr[0].match(/:(.*?);/)[1]
  //   const bstr = atob(arr[1])
  //   let n = bstr.length
  //   const u8arr = new Uint8Array(n)
  //   while (n) {
  //     u8arr[n - 1] = bstr.charCodeAt(n - 1)
  //     n -= 1 // to make eslint happy
  //   }
  //   console.log("++++fileeeeeeeeeeeeeeeeeeeee",[u8arr],"..............",filename,"======================",new File([u8arr], filename, { type: mime }))
  //   return new File([u8arr], filename, { type: mime })
  // }

  const handleUpload = async () => {
    if (images.length !== 0) {
      console.log("filee1111111111111", images[0])
      // helper function: generate a new file from base64 String
      const dataUrl = images[0].data_url;
      console.log("fileeeeeeeee222222222222", dataUrl)
      // const file = URL.createObjectURL(images[0].file)  //dataURLtoFile(dataUrl,'data.png')
      // console.log("fileeeeeeeeeeeeeee333333333",file)
      // console.log("file", file)
      // console.log("fileeeeeeeeeeeeeeee44444444444444")
      // const formData = new FormData();
      // formData.append('files', file);
      //         console.log("formData", formData)

      //   fetch(dataUrl)
      // .then(response => response.blob())
      // .then(function (myBlob) {
      //   console.log("fileeeeeeeeeeeeeee8888888888888",myBlob)
      //   // const formData = new FormData();
      //   // formData.append('files', myBlob);
      //   // console.log(formData)
      // });
      try {
        console.log("image calling........................................")
        uploadUserImage({
          variables: {
            userId: userId,
            profileImageContent: dataUrl,
          },
        }).then(response => {
          console.log("reponse", response.data)
          if (response.data) {
            if (response.data.updateUser) {
              if (response.data.updateUser.user) {
                if (response.data.updateUser.user.profileImageContent) {
                  console.log("hideeeeeee")
                  setShowMenu(false);
                  // setImages(dataUrl)
                }
              }
            }
          }
        })
      } catch (e) {
        console.log("image error", e.message)
      }
      // fetch('http://localhost:1337/upload', {
      //   method: 'POST',
      //   headers: {
      //     "Authorization": "Bearer ", // <- Don't forget Authorization header if you are using it.
      //   },
      //   body: formData,
      // }).then((response) => {
      //   const result = response.json()
      //   console.log("result", result)
      // }).catch(function (err) {
      //   console.log("error:");
      //   console.log(err)
      // });
      // })
      // fetch(dataUrl)
      // .then(res => res.blob())
      // .then(console.log)
      // const blob = base64ToBlob(dataUrl);
      // blob.name = getFilename(dataUrl);

      // generate file from base64 string
      // return blob;

      // console.log("generated file", blob)
      // const fs = require("fs").promises

      // module.exports = async imgPath => {
      //   // we want the file type without the "." like: "jpg" or "png"
      //   const ext = path.extname(imgPath).slice(1)
      //   // name of the file like image01.jpg
      //   const name = path.basename(imgPath)
      //   // read contents of file into a Buffer
      //   const buffer = await fs.readFile(imgPath)
      //   // get the buffersize using service function from upload plugin
      //   const fileData = await strapi.plugins.upload.services.upload.enhanceFile(
      //     {
      //       path: imgPath,
      //       name,
      //       type: `image/${ext}`,
      //       size: buffer.toString().length,
      //     }
      //   )
      //   return strapi.plugins.upload.services.upload.uploadFileAndPersist(
      //     fileData
      //   )
      // }

    }
  }

  const onImageLoading = (e) => {
    setShowMenu(true);
  }
  // const mousemove = () =>{
  //   setShowMenu(true)
  // }
  // const onImageLoaded = (e) =>{
  //   $('._34iiqkc-jTKb3N7DvLLkwC').show();
  // }

  const handleUpload2 = () => {
    const PP = profilePictureRef.current
    const imageAsDataURL = PP.getImageAsDataUrl()
    // const realData = imageAsDataURL.replace("data:image/png;base64,", "")
    // console.log(typeof realData, "////////////////////", realData)
    // const realData = imageAsDataURL.split(';base64,').pop();
    console.log(imageAsDataURL, "realllllllllllllllllllllll")
    try {
      console.log("image calling........................................")
      uploadUserImage({
        variables: {
          userId: userId,
          profileImageContent: imageAsDataURL,
        },
      }).then(response => {
        console.log("reponse", response.data)
        if (response.data) {
          if (response.data.updateUser) {
            if (response.data.updateUser.user) {
              if (response.data.updateUser.user.profileImageContent) {
                console.log("hideeeeeee")
                setShowMenu(false);
                // $('._1MKAF96uXJHSW-a1nWoriQ').hide();
                // setImages(dataUrl)
              }
            }
          }
        }
      })
    } catch (e) {
      console.log("image error", e.message)
    }
    //   fs.writeFile('image.png', realData, {encoding: 'base64'}, function(err) {
    //     console.log('File created');
    // });
    // const byteCharacters = atob(realData)
    // const byteNumbers = new Array(byteCharacters.length)
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i)
    // }
    // const byteArray = new Uint8Array(byteNumbers)

    // let image = new Blob([byteArray], { type: "image/jpeg" })


    // try {
    //   uploadImage({
    //     variables: {
    //       file: fileUP,
    //     },
    //   }).then(response => {
    //     console.log("reponse", response.data)
    //   })
    // } catch (e) {
    //   console.log("image error", e.message)
    // }

    // var bs = atob(realData);
    //     var buffer = new ArrayBuffer(bs.length);
    //     var ba = new Uint8Array(buffer);
    //     for (var i = 0; i < bs.length; i++) {
    //         ba[i] = bs.charCodeAt(i);
    //     }
    //     var file = new Blob([ba], { type: "image/png" });
    //     console.log("file---------", file)
    //     file.lastModifiedDate = new Date();
    //     file.name = "my_image.png";
    // console.log("fileData", file)
    // var bs = atob(realData);
    // var buffer = new ArrayBuffer(bs.length);
    // var ba = new Uint8Array(buffer);
    // for (var i = 0; i < bs.length; i++) {
    //     ba[i] = bs.charCodeAt(i);
    // }
    // console.log("=============",[ba])
    // var file = new Blob([ba], { type: "image/png" });
    // console.log("///////////////>>>>>>>>>>>>",file)
    // urltoFile(realData, 'hello.png','image/png')
    // .then(function(file){
    //     console.log("ffffffffffffffffffffffff",file);
    //         // try {
    //         //     uploadImage({
    //         //         variables: {
    //         //         file: file,
    //         //         },
    //         //     }).then(response => {
    //         //         console.log("reponse", response.data)
    //         //     })
    //         // }
    //         // catch (e) {
    //         //     console.log("image error", e.message)
    //         // }
    // });
    // const res =  fetch(imageAsDataURL);
    // const blob =  res.blob();
    // const file = new File([blob], "image.png", { type: 'image/png' });
    // console.log("--------------",file)
    // fetch(imageAsDataURL)
    //     .then(res => res.blob())
    //     .then(blob => {
    //         const file = new File([blob], "image.png",{ type: "image/png" })
    //         console.log("++++++++++++++++++++++++++++++++",file)
    //         try {
    //             uploadImage({
    //               variables: {
    //                 file: file,
    //               },
    //             }).then(response => {
    //               console.log("reponse", response.data)
    //             })
    //         }
    //         catch (e) {
    //             console.log("image error", e.message)
    //         }
    //     })
    // const file = dataURLtoFile(imageAsDataURL,'image.png');
    // console.log("=====================",file,URL.createObjectURL(file))
    // const block = imageAsDataURL.split(";");
    // const contentType = block[0].split(":")[1];
    // const realData = block[1].split(",")[1];
    // const formData = new FormData();
    // // const data=(imageAsDataURL).split(',')[1];
    // // const binaryBlob = atob(data);
    // // console.log("111111111111111111111",binaryBlob)
    // // formData.append("myFile", binaryBlob, { filename: 'myFile.png' });

    // // formData.append("image_data", imageAsDataURL);
    // const blob = b64toBlob(realData, contentType);

    // console.log("HHHHHHHHHHHHHHHHHHHHH",blob)
    // formData.append("image", blob);
    // console.log("finnnnnnnnnnnnnn",formData)
  }
  // const onImageChange = event => {
  //     console.log(event.target.files,"fileeeeeeeeeeeeeeeeeeee777777777777")
  // };
  if (userData?.data?.user?.id ) {
    if(!userData?.data?.user?.email || !userData?.data?.user?.username || !userData?.data?.user?.mobileNumber)
    {
      return (
        <ProfileComplete user={userData} />
      )
    }
    else{
      if(!userId){
        if(localStorage.getItem('lastRevistedPage')){
          if(localStorage.getItem('lastRevistedPage')==='/house/'){
            if(localStorage.getItem('lastRevistedPageID')){
              navigate(localStorage.getItem('lastRevistedPage')+localStorage.getItem('lastRevistedPageID'))
              localStorage.removeItem('lastRevistedPage')
              localStorage.removeItem('lastRevistedPageID')
            }
            else{
              navigate(localStorage.getItem('lastRevistedPage'))
              localStorage.removeItem('lastRevistedPage') 
            }
          }
          else{
            navigate(localStorage.getItem('lastRevistedPage'))
            localStorage.removeItem('lastRevistedPage')
          }
        }
        else{
          if(localStorage.getItem("userId") && localStorage.getItem('lastPage')){
            if(localStorage.getItem('lastPage') ==='blog-index'){
              localStorage.removeItem('lastPage');
              navigate("/blog/")
            }
            else if(localStorage.getItem('lastPage') ==='blog-detail'){
              if(localStorage.getItem('lastPageUrl')!='null'){
                navigate(`/blog/${localStorage.getItem('lastPageUrl')}`)
              }
              else{
                navigate("/blog/")
              }
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageUrl');
            }
            else if(localStorage.getItem('lastPage') ==='tools'){
              if(sessionStorage.getItem('userRedirectFlag')){
                navigate('/tools/results')
                sessionStorage.setItem('activeStep',6);
              }
              else{
                navigate("/user-profile/")
              }
              localStorage.removeItem('lastPage');
            }
            else if(localStorage.getItem('lastPage')==='university'){
              navigate("/universities/")
              localStorage.removeItem('lastPage');
            }
            else if(localStorage.getItem('lastPage')==='university-detail'){
              if(localStorage.getItem('lastPageUrl')!='null'){
                navigate(`/universities/${localStorage.getItem('lastPageUrl')}`)
              }
              else{
                navigate("/universities/")
              }
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageUrl');
            }
            else if(localStorage.getItem('lastPage') === 'housing-page'){
              if(localStorage.getItem('lastPageId')!='null'){
                navigate(`/house/?id=${localStorage.getItem('lastPageId')}`)
              }
              else{
                navigate("/house/")
              }
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageId');
            }
            else{
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageUrl');
              navigate("/user-profile/")
            }
          }
          else{
            navigate('/user-profile/')
          }
        }
      }
    }
  }

  return (
    <>
      {console.log("image.data_url --------", images)}
      {userId ? (
        <Layout setTitle="User Profile">
          <SEO title="User Profile" />
          {/* <!-- main --> */}
          {userData.data ?
            userData.data.user ? (
              <main className="user">
                <div className="main-content">
                  <div className="container">
                    <div className="existing-user">
                      <div className="user-content">
                        <ul className="nav nav-pills">
                          <div
                            className={"user-image " + (showMenu ? 'show-slider' : 'hide-slider')}
                            style={{ marginBottom: "20px" }}
                          >
                            {/*<img src={profileImage ? profileImage : ""} alt="" />*/}
                            {/*<ImageUploading
                              single
                              value={images}
                              onChange={onChange}
                              dataURLKey="data_url"
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                  {images.length === 0 ? (
                                    <img
                                      src={profileImage ? profileImage : ""}
                                      alt=""
                                      onClick={onImageUpload}
                                      {...dragProps}
                                    />
                                  ) : (
                                    <>
                                      {imageList.map((image, index) => (
                                        <div key={index} className="image-item">
                                          <img
                                            src={image.data_url}
                                            alt="a"
                                            onClick={onImageUpload}
                                            {...dragProps}
                                          />
                                          {showMenu?
                                          <div className="image-item__btn-wrapper custom-btn">
                                            <button
                                              onClick={() => onImageUpdate(index)}
                                            >
                                              Edit
                                            </button>
                                            <button
                                              onClick={() => handleUpload()}
                                            >
                                              Save
                                            </button>
                                          </div> :""}
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              )}
                            </ImageUploading> */}
                            <Fragment>
                              <ProfilePicture
                                ref={profilePictureRef}
                                image={profileImage ? profileImage : ''}
                                onImageLoading={onImageLoading}
                                useHelper={false}
                                minImageSize={50}
                                debug={false}
                                message={{
                                  DEFAULT: "select.",
                                  DRAGOVER: "Drop your photo",
                                  INVALID_FILE_TYPE: "Only images allowed.",
                                  INVALID_IMAGE_SIZE: "Your photo must be larger than 350px."
                                }}
                              />
                              {showMenu ?
                                <button onClick={() => handleUpload2()}>Save</button>
                                : ""}
                            </Fragment>
                          </div>
                          <div className="user-detail">
                            <p>
                              {userData.data.user.firstName}{" "}

                            </p>
                            <h5>
                              {userData.data.user.userRole
                                ? userData.data.user.userRole.name
                                : ""}
                            </h5>
                            {userData.data.user.userRole ? (
                              userData.data.user.userRole.name === "Student" ? (
                                <>
                                  {universityName ?
                                    <h6>
                                      at{" "}
                                      {universityName
                                        ? universityName
                                        : ""}
                                    </h6>
                                    : null}
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link  ${dashboard ? "active" : ""}`}
                              onClick={() => {
                                setDashboard(true)
                                setJourney(false)
                                setProfile(false)
                                setActivity(false)
                                setWishlist(false)
                                setRecommandation(false)
                                setAccount(false)
                                setdocument(false)
                                seteditProfile(false)
                                setOrderHistory(false)
                              }}
                              id="pills-dashboard-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#dashboard"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <img src={dashboard_icon} alt="" />
                              My Dashboard
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link  ${profile ? "active" : ""}`}
                              onClick={() => {
                                setDashboard(false)
                                setJourney(false)
                                setProfile(true)
                                setActivity(false)
                                setWishlist(false)
                                setRecommandation(false)
                                setAccount(false)
                                setdocument(false)
                                seteditProfile(false)
                                setOrderHistory(false)
                              }}
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="true"
                            >
                              <img className="icon" src={profile_icon} alt="" />
                              <img
                                className="active-icon"
                                src={active_profile_icon}
                                alt=""
                              />
                              Application Profile
                            </button>
                          </li>
{/* 
                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link  ${editProfile ? "active" : ""}`}
                              onClick={() => {
                                setDashboard(false)
                                setJourney(false)
                                setProfile(false)
                                setActivity(false)
                                setWishlist(false)
                                setRecommandation(false)
                                setAccount(false)
                                setdocument(false)
                                seteditProfile(true)
                              }}
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="true"
                            >
                              <img className="icon" src={profile_icon} alt="" />
                              <img
                                className="active-icon"
                                src={active_profile_icon}
                                alt=""
                              />
                              Edit Profile
                            </button>
                          </li> */}

                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link  ${document ? "active" : ""}`}
                              onClick={() => {
                                setDashboard(false)
                                setJourney(false)
                                setProfile(false)
                                setActivity(false)
                                setWishlist(false)
                                setRecommandation(false)
                                setAccount(false)
                                setdocument(true)
                                seteditProfile(false)
                                setOrderHistory(false)
                              }}
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="true"
                            >
                              <img className="icon" src={document_icon} alt="" />
                              <img
                                className="active-icon"
                                src={document_icon_new}
                                alt=""
                              />
                              Documents
                            </button>
                          </li>



                          {userData.data.user.userRole ? (
                            userData.data.user.userRole.name === "Student" ? (
                              <li className="nav-item" role="presentation">
                                <button
                                  className={`nav-link  ${journey ? "active" : ""
                                    }`}
                                  onClick={() => {
                                    setDashboard(false)
                                    setJourney(true)
                                    setProfile(false)
                                    setActivity(false)
                                    setWishlist(false)
                                    setRecommandation(false)
                                    setAccount(false)
                                    setdocument(false)
                                    seteditProfile(false)
                                    setOrderHistory(false)
                                  }}
                                  id="pills-journey-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#journey"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-journey"
                                  aria-selected="true"
                                >
                                  <img src={journey_icon} alt="" />
                                  Journey
                                </button>
                              </li>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                           <li className="nav-item" role="presentation">
                            <button className={`nav-link  ${activity ? "active" : ""}`}
                              onClick={(e) => {
                                setDashboard(false)
                                setJourney(false)
                                setProfile(false)
                                setActivity(false)
                                setWishlist(false)
                                setRecommandation(false)
                                setAccount(false)
                                setdocument(false)
                                seteditProfile(false)
                                setOrderHistory(true)
                              }}
                              id="pills-activity-tab" data-bs-toggle="pill"
                              data-bs-target="#order-history" type="button" role="tab" aria-controls="pills-order-history"
                              aria-selected="true">
                              <img src={myactivity_icon} alt="" />
                              Order History
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className={`nav-link  ${activity ? "active" : ""}`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (myActivityUserName.length > 0) {
                                  window.location.href = `${process.env.NODEBB_URL}/user/${myActivityUserName}/my-activity`
                                }
                                else {
                                  window.location.href = `${process.env.NODEBB_URL}`;
                                }
                              }}
                              id="pills-activity-tab" data-bs-toggle="pill"
                              data-bs-target="#activity" type="button" role="tab" aria-controls="pills-activity"
                              aria-selected="true">
                              <img src={myactivity_icon} alt="" />
                              My Activity
                            </button>
                          </li>
                          {userData.data.user.userRole ? (
                            userData.data.user.userRole.name === "Student" ? (
                              <>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className={`nav-link  ${wishlist ? "active" : ""
                                      }`}
                                    onClick={() => {
                                      setDashboard(false)
                                      setJourney(false)
                                      setProfile(false)
                                      setActivity(false)
                                      setWishlist(true)
                                      setRecommandation(false)
                                      setAccount(false)
                                      setdocument(false)
                                      seteditProfile(false)
                                      setOrderHistory(false)
                                    }}
                                    id="pills-wishlist-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#wishlist"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-wishlist"
                                    aria-selected="true"
                                  >
                                    <img src={wishlist_icon} alt="" />
                                    Wishlist
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className={`nav-link  ${recommandation ? "active" : ""
                                      }`}
                                    onClick={() => {
                                      setDashboard(false)
                                      setJourney(false)
                                      setProfile(false)
                                      setActivity(false)
                                      setWishlist(false)
                                      setRecommandation(true)
                                      setAccount(false)
                                      setdocument(false)
                                      seteditProfile(false)
                                      setOrderHistory(false)
                                    }}
                                    id="pills-recommendation-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#recommendation"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-recommendation"
                                    aria-selected="true"
                                  >
                                    <img src={recommandation_icon} alt="" />
                                    Recommendation
                                  </button>
                                </li>
                              </>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {/* <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link  ${account ? "active" : ""}`}
                              onClick={() => {
                                setDashboard(false)
                                setJourney(false)
                                setProfile(false)
                                setActivity(false)
                                setWishlist(false)
                                setRecommandation(false)
                                setAccount(true)
                                setdocument(false)
                                seteditProfile(false)


                              }}
                              id="pills-account-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#account"
                              type="button"
                              role="tab"
                              aria-controls="pills-account"
                              aria-selected="true"
                            >
                              <img src={myaccount_icon} alt="" />
                              My Account
                            </button>
                          </li> */}

                        </ul>

                        <div className="tab-content" id="pills-tabContent">
                          {dashboard ? <Dashboard userValue={userData} /> : null}
                          {profile ? <Profile userValue={userData} /> : null}
                          {journey ? <Journey userValue={userData} /> : null}
                          {document ? <Document id={userData?.data?.user?.id} /> : null}
                          {editProfile ? <EditProfile userValue={userData?.data?.user} id={userData?.data?.user?.id} /> : null}

                          {orderHistory ? <OrderHistory /> : null}
                          {activity ? (
                            <div
                              id="activity"
                              role="tabpanel"
                              aria-labelledby="pills-activity-tab"
                            ></div>
                          ) : null}
                          {wishlist ? <Wishlist userValue={userData} /> : null}
                          {recommandation ? (
                            <Recommendation userValue={userData} />
                          ) : null}
                          {account ? <MyAccount userValue={userData} /> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /container ---> */}
                </div>
              </main>
            ) :

              (
                "") : (
              ""
            )}
          {/* / main */}
        </Layout>
      ) : null}
    </>
  )
}
export default UserProfile
