import React, { useState } from "react"
import {
 TextField,
  Dialog, DialogActions, MenuItem,
  DialogContent, DialogTitle
} from "@material-ui/core"

import {Button} from 'antd'
import { CheckOutlined } from '@ant-design/icons';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useQuery } from "@apollo/client"
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useMutation } from "@apollo/client"
import { UPDATE_USER_EDUCATION_BACKGROUND } from "../graphql"
import axios from "axios"
import apiUrl from "../../api/apiUrl"



export default function TestScore(props) {

  // console.log('testscore',props.userValue.data.user?.testscore)
  const [open, setOpen] = useState(true)
  const userID = props.userValue.data.user.id

  const [plusTwoMark, setplusTwoMark] = useState(props?.userValue?.data?.user?.testscore?.plusTwoMark || '')
  const [gre, setgre] = useState(props?.userValue?.data?.user?.testscore?.GRE || '')
  const [ielts, setielts] = useState(props?.userValue?.data?.user?.testscore?.IELTS || '')
  const [toefl, settoefl] = useState(props?.userValue?.data?.user?.testscore?.TOEFL || '')
  const [defl, setdefl] = useState(props?.userValue?.data?.user?.testscore?.DEFL || '')
  const [otherTest, setotherTest] = useState(props?.userValue?.data?.user?.testscore?.otherTestScore || '')
  const [gmat, setgmat] = useState(props?.userValue?.data?.user?.testscore?.GMAT || '')
  const [otherTestName, setotherTestName] = useState(props?.userValue?.data?.user?.testscore?.otherTestName || '')

  const [isLoading, setisLoading] = useState('')
  const [notification, setnotification] = useState({})


  const handleSubmit = async () => {
    const requestHeader = {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
      }
    }

    let formPayload = {
      testscore: {
        plusTwoMark:plusTwoMark,
        GRE:gre,
        GMAT:gmat,
        TOEFL:toefl,
        IELTS:ielts,
        DEFL:defl,
        otherTestName:otherTestName,
        otherTestScore:otherTest,

      }
    }

    try {
      setisLoading(true)
      axios.post(apiUrl.addEducation, formPayload, requestHeader)
        .then(response => {
          setnotification({error: false, message: 'Data saved successfully'})
          setisLoading(false)
          // console.log("reponse", response.data)
        })
        .finally(() => {
          setOpen(false)
        })
    } catch (e) {
      setisLoading(false)
      setnotification({error: true, message: 'Failed to save data , Try again later '})
      // console.log("Adding user education background error", e.message)
    }

  }

  return (
    <div>

      <>
      <div className="row w-100">
          <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
        </div>
        <div className="fw-bold">Edit Test Scores</div>
        <div className="row w-100">
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="address"
              label="Class 12"
              type="text"
              value={plusTwoMark}
              onChange={e => {
                setplusTwoMark(e.target.value)
              }}

            >
            </TextField>
          </div>
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="address"
              label="GMAT"
              type="text"
              value={gmat}
              onChange={e => {
                setgmat(e.target.value)
              }}

            >
            </TextField>
          </div>
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="address"
              label="GRE"
              type="text"
              value={gre}
              onChange={e => {
                setgre(e.target.value)
              }}

            >
            </TextField>
          </div>
        </div>
        <div className="row w-100">
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="ielts"
              label="IELTS"
              type="text"
              value={ielts}
              onChange={e => {
                setielts(e.target.value)
              }}

            >
            </TextField>
          </div>
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="toefl"
              label="TOEFL"
              type="text"
              value={toefl}
              onChange={e => {
                settoefl(e.target.value)
              }}

            >
            </TextField>
          </div>
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="delf"
              label="DELF"
              type="text"
              value={defl}
              onChange={e => {
                setdefl(e.target.value)
              }}

            >
            </TextField>
          </div>
        </div>
        <div className="row w-100">
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="address"
              label="Other Tests"
              type="text"
              placeholder="Enter Test Name"
              value={otherTestName}
              onChange={e => {
                setotherTestName(e.target.value)
              }}

            >
            </TextField>
          </div>
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="address"
              label="Score"
              type="text"
              placeholder="Enter Test Score"
              value={otherTest}
              onChange={e => {
                setotherTest(e.target.value)
              }}

            >
            </TextField>
          </div>

        </div>
        {/* <div className="fw-bold">Add Education Background</div> */}
        {/* <div className="row w-100">
          <div className="col">
            <TextField
              className="w-100"
              margin="dense"
              id="address"
              label="Marks in English in Class 12"
              type="text"
              value={plusTwoMark}
              onChange={e => {
                setplusTwoMark(e.target.value)
              }}
              
            >
            </TextField>
          </div>
        </div>

        <div className="mt-2">
          <FormLabel id="demo-row-radio-buttons-group-label"> Have you taken any English Proficiency Test?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={englishProficiencyTest}
            onChange={(e)=>setenglishProficiencyTest(e.target.value)}
          >
            <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
            <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
          </RadioGroup>
        </div>

        <div className="mt-2">
          <FormLabel id="demo-row-radio-buttons-group-label"> Have you taken GRE/GMAT?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={gmat}
            onChange={(e)=>setgmat(e.target.value)}
          >
            <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
            <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
          </RadioGroup>
        </div>


        <div className="mt-2">
          <FormLabel id="demo-row-radio-buttons-group-label"> Have you taken SAT/ACT?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={sat}
          >
            <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
            <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
          </RadioGroup>
        </div>
 */}



        <div className="mt-3 pb-2 pl-1 pr-1">
          {/* <Button className="save-btn ms-0" onClick={handleSubmit} color="primary">
            Save
          </Button> */}
          <div className="cta-primary">
          <Button type="primary" size="large" onClick={handleSubmit} loading={isLoading} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
            Save
          </Button>
          </div>
        </div>


      </>


    </div>
  )
}