import React, { useState } from "react"
import {
  TextField,
  Dialog, DialogActions, MenuItem,
  DialogContent, DialogTitle
} from "@material-ui/core"
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useMutation } from "@apollo/client"
import { UPDATE_USER_EDUCATION_BACKGROUND } from "../graphql"
import axios from "axios"
import apiUrl from "../../api/apiUrl"
// import { YearPicker } from 'react-dropdown-date';
// import DatePicker from '@material-ui/lab';
// import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { EXTRA_CARRICULAR_ACTIVITY } from "../graphql"

import { useQuery } from "@apollo/client"

export default function Recommendation(props) {

  const [open, setOpen] = useState(true)
  const userID = props.userValue.data.user.id

  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState({})

  const [refrenceType, setrefrenceType] = useState('Professional')
  const [nameOfOrganization, setnameOfOrganization] = useState('')
  const [recommenderName, setrecommenderName] = useState('')
  const [officialEmail, setofficialEmail] = useState('')
  const [relationWithRecommander, setrelationWithRecommander] = useState('')
  const [recommenderDesignation, setrecommenderDesignation] = useState('')
  const [contactNumber, setcontactNumber] = useState('')
  const [addressOfOrganization, setaddressOfOrganization] = useState('')
  const [isRecommendationLatter, setisRecommendationLatter] = useState(true)

  const [recommenderNameError, setrecommenderNameError] = useState('')
  const [organizationError, setorganizationError] = useState('')
  const [emailIdError, setemailIdError] = useState('')


  const [activityStatus, setactivityStatus] = useState('Ongoing')
  const [activity, setactivity] = useState('')
  const [position, setposition] = useState('')
  const [description, setdescription] = useState('')
  const [startDate, setstartDate] = useState('')
  const [endOn, setendOn] = useState('')
  const [isIntrest, setisIntrest] = useState('')


  const list = []
  const list1 = []


  props.userValue.data.user.Recommendation.forEach((record) => {
    let dict = {}
    dict.id = record?.id
    dict.ReferenceType = record?.ReferenceType
    dict.RecommenderName = record?.RecommenderName
    dict.RecommenderDesignation = record?.RecommenderDesignation
    dict.AddressofOrganisation = record?.AddressofOrganisation
    dict.nameOfOrganization = record?.nameOfOrganization
    dict.officialEmail = record?.officialEmail
    dict.RelationWithRecommender = record?.RelationWithRecommender
    dict.ContactNumber = record?.ContactNumber
    dict.RecommendationLatter = record?.RecommendationLatter

    list.push(dict)
  })




  // const [recommenadtionLIst, setrecommenadtionLIst] = useState(list)
  const [recommenadtionLIst, setrecommenadtionLIst] = useState(list)

  // const [extraCarricularListError, setextraCarricularListError] = useState(list1)
  const [recommendationListError, setrecommendationListError] = useState(list1)


  const handleClose = () => {
    setOpen(false)
  }

  const handleAdd = (index, element) => e => {
    let newArr = [...recommenadtionLIst];
    // if(element !== "passingOutYear"){
    newArr[index][element] = e.target.value;
    // }
    // else{
    //   newArr[index][element] = (e.getFullYear()).toString()

    // }
    setrecommenadtionLIst(newArr);
  }

  const handleSubmitButton = () => {
    if (props.add) {
      if (!recommenderName) {
        setrecommenderNameError("Enter valid name")
      }
      if (!nameOfOrganization) {
        setorganizationError("Enter a valid Organization name")
      }
      if (!officialEmail) {
        setemailIdError('Official Email id Required')
      }

      if (recommenderName && nameOfOrganization && officialEmail) {
        setrecommenderNameError("")
        setorganizationError("")
        setemailIdError("")
        handleSubmit()
      }
    }
    else {
      let listArr = []
      let flag = 0
      recommenadtionLIst.forEach((record) => {
        let dict = {}
        if (!record.RecommenderName) {
          dict.recommenderNameError = "Recommender Name is Required"
          flag = 1
        }
        else if (!record.nameOfOrganization) {
          dict.organizationError = "Enter a valid organization name"
          flag = 1
        }
        else if (!record.officialEmail) {
          dict.emailIdError = "Enter a valid email"
          flag = 1
        }
        else {
          dict.RecommenderName = ""
          dict.nameOfOrganization = ""
          dict.officialEmail = ""

        }
        listArr.push(dict)
      })
      setrecommendationListError(listArr)
      if (flag === 0) {
        handleSubmit()
      }
    }
  }





  const handleSubmit = async () => {
    setIsLoading(true)
    if (props.add) {
      if (recommenderName || nameOfOrganization || officialEmail) {
        let dict1 = {}

        dict1.ReferenceType = refrenceType || ''
        dict1.RecommenderName = recommenderName
        dict1.RecommenderDesignation = recommenderDesignation || ''
        dict1.AddressofOrganisation = addressOfOrganization || ''
        dict1.nameOfOrganization = nameOfOrganization
        dict1.officialEmail = officialEmail
        dict1.RelationWithRecommender = relationWithRecommander
        dict1.ContactNumber = contactNumber
        dict1.RecommendationLatter = isRecommendationLatter

        const requestHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        try {
          await axios.post(apiUrl.addEducation, { Recommendation: recommenadtionLIst.concat([dict1]) }, requestHeader)
            .then(response => {
              setIsLoading(false)
              setNotification({error:false,message:'Recommendation created successfully'})
            })
            .finally(() => {
              setOpen(false)
            })
        } catch (e) {
          setIsLoading(false)
          setNotification({error:true,message:'Failed to create'})

        }
      }
      else {
        setOpen(false)
      }
    }
    else {
      try {

        const requestHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        axios.post(apiUrl.addEducation, { Recommendation: recommenadtionLIst }, requestHeader)
          .then(response => {
            setIsLoading(false)
            setNotification({error:false,message:'Recommendation updated successfully'})
          })
          .finally(() => {
            setactivityStatus("")
            setactivity("")
            setposition("")
            setdescription("")
            setstartDate("")
            setendOn("")
            setisIntrest("")
            
          })
      } catch (e) {
        setIsLoading(false)
        setNotification({error:true,message:'Failed to update'})
        console.log("update user education background error", e.message)
      }

    }
  }

  return (
    <div>
      {props.add ?

        <>
          <div className="row w-100 px-3">
            <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
          </div>

          <div className="fw-bold">Add Recommendation</div>
          <div className="row w-100">
            <div className="col mt-3">
              <FormLabel id="demo-row-radio-buttons-group-label" >Reference Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={refrenceType}
                onChange={(e) => setrefrenceType(e.target.value)}>
                <FormControlLabel value="Professional" control={<Radio size="small" />} label="Professional" />
                <FormControlLabel value="Academic" control={<Radio size="small" />} label="Academic" />
              </RadioGroup>
            </div>

            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="activity"
                label="Name of Organisation/Institution"
                type="text"
                value={nameOfOrganization}
                onChange={e => {
                  setnameOfOrganization(e.target.value)
                }}
                helperText={organizationError || ""}
                error={organizationError}
              >

              </TextField>

              {' '}
            </div>


          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                required
                className="w-100"
                margin="dense"
                id="position-name"
                label="Recommender Name"
                type="text"
                value={recommenderName}
                onChange={e => {
                  setrecommenderName(e.target.value)
                }}
                helperText={recommenderNameError || ""}
                error={recommenderNameError}
              />
              {' '}
            </div>
            <div className="col">
              <TextField
                required
                className="w-100"
                margin="dense"
                id="course-name"
                label="Official Email ID"
                type="email"
                value={officialEmail}
                onChange={e => {
                  setofficialEmail(e.target.value)
                }}
                helperText={emailIdError || ""}
                error={emailIdError}
              />
              {' '}
            </div>
          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="position-name"
                label="Relation with Recommender"
                type="text"
                value={relationWithRecommander}
                onChange={e => {
                  setrelationWithRecommander(e.target.value)
                }}
              />
              {' '}
            </div>
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="course-name"
                label="Recommender Designation"
                type="text"
                value={recommenderDesignation}
                onChange={e => {
                  setrecommenderDesignation(e.target.value)
                }}
              />
              {' '}
            </div>
          </div>

          <div className="row w-100">
            <div className="col">
              <TextField
                required
                className="w-100"
                margin="dense"
                id="position-name"
                label="Contact Number"
                type="number"
                value={contactNumber}
                onChange={e => {
                  setcontactNumber(e.target.value)
                }}

              />
              {' '}
            </div>
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="course-name"
                label="Address of Organisation/Institution"
                type="text"
                value={addressOfOrganization}
                onChange={e => {
                  setaddressOfOrganization(e.target.value)
                }}
              />
              {' '}
            </div>
          </div>


          {/* <div className="row w-100 mt-2">
            <div className="col">
              <FormLabel id="demo-row-radio-buttons-group-label" >Do you have letter of recommendation?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={isRecommendationLatter}
                onChange={(e) => setisRecommendationLatter(e.target.value)}
              >
                <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
              </RadioGroup>
              {' '}
            </div>
          </div> */}

          <div className="mt-3 d-flex justify-content-between pb-2 pl-1 pr-1">

            {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
              Save
            </Button> */}
            <div className="cta-primary">
            <Button type="primary"  onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
              Save
            </Button>
            </div>

            <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
              + Add new
            </Button>
          </div>

        </>

        :
        <>


          {props.userValue.data.user.Recommendation.length > 0 ?
            <>
              {props.userValue.data ? props.userValue.data.user.Recommendation.map(
                (content, index) => (

                  <div className="my-2">
                    <div className="row w-100 px-3">
                      <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
                    </div>
                    <div className="fw-bold">Edit Recommendation</div>


                    <div className="row w-100">

                      <div className="col mt-3">
                        <FormLabel id="demo-row-radio-buttons-group-label" >Reference Type</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={recommenadtionLIst[index]?.ReferenceType}
                          onChange={handleAdd(index,"ReferenceType")}>
                          <FormControlLabel value="Professional" control={<Radio size="small" />} label="Professional" />
                          <FormControlLabel value="Academic" control={<Radio size="small" />} label="Academic" />
                        </RadioGroup>
                      </div>

                      <div className="col">
                        <TextField
                          className="w-100"
                          margin="dense"
                          id="activity"
                          label="Name of Organisation/Institution"
                          type="text"
                          value={recommenadtionLIst[index]?.nameOfOrganization}
                          onChange={handleAdd(index, "nameOfOrganization")}
                          helperText={recommendationListError[index]?.nameOfOrganization || ""}
                          error={recommendationListError[index]?.nameOfOrganization}
                        >

                        </TextField>

                        {' '}
                      </div>


                    </div>
                    <div className="row w-100">

                      <div className="col">
                        <TextField
                          required
                          className="w-100"
                          margin="dense"
                          id="position-name"
                          label="Recommender Name"
                          type="text"
                          value={recommenadtionLIst[index]?.RecommenderName}
                          onChange={handleAdd(index, "RecommenderName")}
                          helperText={recommendationListError[index]?.recommenderNameError || ""}
                          error={recommendationListError[index]?.recommenderNameError}
                        />

                        {' '}
                      </div>
                      <div className="col">
                        <TextField
                          required
                          className="w-100"
                          margin="dense"
                          id="course-name"
                          label="Official Email ID"
                          type="email"
                          value={recommenadtionLIst[index]?.officialEmail}
                          onChange={handleAdd(index, "officialEmail")}
                          helperText={recommendationListError[index]?.emailIdError || ""}
                          error={recommendationListError[index]?.emailIdError}
                        />
                        {' '}
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col">


                        <TextField
                          className="w-100"
                          margin="dense"
                          id="position-name"
                          label="Relation with Recommender"
                          type="text"
                          value={recommenadtionLIst[index]?.RelationWithRecommender}
                          onChange={handleAdd(index, "RelationWithRecommender")}

                        />
                        {' '}
                      </div>
                      <div className="col">

                        <TextField
                          className="w-100"
                          margin="dense"
                          id="course-name"
                          label="Recommender Designation"
                          type="text"
                          value={recommenadtionLIst[index]?.RecommenderDesignation}
                          onChange={handleAdd(index, "RecommenderDesignation")}

                        />
                        {' '}
                      </div>
                    </div>

                    <div className="row w-100">
                      <div className="col">
                        <TextField
                          required
                          className="w-100"
                          margin="dense"
                          id="position-name"
                          label="Contact Number"
                          type="number"
                          value={recommenadtionLIst[index]?.ContactNumber}
                          onChange={handleAdd(index, "ContactNumber")}


                        />
                        {' '}
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100"
                          margin="dense"
                          id="course-name"
                          label="Address of Organisation/Institution"
                          type="text"
                          value={recommenadtionLIst[index]?.AddressofOrganisation}
                          onChange={handleAdd(index, "AddressofOrganisation")}

                        />
                        {' '}
                      </div>
                    </div>

                    {/* <div className="row w-100 mt-2">
                      <div className="col">
                        <FormLabel id="demo-row-radio-buttons-group-label" >Do you have letter of recommendation?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={recommenadtionLIst[index]?.RecommendationLatter}
                          onChange={handleAdd(index, "RecommendationLatter")}

                        >
                          <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                        </RadioGroup>

                        {' '}
                      </div>
                    </div> */}
                  </div>
                )) : null}
            </>
            : null}
          {props.userValue.data.user.Recommendation.length > 0 && <div className="my-3 d-flex justify-content-between pb-2 pl-1 pr-1">
            {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
              Save
            </Button> */}
            <div className="cta-primary">
              <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
                Save
              </Button>
            </div>
            <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
              + Add new
            </Button>
          </div>}

        </>
      }
    </div>
  )
}