import React, { useState } from "react"
import {
    TextField, Dialog,
    Checkbox, MenuItem,
    DialogActions,
    DialogContent, DialogTitle
} from "@material-ui/core"
import { useQuery, useMutation } from "@apollo/client"
import {
    COUNTRY_QUERY,
    UPDATE_USER_WORK_EXPERIENCE
} from "../graphql"
import Autocomplete from "@material-ui/lab/Autocomplete"
// import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import DateFnsUtils from "@date-io/date-fns";

import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons';


import axios from "axios"
import apiUrl from "../../api/apiUrl"

export default function UserUpdateWorkExperience(props) {
    const [showAdd, setshowAdd] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [notification, setnotification] = useState({})


    const [workStatus, setworkStatus] = useState('')
    const [workType, setWorkType] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [designation, setdesignation] = useState('')
    const [jobRole, setjobRole] = useState('')
    const [startFrom, setstartFrom] = useState('')
    const [endOn, setendOn] = useState('')

    const [workStatusError, setworkStatusError] = useState('')
    const [designationError, setdesignationError] = useState('')
    const [jobRoleError, setjobRoleError] = useState('')
    const [startFromError, setstartFromError] = useState('')

    const [locationName, setCityName] = useState("")
    const [country, setCountry] = useState("")
    const [open, setOpen] = useState(true)
    const userID = props.userValue.data.user.id
    const countryQuery = useQuery(COUNTRY_QUERY)
    const list = []
    const list1 = []
    const list2 = []

    const startYear = 1950
    const endyear = new Date().getFullYear()
    const yearList = []
    for (var i = startYear; i <= endyear; i++) {
        yearList.push(i.toString());
    }
    const [updateUser] = useMutation(UPDATE_USER_WORK_EXPERIENCE)

    const countryProps = {
        options: countryQuery.data ? countryQuery.data.countries : [],
        getOptionLabel: option => option.countryName,
    }



    const [jobTitleError, setJobTitleError] = useState("")
    const [companyNameError, setCompanyNameError] = useState("")
    const [workTypeError, setWorkTypeError] = useState("")
    const [fromMonthError, setFromMonthError] = useState("")
    const [fromYearError, setFromYearError] = useState("")
    const [toMonthError, setToMonthError] = useState("")
    const [toYearError, setToYearError] = useState("")
    const [locationNameError, setLocationNameError] = useState("")
    const [countryError, setCountryError] = useState("")



    props.userValue.data.user.workExperience.forEach((record, i) => {
        let dict = {}
        let dict1 = {}
        dict.workType = record.workType
        dict.workStatus = record.workStatus
        dict.companyName = record.companyName
        dict.designation = record.designation
        dict.jobRole = record.jobRole
        dict.startFrom = record.startFrom
        dict.endOn = record.endOn
        dict.locationName = record.locationName
        dict1.country = record?.country?.id
        dict1.country = record?.country?.id
        dict1.countryName = record?.country?.countryName

        list.push(dict)
        list1.push(dict1)
    })
    const [educationList, setEducationList] = useState(list)
    const [countryList, setCountryList] = useState(list1)

    console.log("////-----------------------------///////////", countryList)
    educationList.forEach((record) => {
        let dict = {}
        dict.workTypeError = ""
        dict.workStatusError = ""
        dict.companyNameError = ""
        dict.designationError = ""
        dict.jobRoleError = ""
        dict.startFromError = ""
        dict.locationNameError = ""
        dict.countryError = ""
        list2.push(dict)
    })
    const [educationListError, setEducationListError] = useState(list2)

    const handleClose = () => {
        setOpen(false)
    }

    const handleAdd = (index, element) => e => {
        let newArr = [...educationList];
        let newArr1 = [...countryList];
        if (element !== "country" && element !== "isPresent") {
            newArr[index][element] = e.target.value;
        }
        else if (element === "isPresent") {
            newArr[index][element] = e.target.checked;
        }
        else {
            console.log("------------------", e.target.value, e)
            newArr1[index][element] = e.target.value;
        }
        setEducationList(newArr);
        setCountryList(newArr1);
    }


    const handleSubmitButton = () => {
        if (props.add) {

            setworkStatusError("")
            setCompanyNameError("")
            setWorkTypeError("")
            setstartFromError("")
            setFromYearError("")
            setLocationNameError("")
            setCountryError("")
            setdesignationError('')


            if (!workStatus) {
                setworkStatusError("This field is required")
                console.log('workStatus')

                return
            }
            if (!companyName) {
                setCompanyNameError("This field is required")
                console.log('cn')
                return
            }
            if (!workType) {
                setWorkTypeError("This field is required")
                console.log('wt')
                return
            }
            if (!startFrom) {
                startFromError("This field is required")
                console.log('sf')
                return
            }
            if (!startFrom) {
                setFromYearError("This field is required")
                console.log('ef')
                return
            }
            if (!locationName) {
                setLocationNameError("This field is required")
                console.log('na')
                return
            }
            if (!country) {
                setCountryError("This field is required")
                console.log('c')
                return
            }
            if (!designation) {
                setdesignationError('This field is required')
                console.log('design')
                return
            }

            console.log('all feilgs')

            handleSubmit()

        }

        else {
            let listArr = []
            let flag = 0
            educationList.forEach((record) => {
                let dict = {}

                if (!record.workStatus) {
                    dict.workStatusError = "This Field is Required"
                    console.log('workStatus')
                    flag = 1
                }

                if (!record.startFrom) {
                    dict.startFromError = "This Field is Required"
                    console.log('sf')
                    flag = 1
                }

                if (!record.designation) {
                    dict.designationError = "This Field is Required"
                    console.log('design')
                    flag = 1

                }

                else if (!record.companyName) {
                    dict.companyNameError = "This Field is Required"
                    flag = 1
                }
                else if (!record.workType) {
                    dict.workTypeError = "This Field is Required"
                    flag = 1
                }

                else if (!record.locationName) {
                    dict.locationNameError = "This Field is Required"
                    flag = 1
                }
                else {
                    dict.workTypeError = ""
                    dict.workStatusError = ""
                    dict.companyNameError = ""
                    dict.designationError = ""
                    dict.jobRoleError = ""
                    dict.startFromError = ""
                    dict.locationNameError = ""
                    dict.countryError = ""
                }
                listArr.push(dict)
            })
            setEducationListError(listArr)
            if (flag === 0) {
                handleSubmit()
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        if (props.add) {
            let updatedList = [];
            educationList.forEach(function (value, i) {
                let dict = {}
                dict.id = value.id
                dict.workType = value.workType
                dict.workStatus = value.workStatus
                dict.companyName = value.companyName
                dict.designation = value.designation
                dict.jobRole = value.jobRole
                dict.startFrom = value.startFrom
                dict.endOn = value.endOn
                dict.locationName = value.locationName
                dict.country = countryList[i].country
                updatedList.push(dict)
            });
            let dict1 = {}

            dict1.workStatus = workStatus
            dict1.designation = designation
            dict1.jobRole = jobRole
            dict1.startFrom = startFrom
            dict1.endOn = endOn
            dict1.companyName = companyName
            dict1.workType = workType
            dict1.locationName = locationName
            dict1.country = country
            try {

                const requestHeader = {
                    headers: {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                    }
                }

                axios.post(apiUrl.addEducation, { workExperience: updatedList.concat([dict1]) }, requestHeader)
                    .then(response => {
                        setIsLoading(false);
                        setnotification({ error: false, message: 'Work Experience added successfully' });
                        console.log("reponse", response.data)
                    })
                    .finally(() => {
                        setOpen(false)
                    })


                // updateUser({

                //     variables: {
                //         id: userID,
                //         eContent: updatedList.concat([dict1])
                //     },
                // })
                //     .then(response => {
                //         console.log("reponse", response.data);

                //     })
                //     .finally(() => {
                //         setOpen(false)
                //     })
            }
            catch (e) {
                setIsLoading(false)
                setnotification({ error: true, message: 'Failed to create workexperiance' })
                console.log("update user work experience error", e.message)
            }
        }
        else {
            let updatedList = [];
            educationList.forEach(function (value, i) {
                let dict = {}
                dict.id = value.id
                dict.workType = value.workType
                dict.workStatus = value.workStatus
                dict.companyName = value.companyName
                dict.designation = value.designation
                dict.jobRole = value.jobRole
                dict.startFrom = value.startFrom
                dict.endOn = value.endOn
                dict.locationName = value.locationName
                dict.country = countryList[i].country
                updatedList.push(dict)
                if (i === (educationList.length - 1)) {
                    setTimeout(function () {
                        try {

                            const requestHeader = {
                                headers: {
                                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                                }
                            }

                            axios.post(apiUrl.addEducation, { workExperience: updatedList }, requestHeader)
                                .then(response => {
                                    setIsLoading(false)
                                    setnotification({ error: false, message: 'workExperience updated successfully' })
                                    console.log("reponse", response.data)
                                })
                                .finally(() => {
                                    setOpen(false)
                                })


                            // updateUser({
                            //     variables: {
                            //         id: userID,
                            //         eContent: updatedList
                            //     },
                            // })
                            //     .then(response => {
                            //         console.log("reponse", response.data)
                            //     })
                            //     .finally(() => {
                            //         setOpen(false)
                            //     })
                        }
                        catch (e) {
                            console.log("update user work experience error", e.message)
                            setIsLoading(false)
                            setnotification({ error: true, message: 'Failed to update workexperiance' })
                        }
                    }, 500);
                }
            });
        }
    }

    var onTagsChange = (event, values) => {
        if (values) {
            setCountry(values.id)
        } else {
            setCountry("")
        }
    }

    var onTagsChange1 = (event, index, values) => {
        let newArr1 = [...countryList];
        console.log("----qqqqqqqqqqqqqq-------,", values, index)
        if (values) {
            newArr1[index].country = values.id;
        }
        setCountryList(newArr1);
    }

    return (
        <div>
            {!props.add && <>
                {props.userValue.data.user.workExperience.length > 0 ?
                    <>
                        {props.userValue.data ? props.userValue.data.user.workExperience.map(
                            (content, index) => (
                                <>
                                    <div className="row w-100">
                                        <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <div className="fw-bold">Edit Work Experience</div>
                                        <div className="row w-100">
                                            <div className="col">
                                                <TextField
                                                    select
                                                    className="w-100"
                                                    margin="dense"
                                                    id="job-title"
                                                    label="Work Status"
                                                    type="text"
                                                    value={educationList[index].workStatus}
                                                    fullWidth
                                                    onChange={handleAdd(index, "workStatus")}
                                                    helperText={educationListError[index].workStatusError ? educationListError[index].workStatusError : ""}
                                                    error={educationListError[index].workStatusError}
                                                >
                                                    <MenuItem value="Ongoing">
                                                        {"Ongoing"}
                                                    </MenuItem>
                                                    <MenuItem value="Completed">
                                                        {"Completed"}
                                                    </MenuItem>
                                                </TextField>
                                            </div>
                                            <div className="col">
                                                <TextField
                                                    className="w-100"
                                                    margin="dense"
                                                    select
                                                    id="from-month"
                                                    label="Work Type"
                                                    type="text"
                                                    value={educationList[index].workType}
                                                    onChange={handleAdd(index, "workType")}
                                                    helperText={educationListError[index].workTypeError ? educationListError[index].workTypeError : ""}
                                                    error={educationListError[index].workTypeError}
                                                >
                                                    <MenuItem value="Fulltime">
                                                        {"Fulltime"}
                                                    </MenuItem>
                                                    <MenuItem value="PartTime">
                                                        {"PartTime"}
                                                    </MenuItem>
                                                    <MenuItem value="Internship">
                                                        {"Internship"}
                                                    </MenuItem>

                                                </TextField>
                                            </div>
                                        </div>

                                        <div className="row w-100">

                                            <div className="col">
                                                <TextField
                                                    className="w-100"
                                                    margin="dense"

                                                    id="from-month"
                                                    label="Name of the Organization"
                                                    type="text"
                                                    value={educationList[index].companyName}
                                                    onChange={handleAdd(index, "companyName")}
                                                    helperText={educationListError[index].companyNameError ? educationListError[index].companyNameError : ""}
                                                    error={educationListError[index].companyNameError}
                                                >


                                                </TextField>
                                            </div>
                                            <div className="col">
                                                <TextField
                                                    className="w-100"
                                                    margin="dense"
                                                    id="from-year"
                                                    label="Designation"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    type="text"
                                                    value={educationList[index].designation}
                                                    onChange={handleAdd(index, "designation")}
                                                    helperText={educationListError[index].designationError ? educationListError[index].designationError : ""}
                                                    error={educationListError[index].designationError}
                                                >
                                                </TextField>
                                            </div>
                                        </div>

                                        {!(educationList[index].isPresent) ?
                                            <>
                                                <div className="row w-100">
                                                    <div className="col">
                                                        <TextField
                                                            className="w-100"
                                                            margin="dense"
                                                            id="to-month"
                                                            label="Job Role"
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            type="text"
                                                            value={educationList[index].jobRole}
                                                            onChange={handleAdd(index, "jobRole")}
                                                            helperText={educationListError[index].jobRoleError ? educationListError[index].jobRoleError : ""}
                                                            error={educationListError[index].jobRoleError}
                                                        >

                                                        </TextField>
                                                    </div>
                                                    <div className="col">
                                                        <TextField
                                                            className="w-100"
                                                            margin="dense"
                                                            id="to-year"
                                                            label="Start from"
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            type="date"
                                                            value={educationList[index].startFrom}
                                                            onChange={handleAdd(index, "startFrom")}
                                                            helperText={educationListError[index].startFromError ? educationListError[index].startFromError : ""}
                                                            error={educationListError[index].startFromError}
                                                        >

                                                        </TextField>
                                                    </div>
                                                    <div className="col">
                                                        <TextField
                                                            className="w-100"
                                                            margin="dense"
                                                            id="to-year"
                                                            label="End On"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            value={educationList[index].endOn}
                                                            onChange={handleAdd(index, "endOn")}

                                                        >

                                                        </TextField>
                                                    </div>
                                                </div>

                                            </>
                                            : null}

                                        <div className="row w-100">
                                            <div className="col">
                                                <TextField
                                                    className="w-100"
                                                    margin="dense"
                                                    id="city-name"
                                                    label="City/Town"
                                                    type="text"
                                                    value={educationList[index].locationName}
                                                    onChange={handleAdd(index, "locationName")}
                                                    helperText={educationListError[index].locationNameError ? educationListError[index].locationNameError : ""}
                                                    error={educationListError[index].locationNameError}
                                                />
                                            </div>

                                            <div className="col">
                                                <Autocomplete
                                                    className="w-100"
                                                    {...countryProps}
                                                    id="debug"
                                                    debug
                                                    onChange={(event, values) => { onTagsChange1(event, index, values) }}
                                                    defaultValue={{ countryName: countryList[index].countryName }}
                                                    renderInput={params => (
                                                        <TextField
                                                            className="w-100"
                                                            {...params}
                                                            label="Country"
                                                            margin="normal"
                                                            error={countryError}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )) : null}
                    </>
                    : null}
                {props.userValue.data.user.workExperience.length > 0 &&
                    <div className="mt-3 d-flex justify-content-between p-1">
                        {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
                            Save
                        </Button> */}
                        <div className="cta-primary">
                            <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
                                Save
                            </Button>
                        </div>
                        <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
                            + Add new
                        </Button>
                    </div>}
            </>}


            {props.add &&
                <>
                    <div className="row w-100">
                        <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <div className="fw-bold">Add Work Experience</div>
                        <div className="row w-100">
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    select
                                    id="work-status"
                                    label="Work Status"
                                    type="text"
                                    value={workStatus}
                                    onChange={e => {
                                        setworkStatus(e.target.value)
                                    }}
                                    helperText={workStatusError ? workStatusError : ""}
                                    error={workStatusError}
                                >
                                    <MenuItem value="Ongoing">
                                        {"Ongoing"}
                                    </MenuItem>
                                    <MenuItem value="Completed">
                                        {"Completed"}
                                    </MenuItem>


                                </TextField>
                            </div>
                            <div className="col">

                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    select
                                    id="work-type"
                                    label="Work Type"
                                    type="text"
                                    value={workType}
                                    onChange={e => {
                                        setWorkType(e.target.value)
                                    }}
                                    helperText={workTypeError ? workTypeError : ""}
                                    error={workTypeError}
                                >

                                    <MenuItem value="Fulltime">
                                        {"Fulltime"}
                                    </MenuItem>
                                    <MenuItem value="PartTime">
                                        {"PartTime"}
                                    </MenuItem>
                                    <MenuItem value="Internship">
                                        {"Internship"}
                                    </MenuItem>

                                </TextField>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    id="company-name"
                                    label="Name of the Organization"
                                    type="text"
                                    value={companyName}
                                    onChange={e => {
                                        setCompanyName(e.target.value)
                                    }}
                                    helperText={companyNameError ? companyNameError : ""}
                                    error={companyNameError}
                                />
                                {' '}
                            </div>
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    id="job-title"
                                    label="Designation"
                                    type="text"
                                    fullWidth
                                    value={designation}
                                    onChange={e => {
                                        setdesignation(e.target.value)
                                    }}
                                    helperText={designationError ? designationError : ""}
                                    error={designationError}
                                />
                            </div>

                        </div>
                        <div className="row w-100">
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    id="job-title"
                                    label="Job Role"
                                    type="text"
                                    fullWidth
                                    value={jobRole}
                                    onChange={e => {
                                        setjobRole(e.target.value)
                                    }}
                                    helperText={jobRoleError ? jobRoleError : ""}
                                    error={jobRoleError}
                                />
                            </div>
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    id="start-from"
                                    label="Started From"
                                    type="date"
                                    value={startFrom}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => setstartFrom(e.target.value)}
                                    helperText={startFromError ? startFromError : ""}
                                    error={startFromError}
                                />
                                {' '}
                            </div>
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    id="end-on"
                                    label="Ended On"
                                    type="date"
                                    value={endOn}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => setendOn(e.target.value)}
                                />
                                {' '}
                            </div>
                        </div>

                        <div className="row w-100">
                            <div className="col">
                                <TextField
                                    className="w-100"
                                    margin="dense"
                                    id="location-name"
                                    label="City/Town"
                                    type="text"
                                    value={locationName}
                                    onChange={e => {
                                        setCityName(e.target.value)
                                    }}
                                    helperText={locationNameError ? locationNameError : ""}
                                    error={locationNameError}
                                />
                                {' '}
                            </div>
                            <div className="col">
                                <Autocomplete
                                    className="w-100"
                                    {...countryProps}
                                    id="debug"
                                    debug
                                    onChange={onTagsChange}
                                    defaultValue={{ countryName: "" }}
                                    renderInput={params => (
                                        <TextField
                                            className="w-100"
                                            {...params}
                                            label="Country"
                                            margin="normal"
                                            error={countryError}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>



                    <div className="mt-3 d-flex justify-content-between pb-2 pl-1 pr-1">
                        {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
                            Save
                        </Button> */}
                        <div className="cta-primary">
                        <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
                            Save
                        </Button>
                        </div>
                        <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
                            + Add new
                        </Button>
                    </div>


                </>}



        </div>
    )
}