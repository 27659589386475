import React, { useState } from "react"
import {
  TextField,
  Dialog, DialogActions, MenuItem,
  DialogContent, DialogTitle
} from "@material-ui/core"

import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons';

// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useMutation, useQuery } from "@apollo/client"
import { UPDATE_USER_EDUCATION_BACKGROUND, HIGHEST_LEVEL_OF_EDUCATION, EDUCATION_COURSE, EDUCATION_SPECIALIZATION, ALL_EDUCATION_COURSE, COUNTRY_QUERY } from "../graphql"
import axios from "axios"
import apiUrl from "../../api/apiUrl"
import Autocomplete from "@material-ui/lab/Autocomplete"



import { Button as Btn } from "antd"
import { DeleteFilled } from '@ant-design/icons';


export default function UserUpdateEducationFormDialog(props) {
  console.log("=========111111111111111111==========", props)
  const [open, setOpen] = useState(true)
  const userID = props.userValue.data.user.id
  const countryQuery = useQuery(COUNTRY_QUERY)
  const [highestLevelOfEducation, sethighestLevelOfEducation] = useState('')
  const [educationStatus, seteducationStatus] = useState('Completed')
  const [specialization, setspecialization] = useState('')
  const [certificate, setcertificate] = useState('')
  const [universityName, setuniversityName] = useState('')
  const [universityCity, setuniversityCity] = useState('')
  const [universityState, setuniversityState] = useState('')
  const [universityCountry, setuniversityCountry] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [notification, setnotification] = useState({})

  const [highestLevelOfEducationError, sethighestLevelOfEducationError] = useState('')
  const [educationStatusError, seteducationStatusError] = useState('')
  const [specilazionError, setspecilazionError] = useState('')
  const [certificateError, setcertificateError] = useState('')


  const highestLevelOfEducationLIst = useQuery(HIGHEST_LEVEL_OF_EDUCATION)
  const courseList = useQuery(EDUCATION_COURSE, { variables: { id: highestLevelOfEducation } })

  const allCourseList = useQuery(ALL_EDUCATION_COURSE)

  const educationSpecializationList = useQuery(EDUCATION_SPECIALIZATION)
  const defaultCountryData = props.userValue.data.user.countryOfBirth
    ? props.userValue.data.user.countryOfBirth
    : ""
  

  const [courseName, setCourseName] = useState("")
  const [boardName, setBoardName] = useState("")
  const [universityOrInstitute, setUniversityOrInstitute] = useState("")
  const [locationName, setLocationName] = useState("")
  const [passingOutYear, setPassingOutYear] = useState(null)
  const [gradingSystem, setGradingSystem] = useState("CGPA")
  const [markValue, setMarkValue] = useState("")

  const [updateUser] = useMutation(UPDATE_USER_EDUCATION_BACKGROUND)
  const list = []
  const list1 = []
  const list2 = []

  const [courseNameError, setCourseNameError] = useState("")
  const [boardNameError, setBoardNameError] = useState("")
  const [universityOrInstituteError, setUniversityOrInstituteError] = useState("")
  const [locationNameError, setLocationNameError] = useState("")
  const [passingOutYearError, setPassingOutYearError] = useState("")
  const [gradingSystemError, setGradingSystemError] = useState("")
  const [markValueError, setMarkValueError] = useState("")

  const [defaultCountry, setDefaultCountry] = useState(
    defaultCountryData.countryName
  )

  console.log("///////////////////////", props.userValue.data.user.educationBackground)

  props.userValue.data.user.educationBackground.forEach((record) => {
    console.log('record', record)
    let dict = {}
    let dict1 = {}
    dict.id = record.id
    dict.educationStatus = record.educationStatus
    dict.highest_level_of_education = record.highest_level_of_education.id
    dict.specialization = record.specialization
    dict.courseName = record.courseName
    dict.universityOrInstitute = record.instituteName
    dict.gradingSystem = record?.gradingSystem
    dict.markValue = record.markValue
    dict.universityName = record.universityName
    dict.universityCity = record.universityCity
    dict.universityState = record.universityState
    dict.universityCountry = record.universityCountry?.id
    dict1.country = record?.universityCountry?.id
    dict1.countryName = record?.universityCountry?.countryName
    list.push(dict)
    list1.push(dict1)
  })

  // const startYear = 1950
  // const endyear = new Date().getFullYear()

  // const yearList = []

  // for (var i = startYear; i >= endyear; i--) {
  //   yearList.push(i.toString());
  // }

  const startYear = new Date().getFullYear()
  const endyear = 1950

  const yearList = []


  for (var i = startYear; i >= endyear; i--) {
    yearList.push(i.toString());
  }



  const [educationList, setEducationList] = useState(list)
  const [countryList, setCountryList] = useState(list1)

  console.log(educationList, "==education list")


  educationList.forEach((record) => {
    let dict = {}
    dict.courseNameError = ""
    dict.boardNameError = ""
    dict.universityOrInstituteError = ""
    dict.locationNameError = ""
    dict.passingOutYearError = ""
    dict.gradingSystemError = ""
    dict.markValueError = ""
    list1.push(dict)
    list2.push(dict)
  })
  const [educationListError, setEducationListError] = useState(list1)

  const handleClose = () => {
    setOpen(false)
  }

  const countryProps = {
    options: countryQuery.data ? countryQuery.data.countries : [],
    getOptionLabel: option => option.countryName,
  }




  const handleAdd = (index, element) => (e, v) => {
    console.log("==a==",e.target.value)
    console.log("==a==",element)


    let newArr = [...educationList];
    console.log("==a==",newArr)
    if (element == "universityCountry") {
      newArr[index][element] = v.id;
    }
    else {
      newArr[index][element] = e.target.value

    }
    console.log("==a==",newArr)
    setEducationList(newArr);
  }

  var onTagsChange = (event, values) => {
    if (values) {
      setuniversityCountry(values.id)
    } else {
      setuniversityCountry("")
    }
  }

  const handleSubmitButton = () => {
    if (props.add) {
      sethighestLevelOfEducationError("")
      seteducationStatusError('')
      setspecilazionError('')
      setcertificateError('')


      if (!highestLevelOfEducation) {
        sethighestLevelOfEducationError('This Field is required')
        return
      }

      if (!educationStatus) {
        seteducationStatusError('This field is required')
        return
      }

      if (!specialization) {
        setspecilazionError('This field is required')
        return
      }

      if (!certificate) {
        setcertificateError('This field is required')
        return
      }
      handleSubmit()
    }
    else {
      let listArr = []
      let flag = 0
      educationList.forEach((record) => {
        let dict = {}

        listArr.push(dict)
      })
      // setEducationListError(listArr)
      // console.log("##############################", educationListError)
      if (flag === 0) {
        handleSubmit()
      }
    }
  }

  const deleteEducation = (index) => {
    console.log(index)
    console.log(educationList)
    var tempEdu = [...educationList]
    tempEdu.splice(index, 1)
    console.log(tempEdu)
    setEducationList([])

  }

  const handleSubmit = async () => {
    setIsLoading(true)
    if (props.add) {
      if (highestLevelOfEducation || educationStatus || specialization) {
        let dict1 = {}
        dict1.educationStatus = educationStatus
        dict1.highest_level_of_education = highestLevelOfEducation
        dict1.specialization = specialization
        dict1.courseName = certificate
        dict1.instituteName = universityOrInstitute
        dict1.gradingSystem = gradingSystem
        dict1.markValue = markValue
        dict1.universityName = universityName
        dict1.universityCity = universityCity
        dict1.universityState = universityState
        dict1.universityCountry = universityCountry


        //console.log('edu', educationList.concat([dict1]))

        const requestHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        try {
          await axios.post(apiUrl.addEducation, { educationBackground: educationList.concat([dict1]) }, requestHeader)
            .then(response => {
              setnotification({ error: false, message: 'Education updated successfully' })
              setIsLoading(false)
              console.log("reponse", response.data)
            })
            .finally(() => {
              setOpen(false)
            })
        } catch (e) {
          setIsLoading(false)
          setnotification({ error: true, message: 'Failed to save Education data ,try again later' })
          console.log("Adding user education background error", e.message)
        }
      }
      else {
        setOpen(false)
      }
    }
    else {
      console.log("11111111111111111", educationList)
      try {

        const requestHeader = {
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
          }
        }

        axios.post(apiUrl.addEducation, { educationBackground: educationList }, requestHeader)
          .then(response => {
            setIsLoading(false)
            setnotification({ error: false, message: 'Education updated successfully' })
            console.log("reponse", response.data)
          })
          .finally(() => {
            setOpen(false)
            setCourseName("")
            setBoardName("")
            setUniversityOrInstitute("")
            setLocationName("")
            setPassingOutYear("")
            setGradingSystem("")
            setMarkValue("")
          })
      } catch (e) {
        setIsLoading(false)
        setnotification({ error: true, message: 'Failed to save Education data ,try again later' })
        console.log("update user education background error", e.message)
      }

    }
  }
  console.log("updateedddddddddddddddddddddddddddddd", educationList)
  return (
    <div>
      {props.add ?

        <>
          <div className="row w-100">
            <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
          </div>
          <div className="fw-bold">Add Education Background</div>
          <div className="row w-100">
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="highest-level"
                select
                label="Highest Level of Education"
                type="text"
                value={highestLevelOfEducation}
                onChange={e => {
                  sethighestLevelOfEducation(e.target.value)
                }}
                helperText={highestLevelOfEducationError ? highestLevelOfEducationError : ""}
                error={highestLevelOfEducationError}
              >
                {highestLevelOfEducationLIst ? highestLevelOfEducationLIst?.data?.highestLevelOfEducations.map((content, index) => (
                  <MenuItem value={content.id} key={index}>
                    {content.Education}
                  </MenuItem>
                )) : null}
              </TextField>
            </div>

            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="education-status"
                select
                label="Education Status"
                type="text"
                value={educationStatus}
                onChange={e => {
                  seteducationStatus(e.target.value)
                }}
                helperText={educationStatusError ? educationStatusError : ""}
                error={educationStatusError}
              >

                <MenuItem value="Pursuing" >
                  Pursuing
                </MenuItem>
                <MenuItem value="Completed" >
                  Completed
                </MenuItem>

              </TextField>
            </div>
          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="specialization"

                label="Specialization"
                type="text"
                value={specialization}
                onChange={e => {
                  setspecialization(e.target.value)
                }}
                helperText={specilazionError ? specilazionError : ""}
                error={specilazionError}
              >

              </TextField>
            </div>
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="degree"
                label="Degree/Diploma/Certificate"
                type="text"
                value={certificate}
                onChange={e => {
                  setcertificate(e.target.value)
                }}
                helperText={certificateError ? certificateError : ""}
                error={certificateError}
              >
                
              </TextField>
            </div>
          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                className="w-100"
                select
                margin="dense"
                id="grading-system"
                label="Grade Scheme (GPA/Percentage)"
                type="text"
                value={gradingSystem}
                onChange={e => {
                  setGradingSystem(e.target.value)
                }}
                helperText={gradingSystemError ? gradingSystemError : ""}
                error={gradingSystemError}
              >
                Percentage
                <MenuItem value={"Percentage"} key={"Percentage"}>
                  Percentage
                </MenuItem>
                <MenuItem value={"CGPA"} key={"CGPA"}>
                  CGPA
                </MenuItem>
                <MenuItem value={"GPA"} key={"GPA"}>
                  GPA
                </MenuItem>
                <MenuItem value={"Other"} key={"Other"}>
                  Other
                </MenuItem>
              </TextField>
            </div>
            <div className="col">


              <TextField
                className="w-100"
                margin="dense"
                id="marks-value"
                label="Grade Average/ Marks Obtained"
                type="text"
                value={markValue}
                onChange={e => {
                  setMarkValue(e.target.value)
                }}
                helperText={markValueError ? markValueError : ""}
                error={markValueError}
              />
              {' '}
            </div>

          </div>
          <div className="row w-100">

            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="university-name"
                label="Name of Institution"
                type="text"
                value={universityOrInstitute}
                onChange={e => {
                  setUniversityOrInstitute(e.target.value)
                }}
                helperText={universityOrInstituteError ? universityOrInstituteError : ""}
                error={universityOrInstituteError}
              />
            </div>
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="university-name"
                label="Affiliated University/Board of Education"
                type="text"
                value={universityName}
                onChange={e => {
                  setuniversityName(e.target.value)
                }}
                helperText={universityOrInstituteError ? universityOrInstituteError : ""}
                error={universityOrInstituteError}
              />
            </div>
          </div>
          <div className="row w-100">

            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="location-name"
                label="City"
                type="text"
                value={universityCity}
                onChange={e => {
                  setuniversityCity(e.target.value)
                }}
                helperText={locationNameError ? locationNameError : ""}
                error={locationNameError}
              />

              {' '}
            </div>
            <div className="col">
              <TextField
                className="w-100"
                margin="dense"
                id="state"
                label="State"
                type="text"
                value={universityState}
                onChange={e => {
                  setuniversityState(e.target.value)
                }}
                helperText={locationNameError ? locationNameError : ""}
                error={locationNameError}
              />

              {' '}
            </div>
            <div className="col">
              <Autocomplete
                className="w-100"
                {...countryProps}
                id="debug"
                debug
                onChange={(event, values) => setuniversityCountry(values.id)}
                defaultValue={{ countryName: defaultCountry }}
                renderInput={params => (
                  <TextField
                    className="w-100"
                    {...params}
                    label="Country"
                    margin="normal"

                  />
                )}
              />

              {/* <TextField
                className="w-100"
                margin="dense"
                id="country"
                label="Country"
                type="text"
                value={universityCountry}
                onChange={e => {
                  setuniversityCountry(e.target.value)
                }}
                helperText={locationNameError ? locationNameError : ""}
                error={locationNameError}
              /> */}

              {' '}
            </div>
          </div>


          <div className="mt-3 d-flex justify-content-between pb-2 pl-1 pr-1">
            {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
              Save
            </Button> */}
            <div className="cta-primary">
              <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
                Save
              </Button>
            </div>

            <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
              + Add new
            </Button>
          </div>

        </>

        :
        <>


          {props.userValue.data.user.educationBackground.length > 0 ?
            <>
              {props.userValue.data ? props.userValue.data.user.educationBackground.map(
                (content, index) => (
                  <>
                    {console.log('d', props.userValue.data.user.educationBackground)}

                    <div className="row w-100">
                      <span className={notification?.error ? "text-danger text-center" : "text-center text-success"}>{notification?.message}</span>
                    </div>
                    <div style={{ marginTop: "20px" }}>

                      <div className="d-flex justify-content-between">
                        <div className="fw-bold">Edit Education Background</div>
                        {/* <Btn danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={()=>deleteEducation(index)} /> */}
                      </div>
                      <div className="row w-100">
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="highest-level"
                            select
                            label="Highest Level of Education"
                            type="text"
                            value={educationList[index].highest_level_of_education}
                            onChange={handleAdd(index, 'highest_level_of_education')}

                          >
                            {highestLevelOfEducationLIst ? highestLevelOfEducationLIst?.data?.highestLevelOfEducations.map((content, index) => (
                              <MenuItem value={content.id} key={index}>
                                {content.Education}
                              </MenuItem>
                            )) : null}

                          </TextField>
                        </div>
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="education-status"
                            select
                            label="Education Status"
                            type="text"
                            value={educationList[index].educationStatus}
                            onChange={handleAdd(index, "educationStatus")}

                            helperText={passingOutYearError ? passingOutYearError : ""}
                            error={passingOutYearError}
                          >

                            <MenuItem value="Pursuing" >
                              Pursuing
                            </MenuItem>
                            <MenuItem value="Completed" >
                              Completed
                            </MenuItem>

                          </TextField>
                        </div>
                      </div>

                      {/* ------------------sample------------ */}
                      {/* 
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="location-name"
                            label="Location"
                            type="text"
                            value={educationList[index].locationName}
                            onChange={handleAdd(index, "locationName")}
                            helperText={educationListError[index].locationNameError ? educationListError[index].locationNameError : ""}
                            error={educationListError[index].locationNameError}
                          />
                          {' '}
                        </div> */}
                      <div className="row w-100">
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="specialization"

                            label="Specialization"
                            type="text"
                            value={educationList[index].specialization}
                            onChange={handleAdd(index, "specialization")}

                          >

                          </TextField>
                        </div>
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="degree"
                            label="Degree/Diploma/Certificate"
                            type="text"
                            value={educationList[index].courseName}
                            onChange={handleAdd(index, "courseName")}
                          >
                            
                          </TextField>
                        </div>

                      </div>
                      <div className="row w-100">
                        <div className="col">
                          <TextField
                            className="w-100"
                            select
                            margin="dense"
                            id="grading-system"
                            label="Grade Scheme (GPA/Percentage)"
                            type="text"
                            value={educationList[index].gradingSystem}
                            onChange={
                              handleAdd(index, "gradingSystem")
                            }
                            helperText={gradingSystemError ? gradingSystemError : ""}
                            error={gradingSystemError}
                          >
                            Percentage
                            <MenuItem value={"Percentage"} key={"Percentage"}>
                              Percentage
                            </MenuItem>
                            <MenuItem value={"CGPA"} key={"CGPA"}>
                              CGPA
                            </MenuItem>
                            <MenuItem value={"GPA"} key={"GPA"}>
                              GPA
                            </MenuItem>
                            <MenuItem value={"Other"} key={"Other"}>
                              Other
                            </MenuItem>
                          </TextField>
                        </div>
                        <div className="col">


                          <TextField
                            className="w-100"
                            margin="dense"
                            id="marks-value"
                            label="Grade Average/ Marks Obtained"
                            type="text"
                            value={educationList[index].markValue}
                            onChange={handleAdd(index, "markValue")}
                            helperText={markValueError ? markValueError : ""}
                            error={markValueError}
                          />
                          {' '}
                        </div>

                      </div>
                      <div className="row w-100">

                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="university-name"
                            label="Name of Institution"
                            type="text"
                            value={educationList[index].universityOrInstitute}
                            onChange={handleAdd(index, "universityOrInstitute")}
                            helperText={universityOrInstituteError ? universityOrInstituteError : ""}
                            error={universityOrInstituteError}
                          />
                        </div>
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="university-name"
                            label="Affiliated University/Board of Education"
                            type="text"
                            value={educationList[index].universityName}
                            onChange={handleAdd(index, "universityName")}
                            helperText={universityOrInstituteError ? universityOrInstituteError : ""}
                            error={universityOrInstituteError}
                          />
                        </div>
                      </div>
                      <div className="row w-100">

                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="location-name"
                            label="City"
                            type="text"
                            value={educationList[index].universityCity}
                            onChange={handleAdd(index, "universityCity")}
                            helperText={locationNameError ? locationNameError : ""}
                            error={locationNameError}
                          />

                          {' '}
                        </div>
                        <div className="col">
                          <TextField
                            className="w-100"
                            margin="dense"
                            id="state"
                            label="State"
                            type="text"
                            value={educationList[index].universityState}
                            onChange={handleAdd(index, "universityState")}
                            helperText={locationNameError ? locationNameError : ""}
                            error={locationNameError}
                          />

                          {' '}
                        </div>
                        <div className="col">

                          <Autocomplete
                            className="w-100"
                            {...countryProps}
                            id="debug"
                            debug
                            onChange={handleAdd(index, "universityCountry")}
                            defaultValue={{ countryName: countryList[index].countryName }}
                            renderInput={params => (
                              <TextField
                                className="w-100"
                                {...params}
                                label="Country"
                                margin="normal"

                              />
                            )}
                          />
                          {/* <TextField
                            className="w-100"
                            margin="dense"
                            id="country"
                            label="Country"
                            type="text"
                            value={educationList[index].universityCountry}
                            onChange={handleAdd(index, "universityCountry")}
                            helperText={locationNameError ? locationNameError : ""}
                            error={locationNameError}
                          /> */}

                          {' '}
                        </div>
                      </div>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    margin="dense"
                                    variant="inline"
                                    openTo="year"
                                    views={["year"]}
                                    id="passing-year"
                                    label="Passing Out Year"
                                    value={educationList[index].passingOutYear}
                                    onChange={handleAdd(index,"passingOutYear")}
                                    minDate={new Date("1950-01-01")}
                                    maxDate = {new Date()}
                                    helperText={educationListError[index].passingOutYearError ? educationListError[index].passingOutYearError : ""}
                                    error={educationListError[index].passingOutYearError}                
                                /> */}
                      {/* </MuiPickersUtilsProvider> */}

                    </div>


                  </>
                )) : null}
            </>
            : null}
          {props.userValue.data.user.educationBackground.length > 0 && <div className="my-3 d-flex justify-content-between pb-2 pl-1 pr-1">
          <div className="cta-primary">
            <Button type="primary" onClick={handleSubmitButton} loading={isLoading} size={'large'} style={{ backgroundColor: '#3377B6' }} icon={<CheckOutlined />}>
              Save
            </Button>
          </div>
            {/* <Button className="save-btn ms-0" onClick={handleSubmitButton} color="primary">
              Save
            </Button> */}
            <Button className="border-0 text-capitalize" onClick={props.showBtn} color="primary">
              + Add new
            </Button>
          </div>}

        </>
      }
    </div>
  )
}