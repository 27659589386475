import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useMutation, useQuery } from "@apollo/client"
import popup_logo from "../assets/images/popup_logo.png"
import info_logo from "../assets/images/info_icon.png"
import error_logo from "../assets/images/error_icon.png"
import gimg from "../assets/images/google.png"
import fb from "../assets/images/fb.png"
import linked from "../assets/images/linkedin.png"
import { Link } from "gatsby"
import { validateEmailID, validatePassword } from "../../utils"
import { navigate } from "gatsby"
import close_image from "../assets/images/icon-close.png"
import { DropdownButton, Dropdown } from "react-bootstrap"
// import { Field, reduxForm } from "redux-form";
import { Scrollbars } from "react-custom-scrollbars"
import BounceIndicator from "react-activity/lib/Bounce"
import { DELETE_USER_MUTATION } from "../components/graphql"
import cookies from "js-cookies";

export default function FormDialog({ deleteOpen, setDeleteOpen, userValue }) {
  const [deleteUser] = useMutation(DELETE_USER_MUTATION)
  const [deleteLoad, setDeleteLoad] = useState(false)
  const userId = userValue.data.user.id
  console.log("deleteopen", deleteOpen)
  const handleClose = () => {
    setDeleteOpen(false)
  }

  const deleteAction = async () => {
    if (deleteLoad === false) {
      setDeleteLoad(true)
      try {
        await deleteUser({
          variables: {
            id: userId,
          },
          context: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
            },
          },
        }).then(response => {
          console.log("reponse", response.data)
          logOutAction()
        })
      } catch (deleteError) {
        setDeleteLoad(false)
        console.log("delete user error", deleteError)
      }
    }
  }

  const logOutAction = () => {
    setDeleteLoad(false)
    
    localStorage.removeItem("userName")
    localStorage.removeItem("token")
    localStorage.removeItem("userId")
    cookies.removeItem('username');
    cookies.removeItem('autoLogin');
    cookies.removeItem('email');
    cookies.removeItem('loggedin');
    cookies.removeItem('n_username');
    cookies.removeItem('n_email');
    cookies.removeItem('n_uid');
    cookies.removeItem('express.sid');

    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.href = "/"
    }
  }

  React.useEffect(() => {
    console.log("useEffect called")
  }, [])

  return (
    <div>
      <Dialog
        className="login-signup-popup"
        open={deleteOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="sign-popup">
          <div className="box">
            <div className="signup-popup">
              <div className="site-logo">
                <Link to="/">
                  <img
                    src={popup_logo}
                    alt="Sticky Logo"
                    className="popuplogo"
                  ></img>
                </Link>
                <DialogContentText>Delete Account</DialogContentText>
              </div>
              <div className="login-fields">
                <p> Are you sure you want delete your account? </p>
              </div>
              <div className="submit">
                <button
                  onClick={() => {
                    deleteAction()
                  }}
                >
                  {deleteLoad === false ? (
                    "Delete"
                  ) : (
                    <div className={"centered"} style={{ height: "14px" }}>
                      <BounceIndicator size={14} color={"#0055a4"} />
                    </div>
                  )}
                </button>
              </div>

              {/*<TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={e => { setEmail(e.target.value)}}
              />

              <TextField
                autoFocus={false}
                margin="dense"
                id="password"
                label="password"
                type="email"
                fullWidth
                value={pass}
                onChange={e => {
                  setPass(e.target.value)
                }}
              />

              <TextField
                autoFocus={false}
                margin="dense"
                id="password"
                label="Confirm Password"
                type="password"
                fullWidth
                value={pass}
                onChange={e => {
                  setPass(e.target.value)
                }}
              /> */}
            </div>
          </div>
        </DialogContent>
        {/*<DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions> */}
        <img
          className="closeicon"
          onClick={() => {
            setDeleteOpen(false)
          }}
          src={close_image}
          alt=""
        />
      </Dialog>
    </div>
  )
}
