
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { Upload, message, Switch, Button, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { DeleteFilled } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import apiUrl from '../../api/apiUrl';
import axios from 'axios';


import 'antd/lib/upload/style/index.css'
import 'antd/lib/message/style/index.css'
import 'antd/lib/switch/style/index.css'
import 'antd/lib/spin/style/index.css'




const { Dragger } = Upload;

function EducationUpload({ id,eduDoc }) {


    const [showSecondary, setshowSecondary] = useState(true)
    const [showUg, setshowUg] = useState(false)
    const [showPg, setshowPg] = useState(false)

    const [sslcIsloading, setsslcIsloading] = useState(false)
    const [plustwoIsloading, setplustwoIsloading] = useState(false)

    const [ugcertificatIsloading, setugcertificatIsloading] = useState(false)
    const [ugmarklistIsloading, setugmarklistIsloading] = useState(false)
    const [ugconsolidate, setugconsolidate] = useState(false)


    const [pgcertificatIsloading, setpgcertificatIsloading] = useState(false)
    const [pgmarklistIsloading, setpgmarklistIsloading] = useState(false)
    const [pgconsolidate, setpgconsolidate] = useState(false)


    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const [documents, setdocuments] = useState(eduDoc ?? {})
    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"

    const uploadFile = async (e, feild) => {

        var EDUDocuments = {
            sslc: documents?.sslc,
            plustwo: documents?.plustwo,
            UGCertificate: documents?.UGCertificate,
            UGMarksheet: documents?.UGMarksheet,
            UGConsolidateMarksheet: documents?.UGConsolidateMarksheet,
            PGMarksheet: documents?.PGMarksheet,
            PGCertificate: documents?.PGCertificate,
            PGConsolidateMarksheet: documents?.PGConsolidateMarksheet
        }

        try {
            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            EDUDocuments[feild] = uploadResp.data[0].id

            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { EDUDocuments: EDUDocuments })
            updateUser?.data?.EDUDocuments && setdocuments(updateUser?.data?.EDUDocuments)
            setsslcIsloading(false)
            setplustwoIsloading(false)
            setugcertificatIsloading(false)
            setugconsolidate(false)
            setugmarklistIsloading(false)
            setpgcertificatIsloading(false)
            setpgconsolidate(false)
            setpgmarklistIsloading(false)

        } catch (error) {
            console.log(error)
            setplustwoIsloading(false)
            setugcertificatIsloading(false)
            setugconsolidate(false)
            setugmarklistIsloading(false)
            setpgcertificatIsloading(false)
            setpgconsolidate(false)
            setpgmarklistIsloading(false)

        }
    }


    const deleteFile = async (feild) => {
        try {
            var EDUDocuments = {
                sslc: documents?.sslc,
                plustwo: documents?.plustwo,
                UGCertificate: documents?.UGCertificate,
                UGMarksheet: documents?.UGMarksheet,
                UGConsolidateMarksheet: documents?.UGConsolidateMarksheet,
                PGMarksheet: documents?.PGMarksheet,
                PGCertificate: documents?.PGCertificate,
                PGConsolidateMarksheet: documents?.PGConsolidateMarksheet
            }

            EDUDocuments[feild] = null
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { EDUDocuments: EDUDocuments })
            updateUser?.data?.EDUDocuments && setdocuments(updateUser?.data?.EDUDocuments)
            setsslcIsloading(false)
            setplustwoIsloading(false)
            setugcertificatIsloading(false)
            setugconsolidate(false)
            setugmarklistIsloading(false)
            setpgcertificatIsloading(false)
            setpgconsolidate(false)
            setpgmarklistIsloading(false)
        } catch (error) {
            setsslcIsloading(false)
            setplustwoIsloading(false)
            setugcertificatIsloading(false)
            setugconsolidate(false)
            setugmarklistIsloading(false)
            setpgcertificatIsloading(false)
            setpgconsolidate(false)
            setpgmarklistIsloading(false)
        }
    }




    return (
        <>

            <div className="row col-12 my-3">
                <div className="col-8">
                    I have completed or pursuing Secondary Education
                </div>
                <div className="col-4">
                    <div className="text-end mt-2">
                        <Switch checked={showSecondary} onChange={() => setshowSecondary(!showSecondary)} />
                    </div>
                </div>
            </div>


            {showSecondary && <>
                <div className="row d-flex justify-content-between mt-3 ps-2">
                    <div className="col text-start">
                        <label>10th Marksheet <span className="text-danger">*</span> </label>
                    </div>
                    {documents?.sslc ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.sslc?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('sslc'); setsslcIsloading(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!sslcIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'sslc'); setsslcIsloading(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>


                <div className="row mt-2 ps-2">
                    <div className="col d-flex">
                        <label>12th Marksheetk <span className="text-danger">*</span></label>
                    </div>
                    {documents?.plustwo ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.plustwo?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('plustwo'); setplustwoIsloading(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!plustwoIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'plustwo'); setplustwoIsloading(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>

            </>}

            <hr />


            <div className="row col-12 my-3">
                <div className="col-8">
                    I have  completed or pursuing any UG course
                </div>
                <div className="col-4">
                    <div className="text-end mt-2">
                        <Switch checked={showUg} onChange={() => setshowUg(!showUg)} />
                    </div>
                </div>
            </div>


            {showUg && <>

                <div className="row mt-2 ps-2">
                    <div className="col d-flex">
                        <label>UG Degree Certificate <span className="text-danger">*</span></label>
                    </div>
                    {documents?.UGCertificate ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.UGCertificate?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('UGCertificate'); setugcertificatIsloading(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!ugcertificatIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'UGCertificate'); setugcertificatIsloading(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>

                <div className="row mt-2 ps-2">
                    <div className="col d-flex">
                        <label>UG Marksheet <span className="text-danger">*</span></label>
                    </div>
                    {documents?.UGMarksheet ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.UGMarksheet?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('UGMarksheet'); setugmarklistIsloading(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!ugmarklistIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'UGMarksheet'); setugmarklistIsloading(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>

                <div className="row mt-2 ps-2">
                    <div className="col d-flex">
                        <label>UG Consolidated Marksheet <span className="text-danger">*</span></label>
                    </div>
                    {documents?.UGConsolidateMarksheet ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.UGConsolidateMarksheet?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('UGConsolidateMarksheet'); setugconsolidate(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!ugconsolidate ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'UGConsolidateMarksheet'); setugconsolidate(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>
            </>}

            <hr />

            <div className="row col-12 my-3">
                <div className="col-8">
                    I have completed or pursuing any PG course
                </div>
                <div className="col-4">
                    <div className="text-end mt-2">
                        <Switch checked={showPg} onChange={() => setshowPg(!showPg)} />
                    </div>
                </div>
            </div>


            {showPg && <>
                <div className="row d-flex justify-content-between mt-3 ps-2">
                    <div className="col text-start">
                        <label>PG Degree Certificate <span className="text-danger">*</span> </label>
                    </div>
                    {documents?.PGCertificate ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.PGCertificate?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('PGCertificate'); setpgcertificatIsloading(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!pgcertificatIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'PGCertificate'); setpgcertificatIsloading(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>


                <div className="row mt-2 ps-2">
                    <div className="col d-flex">
                        <label> PG Consolidated Marksheet <span className="text-danger">*</span></label>
                    </div>
                    {documents?.PGConsolidateMarksheet ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.PGConsolidateMarksheet?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('PGConsolidateMarksheet'); setpgconsolidate(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!pgconsolidate ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'PGConsolidateMarksheet'); setpgconsolidate(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>

                <div className="row mt-2 ps-2">
                    <div className="col d-flex">
                        <label> PG Marksheet <span className="text-danger">*</span></label>
                    </div>
                    {documents?.PGMarksheet ?
                        <>
                            <div className="col text-center">
                                <Button href={`${apiUrl.host}${documents?.PGMarksheet?.url}`} target="_blank">View Document</Button>
                                <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={() => { deleteFile('PGMarksheet'); setpgmarklistIsloading(true) }} />
                            </div>
                        </> :

                        <div className="col">
                            {!pgmarklistIsloading ? <Dragger accept={accpted} multiple={false} showUploadList={false} onChange={(e) => { uploadFile(e, 'PGMarksheet'); setpgmarklistIsloading(true) }} >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger> : <Spin indicator={antIcon} />}
                        </div>
                    }
                </div>

            </>}

        </>
    )
}

export default React.memo(EducationUpload);
