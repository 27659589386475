import React, { useState, useEffect } from "react"
import { useQuery, useMutation, gql } from "@apollo/client"
import { navigate } from "gatsby";
import LoadingButton from '@mui/lab/LoadingButton';
import { STUDY_ABROAD_EXAM_QUERY, JOURNEY_STEP_QUERY } from '../../components/graphql'




import {
  Button, TextField,
  Dialog, DialogActions,
  DialogContent, DialogTitle
} from "@material-ui/core"
import axios from "axios";
import apiUrl from "../../api/apiUrl";



export default function ProfileComplete(props) {

  const [email, setEmail] = useState();
  const [userName, setUserName] = useState();
  const [phone, setphone] = useState();
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();

  //error

  const [emailError, setemailError] = useState("");
  const [userNameError, setuserNameError] = useState("");
  const [firstNameError, setfirstNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [phoneError, setphoneError] = useState("");

  const allStudyAbroadExam = useQuery(STUDY_ABROAD_EXAM_QUERY);
  const allJourney = useQuery(JOURNEY_STEP_QUERY);

  const [serverError, setserverError] = useState('')



  const [isloading, setisloading] = useState(false);

  const UPDATE_USER_INFO = gql`
  mutation updateUserInfo(
    $userId: ID! 
    $firstname: String
    $lastname: String
    $username: String
    $email: String
    $phone: String
    $journeyContent: [editComponentPluginJourneySubContentInput]
    $studyExamContent: [editComponentPluginStudyAbroadExamInput]
    ) {
    updateUser(input: { where: { id: $userId }, 
      data: { 
        firstName: $firstname
        lastName:$lastname
        username:$username
        email:$email
        mobileNumber:$phone
        journeyStepContent: $journeyContent
        studyAbroadExamContent: $studyExamContent
      } }) {
      user {
        firstName
        lastName
        username
        email
        mobileNumber
      }
    }
  }
`

  const testf = (d) => {
    console.log('testf', d)
  }


  console.log('aaaaaaaaaaaaal', allStudyAbroadExam)

  //update
  const [udateUserInfo] = useMutation(UPDATE_USER_INFO)



  const updateUserInfos = async () => {
    setisloading(true)

    const journeyList = [];
    const studyAbroadExamList = [];
    allJourney.data.journeySteps.forEach((record) => {
      let dict = {};
      dict.journeystep = record.id;
      journeyList.push(dict);
    });
    allStudyAbroadExam.data.studyAbroadExams.forEach((record) => {
      let dict = {};
      dict.studyAbroadExam = record.id;
      studyAbroadExamList.push(dict);
    });


    console.log('check journey', props?.user?.data?.user?.userRole?.name === 'Student' ? journeyList : '')
    console.log('check study', studyAbroadExamList)



    try {
      // await udateUserInfo({
      //   variables: {
      //     userId: props?.user?.data?.user?.id,
      //     firstname: firstName,
      //     lastname: lastName,
      //     username: userName,
      //     email: email,
      //     phone: phone,
      //     journeyContent: journeyList,
      //     studyExamContent: props?.user?.data?.user?.userRole?.name === 'Student' ? studyAbroadExamList : '',
      //   }
      // })

      const hader = {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
        }
      }

      const data = {
        firstName: firstName,
        lastName: lastName,
        username: userName,
        email: email,
        mobileNumber: phone,
        journeyStepContent: journeyList,
        studyAbroadExamContent: props?.user?.data?.user?.userRole?.name === 'Student' ? studyAbroadExamList : ''
      }

      var response = await axios.post(apiUrl.updateUser, data, hader)
      console.log("################",response?.data?.errmsg,"-----",response)
      setserverError(response?.data?.errmsg || '')
      setisloading(response?.data?.status ? true : false)
      localStorage.setItem("userName", userName);
      console.log("---------------called from complete profile success------------------")
      if(localStorage.getItem('lastRevistedPage')){
        if(localStorage.getItem('lastRevistedPage')==='/house/'){
          if(localStorage.getItem('lastRevistedPageID')){
            navigate(localStorage.getItem('lastRevistedPage')+localStorage.getItem('lastRevistedPageID'))
            localStorage.removeItem('lastRevistedPage')
            localStorage.removeItem('lastRevistedPageID')
          }
          else{
            navigate(localStorage.getItem('lastRevistedPage'))
            localStorage.removeItem('lastRevistedPage') 
          }
        }
        else{
          navigate(localStorage.getItem('lastRevistedPage'))
          localStorage.removeItem('lastRevistedPage')
        }
      }
    } catch (error) {
      console.log(error)
      setisloading(false)
      localStorage.setItem("userName", userName);
      console.log("---------------called from complete profile catch------------------")
      navigate("/user-profile/")
    }
  }

  const formValidate = () => {
    setfirstNameError('')
    setlastNameError('')
    setuserNameError('')
    setemailError('')
    setphoneError('')

    if (!props?.user?.data?.user?.firstName && !firstName) {
      setfirstNameError('First name is Required')
      console.log(firstName)
      console.log('ferr')
      return null
    }
    if (!props?.user?.data?.user?.lastName && !lastName) {
      setlastNameError('Last name is Required')
      console.log('lerr')

      return null
    }
    if (!props?.user?.data?.user?.username && !userName) {
      setuserNameError('Username is Required')
      console.log('uerr')

      return null
    }

    if (!props?.user?.data?.user?.email && !email) {
      setemailError('Email Required')
      console.log('eerr')

      return null
    }

    if (!props?.user?.data?.user?.mobileNumber && !phone) {
      setphoneError('Phone is Required')
      console.log('perr')

      return null
    }
    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    var phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

    if (email && !email.match(emailRegex)) {
      setemailError('Invalid email')
      console.log('emerr')

      return null
    }
    if (phone && !phone.match(phoneRegex)) {
      setphoneError('Invalid phone')
      console.log('pmerr')

      return null
    }

    updateUserInfos()

  }


  return (

    <Dialog open={true}
      aria-labelledby="form-dialog-companyName">
      <DialogTitle id="form-dialog-companyName">
        Complete Profile
      </DialogTitle>
      <DialogContent>
        <span className="text-danger">{ serverError }</span>
        <div style={{ marginTop: "20px" }}>
          {!props?.user?.data?.user?.firstName &&
            <TextField
              margin="dense"
              id="firstname"
              label="First Name"
              type="text"
              fullWidth
              value={firstName}
              required
              onChange={e => setfirstName(e.target.value)}
              helperText={firstNameError ? firstNameError : ""}
              error={firstNameError.length > 0}
            />}

          {!props?.user?.data?.user?.lastName &&
            <TextField
              margin="dense"
              id="LastName"
              label="Last Name"
              type="text"
              required
              fullWidth
              value={lastName}
              onChange={e => setlastName(e.target.value)}
              helperText={lastNameError ? lastNameError : ""}
              error={lastNameError.length > 0}
            />}

          {!props?.user?.data?.user?.username && <TextField
            // autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            value={userName}
            required
            fullWidth
            onChange={e => setUserName(e.target.value)}
            helperText={userNameError ? userNameError : ""}
            error={userNameError.length > 0}
          />}

          {!props?.user?.data?.user?.email &&
            <TextField
              // autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="text"
              value={email}
              fullWidth
              required
              onChange={e => {
                setEmail(e.target.value)
              }}
              helperText={emailError ? emailError : ""}
              error={emailError.length > 0}
            />}

          {!props?.user?.data?.user?.mobileNumber && <TextField
            margin="dense"
            id="phone"
            label="Enter phone number"
            type="number"
            fullWidth
            required
            value={phone}
            onChange={e => setphone(e.target.value)}
            helperText={phoneError ? phoneError : ""}
            error={phoneError.length > 0}
          />}

        </div>
      </DialogContent>
      <DialogActions>

        {/* <Button className="save-btn" onClick={formValidate} color="primary">
          Save
        </Button> */}


        <LoadingButton variant="contained" loading={isloading} style={{
          border: "none",
          outline: "none"
        }} onClick={formValidate} >
          Save
        </LoadingButton>



      </DialogActions>
    </Dialog>

  )
}