import React, { useState } from "react"
import {
  Button, TextField,
  Dialog, DialogActions,
  DialogContent, DialogTitle
} from "@material-ui/core"

import { useMutation } from "@apollo/client"
import { UPDATE_USER_COMPANY_DETAILS } from "../graphql"

export default function UserUpdateCompanyDetail(props) {
  // console.log("=========111111111111111111==========",props)
  const [open, setOpen] = useState(true)
  const userID = props.userValue.data.user.id
  const [companyName, setCompanyName] = useState("")
  const [companyURL, setCompanyURL] = useState("")
  const [companyAddress, setCompanyAddress] = useState("")
  const [companyNameError, setCompanyNameError] = useState("")
  const [companyURLError, setCompanyURLError] = useState("")
  const [companyAddressError, setCompanyAddressError] = useState("")

  const [updateUser] = useMutation(UPDATE_USER_COMPANY_DETAILS)
  const list = []
  const list1 = []
  props.userValue.data.user.companyDetail.forEach((record) => {
    let dict = {}
    dict.id = record.id
    dict.companyName = record.companyName
    dict.companyURL = record.companyURL
    dict.companyAddress = record.companyAddress
    list.push(dict)
  })
  // console.log("listssssssssssssssssssssssssss",list)
  const [educationList, setEducationList] = useState(list)
  educationList.forEach((record) => {
    let dict = {}
    dict.companyNameError = ""
    dict.companyURLError = ""
    dict.companyAddressError = ""
    list1.push(dict)
  })
  const [educationListError, setEducationListError] = useState(list1)

  const handleClose = () => {
    setOpen(false)
  }

  const handleAdd = (index, element) => e => {
    let newArr = [...educationList];
    newArr[index][element] = e.target.value;
    setEducationList(newArr);
  }

  const handleSubmitButton = () => {
    if (props.add) {
      if (!companyName) {
        setCompanyNameError("Enter a valid company name")
      }
      if (!companyURL) {
        setCompanyURLError("Enter a valid compamy URL")
      }
      if (!companyAddress) {
        setCompanyAddressError("Enter a valid company address")
      }
      if (companyName && companyURL && companyAddress) {
        setCompanyNameError("")
        setCompanyURLError("")
        setCompanyAddressError("")
        handleSubmit()
      }
    }
    else {
      let listArr = []
      let flag = 0
      educationList.forEach((record) => {
        let dict = {}
        if (!record.companyName) {
          dict.companyNameError = "Enter a valid company name"
          flag = 1
        }
        else if (!record.companyURL) {
          dict.companyURLError = "Enter a valid company URL"
          flag = 1
        }
        else if (!record.companyAddress) {
          dict.companyAddressError = "Enter a valid company address"
          flag = 1
        }
        else {
          dict.companyNameError = ""
          dict.companyURLError = ""
          dict.companyAddressError = ""
        }
        listArr.push(dict)
      })
      setEducationListError(listArr)
      if (flag === 0) {
        handleSubmit()
      }
    }
  }

  const handleSubmit = async () => {
    // console.log("#####################");
    if (props.add) {
      if (companyName || companyAddress) {
        let dict1 = {}
        dict1.companyName = companyName
        dict1.companyURL = companyURL
        dict1.companyAddress = companyAddress
        try {
          await updateUser({
            variables: {
              id: userID,
              eContent: educationList.concat([dict1])
            },
          })
            .then(response => {
              console.log("reponse", response.data)
            })
            .finally(() => {
              setOpen(false)
            })
        } catch (e) {
          console.log("Adding user company details error", e.message)
        }
      }
      else {
        setOpen(false)
      }
    }
    else {
      // console.log("11111111111111111",educationList)
      try {
        await updateUser({
          variables: {
            id: userID,
            eContent: educationList
          },
        })
          .then(response => {
            console.log("reponse", response.data)
          })
          .finally(() => {
            setOpen(false)
            setCompanyName("")
            setCompanyAddress("")
          })
      } catch (e) {
        console.log("update user company details error", e.message)
      }

    }
  }
  // console.log("updateedddddddddddddddddddddddddddddd",educationList)
  return (
    <div>
      {props.add ?

        <>
          <div className="fw-bold">Edit Company Details</div>
          <div className="row w-100">
            <div className="col">
              <TextField
                // autoFocus
                margin="dense"
                id="companyName"
                label="Company Name"
                type="text"
                value={companyName}
                fullWidth
                onChange={e => {
                  setCompanyName(e.target.value)
                }}
                helperText={companyNameError ? companyNameError : ""}
                error={companyNameError}
              />
            </div>
          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                // autoFocus
                margin="dense"
                id="companyURL"
                label="Company URL"
                type="text"
                value={companyURL}
                fullWidth
                onChange={e => {
                  setCompanyURL(e.target.value)
                }}
                helperText={companyURLError ? companyURLError : ""}
                error={companyURLError}
              />
            </div>
          </div>
          <div className="row w-100">
            <div className="col">
              <TextField
                margin="dense"
                id="companyAddress"
                label="Company Address"
                type="text"
                fullWidth
                multiline={true}
                value={companyAddress}
                onChange={e => {
                  setCompanyAddress(e.target.value)
                }}
                helperText={companyAddressError ? companyAddressError : ""}
                error={companyAddressError}
              />
            </div>
          </div>


          <Button className="cancel-btn" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button className="save-btn" onClick={handleSubmitButton} color="primary">
            Save
          </Button>
        </>

        :
        <>

          {props.userValue.data.user.companyDetail.length > 0 ?
            <>
              {props.userValue.data ? props.userValue.data.user.companyDetail.map(
                (content, index) => (
                  <>
                    {props.recordID === educationList[index].id ?
                      <div>
                        <div className="fw-bold">Edit Company Details</div>
                        <div className="row w-100">
                          <div className="col">
                            <TextField
                            className="w-100"
                              margin="dense"
                              id="companyName"
                              label="Company Name"
                              type="text"
                              fullWidth
                              value={educationList[index].companyName}
                              onChange={handleAdd(index, "companyName")}
                              helperText={educationListError[index].companyNameError ? educationListError[index].companyNameError : ""}
                              error={educationListError[index].companyNameError}
                            />
                          </div>
                        </div>
                        <div className="row w-100">
                          <div className="col">
                            <TextField
                            className="w-100"
                              margin="dense"
                              id="companyURL"
                              label="Company URL"
                              type="text"
                              fullWidth
                              value={educationList[index].companyURL}
                              onChange={handleAdd(index, "companyURL")}
                              helperText={educationListError[index].companyURLError ? educationListError[index].companyURLError : ""}
                              error={educationListError[index].companyURLError}
                            />
                          </div>
                        </div>
                        <div className="row w-100">
                          <div className="col">
                            <TextField
                            className="w-100"
                              margin="dense"
                              id="companyAddress"
                              label="Company Address"
                              type="text"
                              multiline={true}
                              value={educationList[index].companyAddress}
                              fullWidth
                              onChange={handleAdd(index, "companyAddress")}
                              helperText={educationListError[index].companyAddressError ? educationListError[index].companyAddressError : ""}
                              error={educationListError[index].companyAddressError}
                            />
                          </div>
                        </div>
                      </div>
                      : ""}
                  </>
                )) : null}
            </>
            : null}


          <div className="mt-3">
          <Button className="cancel-btn" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button className="save-btn" onClick={handleSubmitButton} color="primary">
            Save
          </Button>
          </div>

        </>
      }
    </div>
  )
}