
import React, { useState } from 'react';

import { Upload, message, Button, Spin, Input } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import 'antd/lib/upload/style/index.css'
import 'antd/lib/spin/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/input/style/index.css'

import { LoadingOutlined } from '@ant-design/icons';

import apiUrl from '../../api/apiUrl';
import axios from 'axios';




const { Dragger } = Upload;

function OtherDocument({ otherDoc, id }) {

    const [otherDocs, setotherDocs] = React.useState(otherDoc)
    const [name, setname] = React.useState('')
    const [isLoading, setisLoading] = useState(false)
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"

    const uplodFile = async (e) => {

        // otherDocs: {name: "est", Document: "620a3af1a404d8723e4513a4"}

        var otherDoc = {}

        try {
            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            otherDoc.Document = uploadResp.data[0].id
            otherDoc.name = name

            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { otherDocs: otherDoc })
            updateUser?.data?.otherDocs && setotherDocs(updateUser?.data?.otherDocs)
            setisLoading(false)

        } catch (error) {
            console.log(error)
            setisLoading(false)


        }
    }

    const deleteFile = async () => {
        setisLoading(true)
        try {
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { otherDocs: null })
            setotherDocs(updateUser?.data?.otherDocs || '')
            setisLoading(false)
        } catch (error) {
            console.log(error)
            setisLoading(false)

        }
    }



    return (
        <>

            {otherDocs ? <div className="row">
                <div className="col">
                    <Input placeholder="Enter Document Name to Upload" value={otherDocs?.name} />
                </div>

                <div className="col text-center">
                    <Button href={`${apiUrl.host}${otherDocs?.Document?.url}`} target="_blank">View Document</Button>
                    <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={()=>deleteFile()} />
                </div>


            </div> :
                <div className="row">
                    <div className="col">
                        <Input placeholder="Enter Document Name to Upload" value={name} onChange={(e) => setname(e.target.value)} />
                    </div>

                    <div className="col">
                        {!isLoading ? <Dragger accept={accpted} multiple={false} showUploadList={false} disabled={!name.length} onChange={(e) => { uplodFile(e); setisLoading(true) }} >
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger> : <Spin indicator={antIcon} />}
                    </div>

                </div>
            }


            {/* <div className="text-end mt-2">
                <Button type="primary" size={"large"}>
                    Add More
                </Button>
            </div> */}



        </>
    )
}

export default OtherDocument;
