
import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { Upload, Spin, Button, Input } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import 'antd/lib/upload/style/index.css'
import 'antd/lib/spin/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/input/style/index.css'


import axios from 'axios';
import apiUrl from '../../api/apiUrl';
import { LoadingOutlined } from '@ant-design/icons';


const WORK_EXP_QUERY = gql`
    query userData($id: ID!) {
      user(id: $id) {
        WorkExperianceDocuments{
            company
            id
            WorkExperianceCerificate{
              name
              url
            }
        }
      }
    }
  `

const { Dragger } = Upload;

function WorkExperiance({ id, expDoc }) {

    const [documents, setdocuments] = useState(expDoc ?? [])
    const [newDocuments, setnewDocuments] = useState([''])
    const [singleDoc, setsingleDoc] = useState('')
    const [singleDocIsLoading, setsingleDocIsLoading] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    var accpted = ".pdf,doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg"

    const addNewDocs = () => {
        var tempDoc = [...newDocuments]
        tempDoc.push('')
        setnewDocuments(tempDoc)
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


    console.log('ucalled', 'componentcalled')


    const uploadExpDocument = async (e, i) => {
        setisLoading(true)
        try {
            var tempexpDocument = [...documents]
            var expDocument = tempexpDocument.map(item => { return { company: item.company, WorkExperianceCerificate: item.WorkExperianceCerificate.id } })
            console.log('exp', expDocument)

            // var tempDoc = {}
            // tempDoc.company = newDocuments[index]
            var fdata = new FormData()
            fdata.append('files', e.file.originFileObj)
            var uploadResp = await axios.post(apiUrl.upload, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            //EDUDocuments[feild] = uploadResp.data[0].id
            var tempDoc = {}
            tempDoc.company = newDocuments[i]
            tempDoc.WorkExperianceCerificate = uploadResp.data[0].id
            expDocument.push(tempDoc)
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { WorkExperianceDocuments: expDocument })
            updateUser?.data?.WorkExperianceDocuments && setdocuments(updateUser?.data?.WorkExperianceDocuments)
            setnewDocuments([])
            setisLoading(false)
        } catch (error) {
            console.log(error)
            setnewDocuments([])
            setisLoading(false)
        }
    }


    const deleteFile = async (itemid) => {
        setisLoading(true)
        try {
            var tempexpDocument = [...documents]
            var newexpDocument = tempexpDocument.filter(item => item?.id !== itemid ).map(item => { return { company: item.company, WorkExperianceCerificate: item.WorkExperianceCerificate.id } })
            console.log(newexpDocument)
            var updateUser = await axios.put(`${apiUrl.updateUserProfile}${id}`, { WorkExperianceDocuments: newexpDocument })
            updateUser?.data?.WorkExperianceDocuments && setdocuments(updateUser?.data?.WorkExperianceDocuments)
            setisLoading(false)

        } catch (error) {
            console.log(error)
            setisLoading(false)

        }
    }


    const changeText = (value, index) => {
        var newDoc = [...newDocuments]
        newDoc[index] = value
        // console.log(value,index)
        setnewDocuments(newDoc)
    }


    if (isLoading) {
        return (
            <div className="spin">
                <Spin indicator={antIcon} />
            </div>
        )


    }


    return (
        <>
           

            {documents.length >= 0 && <>
                {documents.map((item, index) =>
                    <div className="row mt-3" key={index}>
                        <div className="col">
                            <Input placeholder="Enter Company Name" value={item.company} />
                        </div>

                        <div className="col text-center d-flex justify-content-between">
                            {/* <Button >View Document</Button> */}

                            <Button href={`${apiUrl.host}${item?.WorkExperianceCerificate?.url}`} target="_blank">View Document</Button>
                            {/* <Button size={"middle"} onClick={()=>{saveInfo()}}>
                                Save
                            </Button> */}

                            <Button danger icon={<DeleteFilled />} style={{ border: 0, Color: 'red' }} onClick={()=>{deleteFile(item?.id)}} />
                        </div>

                    </div>)}



            </>}


            {newDocuments.map((item, index) =>
                <div className="row mt-3" key={index}>
                    <div className="col">
                        <Input placeholder="Enter Company Name" value={item} onChange={(e) => { changeText(e.target.value, index) }} />
                    </div>


                    <div className="col">
                        <Dragger accept={accpted} multiple={false} showUploadList={false} className="p-0"
                            onChange={(e) => uploadExpDocument(e, index)}
                        >
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </div>

                </div>)}


            {/* 
            {documents.length <= 0 &&
                <>
                    {newDocuments.map((item, index) =>
                        <div className="row mt-3">
                            <div className="col">
                                <Input placeholder="Enter Company Name" value={item} onChange={(e) => { changeText(e.target.value, index) }} />
                            </div>


                            <div className="col">
                                <Dragger multiple={false} showUploadList={false} className="p-0"
                                    onChange={(e) => uploadExpDocument(e, index)}
                                >
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </div>

                        </div>)}
                </>} */}



            <div className="text-end mt-3 mb-3 p-1">
                <div className="cta-primary">
                    <Button type="primary" size={"medium"} onClick={() => { addNewDocs() }}>
                        Add More
                    </Button>
                </div>
            </div>



        </>
    )
}

export default React.memo(WorkExperiance);
